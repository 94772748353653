<app-summary-section
  [stepRoute]="route"
  heading="HEAT_PUMP_INFORMATION.HEADING"
>
  <p class="section-heading">
    {{ "HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.TITLE" | translate }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="heatPumpInformation.systemManufacturer"
      label="HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.MANUFACTURER"
    />

    <app-summary-section-field
      [value]="heatPumpInformation.systemModel"
      label="HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.TYPE"
    />

    <app-summary-section-field
      [value]="heatPumpInformation.systemCount"
      label="HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.NUMBER_OF_IDENTICAL_HEAT_PUMPS"
    />
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        'HEAT_PUMP_INFORMATION.TYPE_OF_HEAT_PUMP.' +
          heatPumpInformation.systemType | translate
      "
      label="HEAT_PUMP_INFORMATION.TYPE_OF_HEAT_PUMP.TITLE"
    />
  </div>

  <p class="section-heading">
    {{ "HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.TITLE" | translate }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="heatPumpInformation.power"
      label="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.POWER"
    />

    <app-summary-section-field
      [value]="heatPumpInformation.additionalHeatingPower"
      label="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.ADDITIONAL_HEATING_POWER"
    />

    <app-summary-section-field
      [value]="heatPumpInformation.maxStartingCurrent"
      label="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.MAX_STARTING_CURRENT"
    />

    <app-summary-section-field
      [value]="heatPumpInformation.maxGridPower"
      label="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.MAX_GRID_POWER"
    />
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        (heatPumpInformation.separateMeter ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      "
      label="HEAT_PUMP_INFORMATION.MEASUREMENT_VIA_SEPARATE_METER.TITLE"
    />

    <app-summary-section-field
      [value]="
        (heatPumpInformation.meterAvailable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      "
      label="HEAT_PUMP_INFORMATION.METER_ALREADY_EXISTS.TITLE"
    />

    @if (heatPumpInformation.meterAvailable) {
      <app-summary-section-field
        [value]="heatPumpInformation.meterNumber"
        label="HEAT_PUMP_INFORMATION.METER_ALREADY_EXISTS.METER_NUMBER"
      />
    }

    <app-summary-section-field
      [value]="
        (heatPumpInformation.operationalPurposeOrCriticalInfrastructure
          ? 'COMMON.YES'
          : 'COMMON.NO'
        ) | translate
      "
      label="HEAT_PUMP_INFORMATION.OPERATIONAL_PURPOSE_OR_CRITICAL_INFRASTRUCTURE.TITLE"
    />

    <app-summary-section-field
      [value]="
        'HEAT_PUMP_INFORMATION.LOAD_MANAGEMENT.' +
          heatPumpInformation.loadManagement | translate
      "
      label="HEAT_PUMP_INFORMATION.LOAD_MANAGEMENT.TITLE"
    />
  </div>
</app-summary-section>
