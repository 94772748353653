import { CONTROLLABLE_CONSUMPTION_SYSTEM } from "@app/models/registration-form";

export class ControllableConsumptionSystemsMapper {
  readonly #enumKeys = Object.keys(CONTROLLABLE_CONSUMPTION_SYSTEM);

  public static readonly INSTANCE = new ControllableConsumptionSystemsMapper();

  public mapToBooleanArray(
    controllableConsumptionSystems: CONTROLLABLE_CONSUMPTION_SYSTEM[],
  ): boolean[] {
    return this.#enumKeys.map((key) =>
      controllableConsumptionSystems.includes(
        key as CONTROLLABLE_CONSUMPTION_SYSTEM,
      ),
    );
  }

  public map(
    controllableConsumptionSystems: boolean[],
  ): CONTROLLABLE_CONSUMPTION_SYSTEM[] {
    const result = controllableConsumptionSystems
      .map((checked, index) =>
        checked
          ? (this.#enumKeys[index] as CONTROLLABLE_CONSUMPTION_SYSTEM)
          : null,
      )
      .filter((value) => value !== null);
    return result;
  }
}
