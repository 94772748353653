import { Component, inject, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { getLanguageLocaleCulture } from "@app/constants/language";
import { ConstructionElectricity } from "@app/models/registration-form";

@Component({
  selector: "app-construction-electricity-details-summary",
  templateUrl: "./construction-electricity-details-summary.component.html",
})
export class ConstructionElectricityDetailsSummaryComponent {
  @Input({ required: true })
  public constructionElectricityDetails!: ConstructionElectricity;
  readonly #translateService = inject(TranslateService);
  public readonly currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang,
  );
}
