import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import { MAIN_POWER_SUPPLY_TYPE } from "@app/models/commissioning-mapped-data.interface";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";

export interface TypeDetails {
  icon: string;
  type: MAIN_POWER_SUPPLY_TYPE;
}
@Component({
  selector: "app-main-power-supply-type",
  templateUrl: "./main-power-supply-type.component.html",
})
export class MainPowerSupplyTypeComponent implements OnInit, OnDestroy {
  details: TypeDetails[] = [
    {
      icon: "add_circle",
      type: MAIN_POWER_SUPPLY_TYPE.NEW_GRID_CONNECTION,
    },
    {
      icon: "keyboard_double_arrow_up",
      type: MAIN_POWER_SUPPLY_TYPE.POWER_INCREASE,
    },
    {
      icon: "settings",
      type: MAIN_POWER_SUPPLY_TYPE.DECOMMISSIONING,
    },
  ];

  public selectedType?: MAIN_POWER_SUPPLY_TYPE;
  public allTouched = false;
  public form!: FormGroup;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createPropertyTypeForm();
    this.updateForm();
  }

  private createPropertyTypeForm(): void {
    this.form = this.formBuilder.group({
      type: [null, Validators.required],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.mainPowerSupplyType),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((mainPowerSupplyType) => {
        this.selectedType = mainPowerSupplyType;
        this.form.patchValue({ type: mainPowerSupplyType });
      });
  }

  public selectType(selectedType: MAIN_POWER_SUPPLY_TYPE): void {
    this.selectedType = selectedType;
    this.form.get("type")?.setValue(selectedType);
    this.allTouched = false;
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.form.markAllAsTouched();
    }
  }

  private updateState(): void {
    this.appStateService.updateFormData({
      mainPowerSupplyType: this.selectedType,
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
