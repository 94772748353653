import { Component, Input } from "@angular/core";

import { FormContact } from "@app/models/form-data.interface";
import { ROUTES } from "@app/models/routes.enum";

@Component({
  selector: "app-registration-contact-data",
  templateUrl: "./registration-contact-data.component.html",
})
export class RegistrationContactDataComponent {
  @Input({ required: true }) public contact!: FormContact;
  public contactRoute = ROUTES.CONTACT;
}
