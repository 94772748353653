import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { filter, map, Subject, take, takeUntil } from "rxjs";

import { GRID_CONNECTION_TYPE } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";

interface GridConnectionDetails {
  icon?: string;
  svgIcon?: string;
  label: string;
  type: GRID_CONNECTION_TYPE;
}

@Component({
  selector: "app-grid-connection-type",
  templateUrl: "./grid-connection-type.component.html",
})
export class GridConnectionTypeComponent implements OnInit, OnDestroy {
  public readonly connectionDetailsList: GridConnectionDetails[] = [
    {
      icon: "add_circle",
      label: "GRID_CONNECTION_TYPE.FORM.NEW",
      type: GRID_CONNECTION_TYPE.NEW,
    },
    {
      icon: "keyboard_double_arrow_up",
      label: "GRID_CONNECTION_TYPE.FORM.POWER",
      type: GRID_CONNECTION_TYPE.POWER,
    },
    {
      icon: "settings",
      label: "GRID_CONNECTION_TYPE.FORM.CHANGE",
      type: GRID_CONNECTION_TYPE.CHANGE,
    },
    {
      icon: "cancel",
      label: "GRID_CONNECTION_TYPE.FORM.DETACH",
      type: GRID_CONNECTION_TYPE.DETACH,
    },
  ];

  public gridConnectionTypes = GRID_CONNECTION_TYPE;
  public selectedType?: GRID_CONNECTION_TYPE;
  private onDestroy$: Subject<void> = new Subject();
  public gridConnectionTypeFormControl!: FormControl;
  public allTouched = false;

  constructor(
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createProductForm();
    this.updateForm();
    this.watchForm();
  }

  private createProductForm(): void {
    this.gridConnectionTypeFormControl = new FormControl(
      "",
      Validators.required,
    );
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.gridConnectionType),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((gridConnectionType) => {
        this.gridConnectionTypeFormControl.patchValue(gridConnectionType);
        this.selectedType = gridConnectionType;
      });
  }

  private watchForm(): void {
    this.gridConnectionTypeFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((gridConnectionType) =>
        this.appStateService.updateFormData({ gridConnectionType }),
      );
  }

  public selectType(selectedType: GRID_CONNECTION_TYPE): void {
    this.gridConnectionTypeFormControl?.setValue(selectedType);
    this.selectedType = selectedType;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    this.allTouched = true;
    this.gridConnectionTypeFormControl.markAllAsTouched();
    if (this.gridConnectionTypeFormControl.valid) {
      this.routeService.navigateToNextStep();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
