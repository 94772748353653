import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
  heading: string;
  message: string;
  acceptLabel: string;
  rejectLabel: string;
  translateParameters?: { [translateParameters: string]: string };
}

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private dialogRef: MatDialogRef<DialogComponent>,
  ) {
    dialogRef.disableClose = true;
    if (document.activeElement instanceof HTMLElement)
      document.activeElement.blur();
  }

  public accept(): void {
    this.dialogRef.close(true);
  }

  public reject(): void {
    this.dialogRef.close(false);
  }
}
