<app-summary-section
  [stepRoute]="routes.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE"
  heading="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.HEADING"
>
  <app-chip>
    {{
      "COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.TYPE." + mainPowerSupplyType
        | translate
    }}
  </app-chip>
</app-summary-section>

@switch (mainPowerSupplyType) {
  @case (type.NEW_GRID_CONNECTION) {
    <app-new-grid-connection-summary
      [buildingDetails]="buildingDetails!"
      [electricityInformation]="electricityInformation!"
    />
  }
  @case (type.POWER_INCREASE) {
    <app-power-increase-summary
      [powerIncreaseDetails]="powerIncreaseDetails!"
    />
  }
}
