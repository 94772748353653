/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Injectable } from "@angular/core";

import {
  COMMISSIONING_FLOW_TYPE,
  CommissioningContact,
  CommissioningMappedData,
  CustomerFacility,
  CustomerFacilityWithMeterChanges,
  CustomerFacilityWithoutMeterChanges,
  ELECTRICITY_SYSTEM_TYPE,
  ElectricityCommissioningFlowDetails,
  ElectricityCommissioningFlowDetailsTypes,
  ElectricityInstallerContact,
  ElectricitySystem,
  MainPowerSupply,
  MainPowerSupplyDecommissioning,
  MainPowerSupplyNewGridConnection,
  MainPowerSupplyPowerIncrease,
  MAIN_POWER_SUPPLY_TYPE,
  MeasurementDetails,
} from "@app/models/commissioning-mapped-data.interface";
import {
  CommissioningElectricityBuildingDetails,
  CommissioningElectricityInformation,
  CommissioningElectricitySimpleMeasurementDetails,
  CommissioningElectricitySystems,
  FLOW_MODULE,
  FormDataObject,
  SystemDetails,
} from "@app/models/form-data.interface";

import { PartialFormData } from "./app-state.service";
import { FormMapperService } from "./form-mapper.service";

@Injectable({
  providedIn: "root",
})
export class CommissioningFormMapperService {
  constructor(private formMapperService: FormMapperService) {}

  public mapCommissioningFormData(
    customerConfigurationId: string,
    formDataObject: PartialFormData,
    documentsUploadEnabled: boolean,
  ): CommissioningMappedData {
    return {
      flow: {
        flowDetails: this.mapFlowDetails(
          formDataObject,
          documentsUploadEnabled,
        ),
        installerContact: this.mapInstallerContact(formDataObject),
        module: FLOW_MODULE.ELECTRICITY, //Note: remove hardcoded value once other modules are implemented
      },
      location: {
        address: {
          zipCode: formDataObject.address?.zipCode!,
          city: formDataObject.address?.city!,
          street: formDataObject.address?.street,
          streetNumber: formDataObject.address?.streetNumber,
        },
        district: formDataObject.address?.additionalAddressData?.district,
        parcel: formDataObject.address?.additionalAddressData?.parcel,
        parcelNumber:
          formDataObject.address?.additionalAddressData?.parcelNumber,
      },
      contact: this.mapContact(formDataObject),
      metadata: {
        customerConfigurationId: customerConfigurationId,
      },
      requestType: "COMMISSIONING",
    };
  }

  private mapFlowDetails(
    {
      commissioningFlowType,
      mainPowerSupplyType,
      commissioningElectricityInformation,
      commissioningElectricityBuildingDetails,
      mainPowerSupplyPowerIncreaseDetails,
      commissioningElectricitySystems,
      commissioningElectricitySimpleMeasurementDetails,
      commissioningElectricityMeasurementDetails,
      documentDetails,
    }: FormDataObject,
    documentsUploadEnabled: boolean,
  ): ElectricityCommissioningFlowDetails {
    let flowDetails: ElectricityCommissioningFlowDetailsTypes;
    switch (commissioningFlowType) {
      case COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY:
        flowDetails = this.mapMainPowerSupply(
          mainPowerSupplyType,
          commissioningElectricityBuildingDetails,
          commissioningElectricityInformation,
          mainPowerSupplyPowerIncreaseDetails,
        );
        break;
      case COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY:
        flowDetails = this.mapCustomerFacility(
          commissioningElectricitySystems,
          commissioningElectricitySimpleMeasurementDetails,
          commissioningElectricityMeasurementDetails,
        );
        break;
    }

    return {
      ...flowDetails!,
      documentDetails: this.formMapperService.mapDocumentDetails(
        documentDetails,
        documentsUploadEnabled,
      ),
    };
  }

  private mapMainPowerSupply(
    mainPowerSupplyType?: MAIN_POWER_SUPPLY_TYPE,
    commissioningElectricityBuildingDetails?: CommissioningElectricityBuildingDetails,
    commissioningElectricityInformation?: CommissioningElectricityInformation,
    mainPowerSupplyPowerIncreaseDetails?: MainPowerSupplyPowerIncrease,
  ): MainPowerSupply {
    let details:
      | MainPowerSupplyNewGridConnection
      | MainPowerSupplyPowerIncrease
      | MainPowerSupplyDecommissioning;
    switch (mainPowerSupplyType!) {
      case MAIN_POWER_SUPPLY_TYPE.NEW_GRID_CONNECTION:
        details = {
          type: mainPowerSupplyType,
          buildingType: commissioningElectricityBuildingDetails?.buildingType!,
          flatCount: commissioningElectricityBuildingDetails?.flatCount,
          area: commissioningElectricityBuildingDetails?.area,
          systemPower: commissioningElectricityInformation?.systemPower!,
          connectionFuse: commissioningElectricityInformation?.connectionFuse!,
          constructionElectricity:
            commissioningElectricityInformation?.constructionElectricity!,
        };
        break;
      case MAIN_POWER_SUPPLY_TYPE.POWER_INCREASE:
        details = {
          type: mainPowerSupplyType,
          currentPowerDemand:
            mainPowerSupplyPowerIncreaseDetails?.currentPowerDemand!,
          plannedPowerDemand:
            mainPowerSupplyPowerIncreaseDetails?.plannedPowerDemand!,
        };
        break;
      case MAIN_POWER_SUPPLY_TYPE.DECOMMISSIONING:
        details = {
          type: mainPowerSupplyType,
        };
        break;
    }
    return {
      type: COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY,
      details,
    };
  }
  private mapCustomerFacility(
    electricitySystems?: CommissioningElectricitySystems,
    simpleMeasurementDetails?: CommissioningElectricitySimpleMeasurementDetails,
    complexMeasurementDetails?: MeasurementDetails[],
  ): CustomerFacility {
    let details:
      | CustomerFacilityWithoutMeterChanges
      | CustomerFacilityWithMeterChanges;
    if (electricitySystems?.meterChanges) {
      details = this.mapCustomerFacilityWithMeterChanges(
        complexMeasurementDetails!,
        electricitySystems,
      );
    } else {
      details = this.mapCustomerFacilityWithoutMeterChanges(
        simpleMeasurementDetails!,
        electricitySystems!,
      );
    }
    return { type: COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY, ...details };
  }

  public mapElectricitySystems(
    commissioningElectricitySystems?: CommissioningElectricitySystems,
  ): ElectricitySystem[] | undefined {
    if (!commissioningElectricitySystems) {
      return undefined;
    }
    return Object.entries(commissioningElectricitySystems)
      .filter(({ 0: systemKey }) =>
        Object.values(ELECTRICITY_SYSTEM_TYPE).some((key) => systemKey === key),
      )
      .filter(({ 1: system }) => (system as SystemDetails).selected)
      .map(
        ({ 0: type, 1: electricitySystem }) =>
          ({
            type: type as ELECTRICITY_SYSTEM_TYPE,
            systemPower: electricitySystem.systemPower,
            systemCount: electricitySystem.systemCount,
            systemType: electricitySystem.systemType,
            notes: electricitySystem.notes,
          }) as ElectricitySystem,
      );
  }

  private mapCustomerFacilityWithoutMeterChanges(
    simpleMeasurementDetails: CommissioningElectricitySimpleMeasurementDetails,
    electricitySystems: CommissioningElectricitySystems,
  ): CustomerFacilityWithoutMeterChanges {
    return {
      meterChanges: false,
      electricitySystems: this.mapElectricitySystems(electricitySystems)!,
      meterNumbers: simpleMeasurementDetails.meterNumbers,
    };
  }

  private mapCustomerFacilityWithMeterChanges(
    measurementDetails: MeasurementDetails[],
    electricitySystems?: CommissioningElectricitySystems,
  ): CustomerFacilityWithMeterChanges {
    return {
      meterChanges: true,
      electricitySystems: this.mapElectricitySystems(electricitySystems),
      measurementDetails: measurementDetails,
    };
  }

  private mapInstallerContact({
    commissioningElectricityContact,
  }: FormDataObject): ElectricityInstallerContact {
    return {
      module: FLOW_MODULE.ELECTRICITY,
      gridOperator: commissioningElectricityContact?.gridOperator!,
      idNumber: commissioningElectricityContact?.idNumber!,
      name: commissioningElectricityContact?.electricianName!,
      location: commissioningElectricityContact?.location!,
      email: commissioningElectricityContact?.electricianEmail!,
      phone: commissioningElectricityContact?.phone!,
    };
  }

  private mapContact({
    commissioningElectricityContact,
  }: FormDataObject): CommissioningContact {
    return {
      requesterContact: commissioningElectricityContact?.contactDetails!,
      differentAddress: commissioningElectricityContact?.differentAddress!,
      differentRequesterAddress:
        commissioningElectricityContact?.differentRequesterAddress,
    };
  }
}
