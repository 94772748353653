<app-summary-section
  [stepRoute]="route"
  heading="STORAGE_CUSTOMER_FACILITY.HEADING"
>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        (storageCustomerFacility.plannedCommissioningDate
          | date: 'shortDate' : '' : currentLanguageCulture) || undefined
      "
      label="STORAGE_CUSTOMER_FACILITY.PLANNED_COMMISSIONING_DATE.TITLE"
    />

    <app-summary-section-field
      [value]="
        'STORAGE_CUSTOMER_FACILITY.FORM_OF_FEED_IN_SALE.' +
          storageCustomerFacility.formOfFeedInSale | translate
      "
      label="STORAGE_CUSTOMER_FACILITY.FORM_OF_FEED_IN_SALE.TITLE"
    />

    <app-summary-section-field
      [value]="
        (storageCustomerFacility.controllable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      "
      label="STORAGE_CUSTOMER_FACILITY.CONTROLLABLE.TITLE"
    />
  </div>
</app-summary-section>
