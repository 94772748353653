import { Component, inject } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";

import { SharedModule } from "@app/modules/shared/shared.module";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";

@Component({
  selector: "app-balcony-pv-summary",
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: "./balcony-pv-summary.component.html",
})
export class BalconyPvSummaryComponent {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #routeService: RouteService = inject(RouteService);

  public next(): void {
    this.#appStateService.clearState();
    this.#routeService.navigateToNextStep();
  }
}
