<app-form-card
  svgIcon="gas"
  cardTitle="WATER.WATER_REQUIREMENTS.HEADING"
  description="WATER.WATER_REQUIREMENTS.DESCRIPTION"
>
  <div class="space"></div>
  <form [formGroup]="waterRequirementsForm">
    <h2 class="full-width">
      {{ "WATER.WATER_REQUIREMENTS.SUBHEADING_CONSUMPTION" | translate }}
    </h2>

    <div class="input-container consumption-data-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "WATER.WATER_REQUIREMENTS.NUMBER_OF_HOUSING_UNITS" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="numberOfHousingUnits"
          formControlName="numberOfHousingUnits"
          min="0"
        />
        <mat-error appErrorMessages="numberOfHousingUnits" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "WATER.WATER_REQUIREMENTS.NUMBER_OF_RESIDENTS" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="numberOfResidents"
          formControlName="numberOfResidents"
          min="0"
        />
        <mat-error appErrorMessages="numberOfResidents" />
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="full-width">
      {{ "WATER.WATER_REQUIREMENTS.SUBHEADING_MORE_REQUIREMENTS" | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "WATER.WATER_REQUIREMENTS.NUMBER_OF_FLOORS" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="numberOfFloors"
          formControlName="numberOfFloors"
          min="0"
        />
        <mat-error appErrorMessages="numberOfFloors" />
      </mat-form-field>
    </div>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "WATER.WATER_REQUIREMENTS.EXTRACTION_POINT_HEIGHT" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="extractionPointHeight"
          formControlName="extractionPointHeight"
          min="0"
        />
        <mat-error appErrorMessages="extractionPointHeight" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "WATER.WATER_REQUIREMENTS.FLOW_RATE" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="flowRate"
          formControlName="flowRate"
          min="0"
        />
        <mat-error appErrorMessages="flowRate" />
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="full-width">
      {{
        "WATER.WATER_REQUIREMENTS.SUBHEADING_ADDITIONAL_CONSUMERS" | translate
      }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <textarea
          #additionalConsumers
          [placeholder]="
            'WATER.WATER_REQUIREMENTS.ADDITIONAL_CONSUMERS_PLACEHOLDER'
              | translate
          "
          [maxlength]="maxLength"
          matInput
          cols="50"
          rows="2"
          formControlName="additionalConsumers"
        ></textarea>
        <mat-hint align="end"
          >{{ additionalConsumers.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="additionalConsumers" />
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: waterRequirementsForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
