import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Observable } from "rxjs";

import { CommissioningMappedData } from "@app/models/commissioning-mapped-data.interface";
import { RegistrationForm } from "@app/models/registration-form";
import { HeaderUtils } from "@app/services/header-utils";

import { EnvironmentService } from "./environment.service";

@Injectable({
  providedIn: "root",
})
export class ConnectionRequestService {
  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService,
  ) {}

  private submit(
    connectionRequestData: RegistrationForm | CommissioningMappedData,
    captchaToken: string | null,
    isCommissioningFlow: boolean,
    files?: File[],
  ): Observable<RegistrationForm | CommissioningMappedData> {
    const formData = new FormData();

    if (files) {
      for (const file of files) {
        formData.append("documents", file);
      }
    }
    const payload = JSON.stringify(connectionRequestData);
    formData.append(
      "form",
      new Blob([payload], {
        type: "application/json",
      }),
    );
    // add raw payload to sentry scope to have it attached in case the http request fails
    Sentry.getCurrentScope().addAttachment({
      data: JSON.stringify(payload),
      filename: "form-payload.json",
      contentType: "application/json",
    });

    const headers = HeaderUtils.toHttpHeaders("Captcha-Token", captchaToken);
    const endpoint = isCommissioningFlow ? "commissioning" : "registration";

    return this.httpClient.post<RegistrationForm | CommissioningMappedData>(
      `${this.environment.apiUrl}/connection-requests/${endpoint}`,
      formData,
      {
        headers,
      },
    );
  }

  public submitRegistrationForm(
    data: RegistrationForm,
    verifiedToken: string | null,
    files?: File[],
  ): Observable<RegistrationForm> {
    return this.submit(
      data,
      verifiedToken,
      false,
      files,
    ) as Observable<RegistrationForm>;
  }

  public submitCommissioningForm(
    data: CommissioningMappedData,
    verifiedToken: string | null,
    files?: File[],
  ): Observable<CommissioningMappedData> {
    return this.submit(
      data,
      verifiedToken,
      true,
      files,
    ) as Observable<CommissioningMappedData>;
  }
}
