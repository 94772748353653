<app-form-card titleIcon="category" cardTitle="WATER.WATER_OPTIONS.HEADING">
  <h2 class="full-width">
    {{ "WATER.WATER_OPTIONS.DESCRIPTION" | translate }}
  </h2>
  <app-selection-group>
    @for (waterOption of waterOptionsList; track waterOption) {
      <app-selection-card
        (click)="selectType(waterOption.type)"
        [icon]="waterOption.icon"
        [label]="waterOption.label"
        [selected]="selectedOption === waterOption.type"
        class="selection-card"
      />
    }
    @if (!selectedOption && allTouched) {
      <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
    }
  </app-selection-group>

  @if (selectedOption) {
    <div class="space"></div>
    <form [formGroup]="waterOptionsForm">
      @switch (selectedOption) {
        @case (waterOptions.NEW) {
          <app-hint>
            <div>
              {{ "WATER.WATER_OPTIONS.NEW.INFO" | translate }}
              <ul class="list-container">
                <li>
                  {{ "SHARED.DOCUMENT_TYPE.SITE_PLAN" | translate }}
                </li>
                <li>
                  {{ "SHARED.DOCUMENT_TYPE.FLOOR_PLAN" | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
        }
        @case (waterOptions.CHANGE) {
          <div class="water-change-container">
            <app-hint>
              <div>
                {{ "WATER.WATER_OPTIONS.CHANGE.INFO" | translate }}
                <ul class="list-container">
                  <li>
                    {{ "SHARED.DOCUMENT_TYPE.SITE_PLAN" | translate }}
                  </li>
                  <li>
                    {{ "SHARED.DOCUMENT_TYPE.FLOOR_PLAN" | translate }}
                  </li>
                </ul>
              </div>
            </app-hint>
            <div class="space"></div>
            <h2>
              {{
                "WATER.WATER_OPTIONS.CHANGE.CONNECTION_CHANGE_QUESTION"
                  | translate
              }}
            </h2>
            <mat-checkbox formControlName="changeFlowRate" color="primary">{{
              "WATER.WATER_OPTIONS.CHANGE.CHANGE_FLOW_RATE_LABEL" | translate
            }}</mat-checkbox>
            <mat-checkbox formControlName="relocate" color="primary">{{
              "WATER.WATER_OPTIONS.CHANGE.RELOCATE_LABEL" | translate
            }}</mat-checkbox>
            <div class="space"></div>
            @if (changeFlowRateControl?.value) {
              <div>
                <h2 class="tooltip-heading-label">
                  {{
                    "WATER.WATER_OPTIONS.CHANGE.CHANGE_FLOW_RATE_LABEL"
                      | translate
                  }}
                  <app-info-tooltip
                    tooltipText="WATER.WATER_OPTIONS.CHANGE.CHANGE_FLOW_RATE_TOOLTIP"
                  />
                </h2>
                <div class="input-container">
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      "WATER.WATER_OPTIONS.CHANGE.CURRENT_FLOW_RATE_PLACEHOLDER"
                        | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="number"
                      name="currentFlowRate"
                      formControlName="currentFlowRate"
                      min="0"
                    />
                    <mat-error appErrorMessages="currentFlowRate" />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      "WATER.WATER_OPTIONS.CHANGE.PLANNED_FLOW_RATE_PLACEHOLDER"
                        | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="number"
                      name="plannedFlowRate"
                      formControlName="plannedFlowRate"
                      min="0"
                    />
                    <mat-error appErrorMessages="plannedFlowRate" />
                  </mat-form-field>
                </div>
                <div class="space"></div>
                <h2>
                  {{
                    "WATER.WATER_OPTIONS.CHANGE.ADDITIONAL_DEMAND_QUESTION"
                      | translate
                  }}
                </h2>
                <div class="input-container">
                  <mat-form-field appearance="outline" class="textarea-field">
                    <mat-label>{{
                      "WATER.WATER_OPTIONS.CHANGE.ADDITIONAL_DEMAND_PLACEHOLDER"
                        | translate
                    }}</mat-label>
                    <textarea
                      #additionalDemand
                      [maxlength]="maxLength"
                      matInput
                      cols="50"
                      rows="2"
                      formControlName="additionalDemand"
                    ></textarea>
                    <mat-hint align="end"
                      >{{ additionalDemand.value.length }} /
                      {{ maxLength }}</mat-hint
                    >
                    <mat-error appErrorMessages="additionalDemand" />
                  </mat-form-field>
                </div>
                <div class="space"></div>
              </div>
            }
            @if (relocateControl?.value) {
              <div>
                <h2 class="tooltip-heading-label">
                  {{ "WATER.WATER_OPTIONS.CHANGE.RELOCATE_LABEL" | translate }}
                  <app-info-tooltip
                    tooltipText="WATER.WATER_OPTIONS.CHANGE.RELOCATE_TOOLTIP"
                  />
                </h2>
                <div class="input-container">
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      "WATER.WATER_OPTIONS.CHANGE.DITCH_LENGTH_PLACEHOLDER"
                        | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="number"
                      name="ditchLength"
                      formControlName="ditchLength"
                      min="0"
                    />
                    <mat-error appErrorMessages="ditchLength" />
                  </mat-form-field>
                </div>
                <div class="space"></div>
              </div>
            }
          </div>
        }
        @case (waterOptions.DETACH) {
          <div>
            <app-hint>
              <div>
                {{ "WATER.WATER_OPTIONS.DETACH.INFO" | translate }}
                <ul class="list-container">
                  <li>
                    {{ "SHARED.DOCUMENT_TYPE.PROOF_OF_OWNERSHIP" | translate }}
                  </li>
                  <li>
                    {{ "SHARED.DOCUMENT_TYPE.SITE_PLAN" | translate }}
                  </li>
                </ul>
              </div>
            </app-hint>
            <div class="space"></div>
            <h2>
              {{
                ("WATER.WATER_OPTIONS.DETACH.DECONSTRUCTION_TYPE_QUESTION"
                  | translate) + " *"
              }}
            </h2>
            <mat-radio-group
              formControlName="permanentDeconstruction"
              class="input-container"
            >
              <div class="deconstruction-type-container">
                <mat-radio-button [value]="false" color="primary"
                  >{{
                    "WATER.WATER_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_LABEL"
                      | translate
                  }}
                </mat-radio-button>
                <app-info-tooltip
                  tooltipText="WATER.WATER_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_TOOLTIP"
                />
              </div>
              <div class="deconstruction-type-container">
                <mat-radio-button [value]="true" color="primary">
                  {{
                    "WATER.WATER_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_LABEL"
                      | translate
                  }}
                </mat-radio-button>
                <app-info-tooltip
                  tooltipText="WATER.WATER_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_TOOLTIP"
                />
              </div>
              <mat-error
                [hideError]="!allTouched"
                appErrorMessages="permanentDeconstruction"
              />
            </mat-radio-group>
            @if (
              (waterOptionsForm | formLookup: "permanentDeconstruction") ===
              false
            ) {
              <div class="input-container">
                <mat-form-field appearance="outline" class="input-field">
                  <mat-label>{{
                    "WATER.WATER_OPTIONS.DETACH.DECONSTRUCTION_UNTIL"
                      | translate
                  }}</mat-label>
                  <input
                    [min]="dateForTomorrow"
                    [matDatepicker]="picker"
                    matInput
                    formControlName="deconstructionUntil"
                  />
                  <mat-datepicker-toggle [for]="picker" matIconSuffix />
                  <mat-datepicker #picker />
                  @if (
                    waterOptionsForm
                      | hasError: "deconstructionUntil" : "required"
                  ) {
                    <mat-error
                      >{{ errorMessageForInvalidDateFormat }}
                    </mat-error>
                  }
                  @if (
                    waterOptionsForm
                      | hasError: "deconstructionUntil" : "matDatepickerMin"
                  ) {
                    <mat-error
                      >{{ "VALIDATION.DATE_PICKER_MIN" | translate }}
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            }
            <h2 class="tooltip-heading-label">
              {{ "WATER.WATER_OPTIONS.DETACH.METER_NUMBER" | translate }}
              <app-info-tooltip
                tooltipText="WATER.WATER_OPTIONS.DETACH.METER_NUMBER_TOOLTIP"
              />
            </h2>
            <div class="input-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>
                  {{ "WATER.WATER_OPTIONS.DETACH.METER_NUMBER" | translate }}
                </mat-label>
                <input
                  matInput
                  type="text"
                  name="meterNumber"
                  formControlName="meterNumber"
                />
                <mat-error appErrorMessages="meterNumber" />
              </mat-form-field>
            </div>
          </div>
        }
      }
    </form>
  }

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: waterOptionsForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
