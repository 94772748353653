<app-form-card
  titleIcon="3p"
  cardTitle="SUMMARY.HEADING"
  dataTestId="summary-heading"
>
  <p class="description">
    {{ "SUMMARY.DESCRIPTION" | translate }}
  </p>
  @if (loading()) {
    <app-loading-spinner />
  }

  <div>
    @if ((state$ | async)?.formData; as formData) {
      <!-- selected module -->
      <app-summary-section
        [stepRoute]="routes.MODULE_SELECTION"
        heading="MODULE_SELECTION.HEADING_SUMMARY"
      >
        <app-chip>
          {{ "MODULE_SELECTION." + formData.selectedModule | translate }}
        </app-chip>
      </app-summary-section>

      @if (areMultipleFlowsEnabled$ | async) {
        <!-- selected flow -->
        <app-summary-section
          [stepRoute]="routes.FLOW_SELECTION"
          heading="FLOW_SELECTION.HEADING"
        >
          <app-chip>
            {{ "FLOW_SELECTION." + formData.selectedFlow | translate }}
          </app-chip>
        </app-summary-section>
      }

      <!-- address -->
      <app-summary-section
        [stepRoute]="routes.ADDRESS"
        heading="ADDRESS.SUBHEADING"
      >
        <div class="section-fields-container">
          @if (formData.address) {
            <app-address [address]="formData.address" />
          }
          @if (formData.address?.streetNotListed) {
            <app-summary-section-field
              [value]="formData.address?.additionalAddressData?.district"
              label="ADDRESS.FORM.DISTRICT"
            />
            <app-summary-section-field
              [value]="formData.address?.additionalAddressData?.parcel"
              label="ADDRESS.FORM.PARCEL"
            />
            <app-summary-section-field
              [value]="formData.address?.additionalAddressData?.parcelNumber"
              label="ADDRESS.FORM.PARCEL_NUMBER"
            />
          }
        </div>
      </app-summary-section>
      <!-- WATER MODULE -->
      @if (formData.selectedModule === module.WATER) {
        <!-- water area -->
        <app-summary-section
          [stepRoute]="routes.WATER_AREA"
          heading="WATER.WATER_AREA.HEADING"
        >
          <app-chip>
            {{ "WATER.WATER_AREA." + formData.waterArea | translate }}
          </app-chip>
        </app-summary-section>
        <!-- sewage -->
        @if (formData.waterArea === formType.SEWAGE) {
          <!-- sewage options -->
          <app-summary-section
            [stepRoute]="routes.SEWAGE_OPTIONS"
            heading="WATER.SEWAGE_OPTIONS.HEADING"
          >
            <app-chip>
              {{
                "WATER.SEWAGE_OPTIONS." +
                  formData.sewageOptions?.sewageOption +
                  ".LABEL" | translate
              }}
            </app-chip>
            @if (
              formData.sewageOptions?.sewageOption === sewageOptions.DETACH
            ) {
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="
                    (formData.sewageOptions?.permanentDeconstruction
                      ? 'WATER.SEWAGE_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_LABEL'
                      : 'WATER.SEWAGE_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
                    ) | translate
                  "
                  label="WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_TYPE_QUESTION"
                />
                @if (!formData.sewageOptions?.permanentDeconstruction) {
                  <app-summary-section-field
                    [value]="
                      (formData.sewageOptions?.deconstructionUntil
                        | date: 'shortDate' : '' : currentLanguageCulture) ||
                      '-'
                    "
                    label="WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_UNTIL"
                  />
                }
                <app-summary-section-field
                  [value]="formData.sewageOptions?.meterNumber"
                  label="WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER"
                />
              </div>
            }
          </app-summary-section>
          <!-- sewage property details -->
          @if (formData.sewageOptions?.sewageOption === sewageOptions.NEW) {
            <app-summary-section
              [stepRoute]="routes.WATER_PROPERTY_TYPE"
              heading="WATER.WATER_PROPERTY_TYPE.HEADING"
            >
              <app-summary-building-type
                [buildingType]="formData.waterBuildingDetails?.buildingType"
              />
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="
                    'SHARED.BUILDING_STRUCTURE.' +
                      formData.waterBuildingDetails?.buildingStructure
                      | translate
                  "
                  label="SHARED.BUILDING_STRUCTURE.LABEL"
                />
              </div>
            </app-summary-section>
          }
          @if (formData.sewageOptions?.sewageOption === sewageOptions.NEW) {
            <app-summary-section
              [stepRoute]="routes.SEWAGE_TYPE"
              heading="WATER.SEWAGE_TYPE.HEADING"
            >
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="
                    'WATER.SEWAGE_TYPE.SEWAGE_TYPES.' + formData.sewageType
                      | translate
                  "
                  label="WATER.SEWAGE_TYPE.SEWAGE_TYPE_LABEL"
                />
              </div>
            </app-summary-section>
          }
        }
        <!-- construction water requirements -->
        @if (
          formData.waterArea === formType.CONSTRUCTION_WATER &&
          formData.constructionWaterRequirements
        ) {
          <app-summary-section
            [stepRoute]="routes.CONSTRUCTION_WATER_REQUIREMENTS"
            heading="WATER.CONSTRUCTION_WATER_REQUIREMENTS.HEADING"
          >
            <app-construction-water-requirements-summary
              [constructionWaterRequirements]="
                formData.constructionWaterRequirements
              "
            />
          </app-summary-section>
        }
        <!-- water connection -->
        @if (formData.waterArea === formType.WATER) {
          <!-- water options -->
          <app-summary-section
            [stepRoute]="routes.WATER_OPTIONS"
            heading="WATER.WATER_OPTIONS.HEADING"
          >
            <app-chip>
              {{
                "WATER.WATER_OPTIONS." +
                  formData.waterOptions?.waterOption +
                  ".LABEL" | translate
              }}
            </app-chip>
            @if (formData.waterOptions?.waterOption === waterOptions.CHANGE) {
              @if (formData.waterOptions?.changeFlowRate) {
                <p class="section-heading">
                  {{
                    "WATER.WATER_OPTIONS.CHANGE.CHANGE_FLOW_RATE_LABEL"
                      | translate
                  }}
                </p>
                <div class="section-fields-container">
                  <app-summary-section-field
                    [value]="formData.waterOptions?.currentFlowRate"
                    label="WATER.WATER_OPTIONS.CHANGE.CURRENT_FLOW_RATE_PLACEHOLDER"
                  />
                  <app-summary-section-field
                    [value]="formData.waterOptions?.plannedFlowRate"
                    label="WATER.WATER_OPTIONS.CHANGE.PLANNED_FLOW_RATE_PLACEHOLDER"
                  />
                  <app-summary-section-field
                    [value]="formData.waterOptions?.additionalDemand"
                    label="WATER.WATER_OPTIONS.CHANGE.ADDITIONAL_DEMAND_QUESTION"
                  />
                </div>
              }
              @if (formData.waterOptions?.relocate) {
                <p class="section-heading">
                  {{ "WATER.WATER_OPTIONS.CHANGE.RELOCATE_LABEL" | translate }}
                </p>
                <div class="section-fields-container">
                  <app-summary-section-field
                    [value]="formData.waterOptions?.ditchLength"
                    label="WATER.WATER_OPTIONS.CHANGE.DITCH_LENGTH_PLACEHOLDER"
                  />
                </div>
              }
            }
            @if (formData.waterOptions?.waterOption === waterOptions.DETACH) {
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="
                    (formData.waterOptions?.permanentDeconstruction
                      ? 'WATER.WATER_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_LABEL'
                      : 'WATER.WATER_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
                    ) | translate
                  "
                  label="WATER.WATER_OPTIONS.DETACH.DECONSTRUCTION_TYPE_QUESTION"
                />
                @if (!formData.waterOptions?.permanentDeconstruction) {
                  <app-summary-section-field
                    [value]="
                      (formData.waterOptions?.deconstructionUntil
                        | date: 'shortDate' : '' : currentLanguageCulture) ||
                      '-'
                    "
                    label="WATER.WATER_OPTIONS.DETACH.DECONSTRUCTION_UNTIL"
                  />
                }
                <app-summary-section-field
                  [value]="formData.waterOptions?.meterNumber"
                  label="WATER.WATER_OPTIONS.DETACH.METER_NUMBER"
                />
              </div>
            }
          </app-summary-section>
          <!-- water property details -->
          @if (
            formData.waterOptions?.waterOption === waterOptions.NEW ||
            formData.waterOptions?.waterOption === waterOptions.CHANGE
          ) {
            <app-summary-section
              [stepRoute]="routes.WATER_PROPERTY_TYPE"
              heading="WATER.WATER_PROPERTY_TYPE.HEADING"
            >
              <app-summary-building-type
                [buildingType]="formData.waterBuildingDetails?.buildingType"
              />
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="
                    'SHARED.BUILDING_STRUCTURE.' +
                      formData.waterBuildingDetails?.buildingStructure
                      | translate
                  "
                  label="SHARED.BUILDING_STRUCTURE.LABEL"
                />
              </div>
            </app-summary-section>
          }
          <!-- water requirements -->
          @if (formData.waterOptions?.waterOption === waterOptions.NEW) {
            <app-summary-section
              [stepRoute]="routes.WATER_REQUIREMENTS"
              heading="WATER.WATER_REQUIREMENTS.HEADING"
            >
              <p class="section-heading">
                {{
                  "WATER.WATER_REQUIREMENTS.SUBHEADING_CONSUMPTION" | translate
                }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="
                    formData.waterRequirementDetails?.numberOfHousingUnits
                  "
                  label="WATER.WATER_REQUIREMENTS.NUMBER_OF_HOUSING_UNITS"
                />
                <app-summary-section-field
                  [value]="formData.waterRequirementDetails?.numberOfResidents"
                  label="WATER.WATER_REQUIREMENTS.NUMBER_OF_RESIDENTS"
                />
              </div>
              <p class="section-heading">
                {{
                  "WATER.WATER_REQUIREMENTS.SUBHEADING_MORE_REQUIREMENTS"
                    | translate
                }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="formData.waterRequirementDetails?.numberOfFloors"
                  label="WATER.WATER_REQUIREMENTS.NUMBER_OF_FLOORS"
                />
                <app-summary-section-field
                  [value]="
                    formData.waterRequirementDetails?.extractionPointHeight
                  "
                  label="WATER.WATER_REQUIREMENTS.EXTRACTION_POINT_HEIGHT"
                />
                <app-summary-section-field
                  [value]="formData.waterRequirementDetails?.flowRate"
                  label="WATER.WATER_REQUIREMENTS.FLOW_RATE"
                />
                <app-summary-section-field
                  [value]="
                    formData.waterRequirementDetails?.additionalConsumers
                  "
                  label="WATER.WATER_REQUIREMENTS.SUBHEADING_ADDITIONAL_CONSUMERS"
                />
              </div>
            </app-summary-section>
          }
        }
      }
      <!-- GAS MODULE -->
      @if (formData.selectedModule === module.GAS) {
        <!-- gas options -->
        <app-summary-section
          [stepRoute]="routes.GAS_OPTIONS"
          heading="GAS.GAS_OPTIONS.HEADING"
        >
          <app-chip>
            {{
              "GAS.GAS_OPTIONS." + formData.gasOptions?.gasOption + ".LABEL"
                | translate
            }}
          </app-chip>
          @if (formData.gasOptions?.gasOption === gasOptions.CHANGE) {
            @if (formData.gasOptions?.changePower) {
              <p class="section-heading">
                {{ "GAS.GAS_OPTIONS.CHANGE.CHANGE_POWER_LABEL" | translate }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="formData.gasOptions?.currentPowerDemand"
                  label="GAS.GAS_OPTIONS.CHANGE.CURRENT_POWER_DEMAND_PLACEHOLDER"
                />
                <app-summary-section-field
                  [value]="formData.gasOptions?.plannedPowerDemand"
                  label="GAS.GAS_OPTIONS.CHANGE.PLANNED_POWER_DEMAND_PLACEHOLDER"
                />
              </div>
            }
            @if (formData.gasOptions?.relocate) {
              <p class="section-heading">
                {{ "GAS.GAS_OPTIONS.CHANGE.RELOCATE_LABEL" | translate }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  [value]="formData.gasOptions?.ditchLength"
                  label="GAS.GAS_OPTIONS.CHANGE.DITCH_LENGTH_PLACEHOLDER"
                />
              </div>
            }
          }
          @if (formData.gasOptions?.gasOption === gasOptions.DETACH) {
            <div class="section-fields-container">
              <app-summary-section-field
                [value]="
                  (formData.gasOptions?.partialDeconstructionDesired
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                "
                label="GAS.GAS_OPTIONS.DETACH.PARTIAL_DECONSTRUCTION_DESIRED_QUESTION"
              />
              <app-summary-section-field
                [value]="formData.gasOptions?.meterNumber"
                label="GAS.GAS_OPTIONS.DETACH.METER_NUMBER"
              />
            </div>
          }
        </app-summary-section>
        <!-- gas property details -->
        @if (
          !(
            formData.gasOptions?.gasOption === gasOptions.CHANGE &&
            !formData.gasOptions?.relocate
          ) && formData.gasOptions?.gasOption !== gasOptions.DETACH
        ) {
          <app-summary-section
            [stepRoute]="routes.GAS_PROPERTY_TYPE"
            heading="GAS.GAS_PROPERTY_TYPE.HEADING"
          >
            <app-summary-building-type
              [buildingType]="formData.gasBuildingDetails?.buildingType"
            />
            <div class="section-fields-container">
              <app-summary-section-field
                [value]="
                  'SHARED.BUILDING_STRUCTURE.' +
                    formData.gasBuildingDetails?.buildingStructure | translate
                "
                label="SHARED.BUILDING_STRUCTURE.LABEL"
              />
              <app-summary-section-field
                [value]="
                  formData.gasBuildingDetails?.currentEnergySource
                    ? ('GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE.' +
                        formData.gasBuildingDetails?.currentEnergySource
                      | translate)
                    : '-'
                "
                label="GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE.LABEL"
              />
              <app-summary-section-field
                [value]="
                  formData.gasBuildingDetails?.houseInlet
                    ? ('SHARED.HOUSE_INLET.' +
                        formData.gasBuildingDetails?.houseInlet | translate)
                    : '-'
                "
                label="SHARED.HOUSE_INLET.LABEL"
              />
            </div>
          </app-summary-section>
        }
        <!-- gas power requirement -->
        @if (formData.gasOptions?.gasOption === gasOptions.NEW) {
          <app-summary-section
            [stepRoute]="routes.GAS_POWER_REQUIREMENT"
            heading="GAS.GAS_POWER_REQUIREMENT.HEADING"
          >
            <div class="section-fields-container">
              <app-summary-section-field
                [value]="formData.gasPowerRequirement?.powerDemand"
                label="GAS.GAS_POWER_REQUIREMENT.POWER_REQUIREMENT_PLACEHOLDER"
              />
              <app-summary-section-field
                [value]="formData.gasPowerRequirement?.notes"
                label="GAS.GAS_POWER_REQUIREMENT.ANYTHING_ELSE_QUESTION"
              />
            </div>
          </app-summary-section>
        }
      }
      <!-- ELECTRICITY MODULE -->
      @if (
        formData.selectedModule === module.ELECTRICITY && formData.formType
      ) {
        <!-- request -->
        <app-summary-section
          [stepRoute]="routes.ELECTRICITY_PRODUCT_SELECTION"
          heading="SUMMARY.REQUEST"
        >
          <app-chip>
            {{ "PRODUCTS." + formData.formType | translate }}
          </app-chip>
        </app-summary-section>
        <!-- pv information  -->
        @if (
          formData.formType === formType.PV_2 &&
          formData.pvCustomerFacility &&
          formData.pvInverter &&
          formData.pvGenerationUnit
        ) {
          <app-pv-summary
            [stepRoute]="routes.PV_FACILITY_INFORMATION"
            [pvCustomerFacility]="formData.pvCustomerFacility"
            [pvInverter]="formData.pvInverter"
            [pvGenerationUnit]="formData.pvGenerationUnit"
          />
        }
        <!-- grid connection type -->
        @if (formData.formType === formType.GRID_CONNECTION) {
          <app-summary-section
            [stepRoute]="routes.GRID_CONNECTION_TYPE"
            heading="GRID_CONNECTION_TYPE.HEADING"
          >
            <app-chip>
              {{
                "GRID_CONNECTION_TYPE.FORM." + formData.gridConnectionType
                  | translate
              }}
            </app-chip>
          </app-summary-section>
        }
        <!-- heat pump -->
        @if (formData.formType === formType.HEAT_PUMP_2) {
          @if (formData.heatPumpSystemInformation) {
            <app-heat-pump-system-information-summary
              [heatPumpSystemInformation]="formData.heatPumpSystemInformation"
            />
          }
          @if (
            formData.heatPumpSystemInformation?.controllable &&
            formData.heatPumpControllableDetails
          ) {
            <app-heat-pump-controllable-system-summary
              [controllableDetails]="formData.heatPumpControllableDetails"
            />
          }
          @if (formData.heatPumpInformation) {
            <app-heat-pump-information-summary
              [heatPumpInformation]="formData.heatPumpInformation"
            />
          }
        }

        <!-- construction electricity -->
        @if (formData.formType === formType.CONSTRUCTION_ELECTRICITY) {
          <app-summary-section
            [stepRoute]="routes.CONSTRUCTION_ELECTRICITY"
            heading="CONSTRUCTION_ELECTRICITY_INFORMATION.HEADING"
          >
            <app-construction-electricity-details-summary
              [constructionElectricityDetails]="
                formData.constructionElectricity!
              "
            />
          </app-summary-section>
        }

        <!-- OTHER_2 -->
        @if (formData.formType === formType.OTHER_2) {
          <app-other-type-summary [otherType]="formData.otherType!" />
          @switch (formData.otherType) {
            @case (OTHER_TYPE.NEW) {
              <app-other-customer-facility-summary
                [otherCustomerFacility]="formData.otherCustomerFacility!"
              />
              @if (
                formData.otherCustomerFacility?.controllable &&
                !!formData.otherControllableDetails
              ) {
                <app-other-controllable-details-summary
                  [controllableDetails]="formData.otherControllableDetails!"
                />
              }
              <app-other-system-details-summary
                [otherSystemDetails]="formData.otherSystemDetails!"
              />
            }
            @case (OTHER_TYPE.CHANGE) {
              <app-other-details-summary
                [otherDetails]="formData.otherDetails!"
              />
            }
          }
        }
        <!-- new grid connection building type -->
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.NEW
        ) {
          <app-summary-section
            [stepRoute]="routes.GRID_CONNECTION_PROPERTY_TYPE"
            heading="SUMMARY.BUILDING"
          >
            <app-summary-building-type
              [buildingType]="formData.newGridConnectionDetails?.buildingType"
            />
            <div class="section-fields-container">
              @if (
                formData.newGridConnectionDetails?.buildingType ===
                buildingType.MULTI_FAMILY_HOUSE
              ) {
                <app-summary-section-field
                  [value]="formData.newGridConnectionDetails?.flatCount"
                  label="GRID_CONNECTION_PROPERTY_TYPE.FORM.FLAT_COUNT"
                />
              }
              @if (
                formData.newGridConnectionDetails?.buildingType ===
                  buildingType.INDUSTRY ||
                formData.newGridConnectionDetails?.buildingType ===
                  buildingType.MIXED
              ) {
                <app-summary-section-field
                  [value]="formData.newGridConnectionDetails?.area"
                  label="GRID_CONNECTION_PROPERTY_TYPE.FORM.AREA"
                />
              }
              <app-summary-section-field
                [value]="
                  (formData.newGridConnectionDetails?.basementAvailable
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                "
                label="GRID_CONNECTION_PROPERTY_TYPE.FORM.BASEMENT_QUESTION"
              />
              <app-summary-section-field
                [value]="
                  (formData.newGridConnectionDetails?.externalConnection
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                "
                label="GRID_CONNECTION_PROPERTY_TYPE.FORM.EXTERNAL_CONNECTION_QUESTION"
              />
            </div>
          </app-summary-section>
        }
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.CHANGE
        ) {
          <!-- grid connection change -->
          <app-summary-section
            [stepRoute]="routes.GRID_CONNECTION_CHANGE"
            heading="GRID_CONNECTION_CHANGE.HEADING"
          >
            <app-chip>
              {{
                "GRID_CONNECTION_CHANGE.CHANGE_TYPE." +
                  formData.changeGridConnectionDetails?.changeType | translate
              }}
            </app-chip>
            <div class="section-fields-container">
              <app-summary-section-field
                [value]="formData.changeGridConnectionDetails?.ditchLength"
                label="SUMMARY.DITCH_LENGTH"
              />
              <app-summary-section-field
                [value]="
                  (formData.changeGridConnectionDetails?.totalPowerChanged
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                "
                label="GRID_CONNECTION_CHANGE.TOTAL_POWER_CHANGED"
              />
            </div>
          </app-summary-section>
          <!-- grid connection power change -->
          @if (formData.changeGridConnectionDetails?.totalPowerChanged) {
            <ng-template [ngTemplateOutlet]="gridConnectionPowerChange" />
          }
        }
        <!-- grid connection power change -->
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.POWER
        ) {
          <ng-template [ngTemplateOutlet]="gridConnectionPowerChange" />
        }
        <!-- planned facilities for new grid connection -->
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.NEW
        ) {
          <app-summary-section
            [stepRoute]="routes.GRID_CONNECTION_CONSUMER"
            heading="SUMMARY.PLANNED_FACILITIES"
          >
            <span class="consumer-container">
              @if (formData.gridConnectionConsumer?.type?.HEAT_PUMP) {
                <app-chip>
                  {{ "SHARED.CONSUMER.HEAT_PUMP" | translate }}
                </app-chip>
              }
              @if (formData.gridConnectionConsumer?.type?.FLOW_HEATER) {
                <app-chip>
                  {{ "SHARED.CONSUMER.FLOW_HEATER" | translate }}
                </app-chip>
              }
              @if (formData.gridConnectionConsumer?.type?.WALLBOX) {
                <app-chip>
                  {{ "SHARED.CONSUMER.WALLBOX" | translate }}
                </app-chip>
              }
            </span>
            <div class="section-fields-container">
              <app-summary-section-field
                [value]="formData.gridConnectionConsumer?.systemPower"
                label="SHARED.CONSUMER.SYSTEM_POWER"
              />
              <app-summary-section-field
                [value]="formData.gridConnectionConsumer?.connectionFuse"
                label="SHARED.CONSUMER.CONNECTION_FUSE"
              />
              <app-summary-section-field
                [value]="formData.gridConnectionConsumer?.maxConcurrentPower"
                label="GRID_CONNECTION_CONSUMER.MAX_CONCURRENT_POWER"
              />
              <app-summary-section-field
                [value]="formData.gridConnectionConsumer?.additionalConsumers"
                label="SHARED.CONSUMER.OTHER_CONSUMER_LABEL"
              />
            </div>
          </app-summary-section>
        }
      }
      <!-- STORAGE_2 -->
      @if (formData.formType === formType.STORAGE_2) {
        @if (formData.storageCustomerFacility) {
          <app-storage-customer-facility-summary
            [storageCustomerFacility]="formData.storageCustomerFacility"
          />
        }
        @if (
          formData.storageCustomerFacility?.controllable &&
          formData.storageControllableDetails
        ) {
          <app-storage-controllable-system-summary
            [controllableDetails]="formData.storageControllableDetails"
            [networkAndSystemProtectionDetails]="
              formData.storageNetworkAndSystemProtectionDetails
            "
          />
        }
        @if (formData.storageDetailsDto) {
          <app-storage-details-summary
            [storageDetails]="formData.storageDetailsDto"
          />
        }
      }

      <!-- charging device 2 -->
      @if (formData.formType === formType.CHARGING_DEVICE_2) {
        @if (formData.chargingDeviceSystemInformation) {
          <app-charging-device-system-information-summary
            [chargingDeviceSystemInformation]="
              formData.chargingDeviceSystemInformation
            "
          />
        }
        @if (
          formData.chargingDeviceSystemInformation?.controllable &&
          formData.chargingDeviceControllableDetails
        ) {
          <app-charging-device-controllable-system-summary
            [controllableDetails]="formData.chargingDeviceControllableDetails"
          />
        }
        @if (formData.chargingDeviceInformation) {
          <app-charging-device-information-summary
            [chargingDeviceInformation]="formData.chargingDeviceInformation"
          />
        }
      }

      <!-- COMMISSIONING -->
      @if (
        formData.selectedFlow === FLOW.COMMISSIONING &&
        formData.commissioningFlowType
      ) {
        @if (formData?.selectedModule) {
          <app-summary-section
            [stepRoute]="
              commissioningFlowSelectionRoutes.get(formData.selectedModule!!)
            "
            heading="SUMMARY.REQUEST"
          >
            <app-chip>
              {{
                "COMMISSIONING_FLOW_TYPE." + formData.commissioningFlowType
                  | translate
              }}
            </app-chip>
          </app-summary-section>
        }
        @switch (formData.commissioningFlowType) {
          @case (COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY) {
            @if (formData.mainPowerSupplyType) {
              <app-main-power-supply-summary
                [mainPowerSupplyType]="formData.mainPowerSupplyType!"
                [buildingDetails]="
                  formData.commissioningElectricityBuildingDetails
                "
                [electricityInformation]="
                  formData.commissioningElectricityInformation
                "
                [powerIncreaseDetails]="
                  formData.mainPowerSupplyPowerIncreaseDetails
                "
              />
            }
          }
          @case (COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY) {
            @if (formData.commissioningElectricitySystems) {
              <app-customer-facility-summary
                [electricitySystems]="formData.commissioningElectricitySystems"
                [meterChanges]="
                  formData.commissioningElectricitySystems.meterChanges
                "
                [simpleMeasurementDetails]="
                  formData.commissioningElectricitySimpleMeasurementDetails
                "
                [complexMeasurementDetails]="
                  formData.commissioningElectricityMeasurementDetails
                "
              />
            }
          }
        }
      }
      <!-- HEAT MODULE -->
      @if (formData.selectedModule === module.HEAT) {
        <app-heat-summary [formData]="formData" />
      }
      <!-- document upload -->
      @if ((whiteLabelConfig$ | async)?.documentsUploadEnabled) {
        <app-summary-section
          [stepRoute]="routes.DOCUMENTS_UPLOAD"
          heading="DOCUMENTS_UPLOAD.HEADING"
        >
          @if (formData.documentDetails?.documents?.length) {
            <div class="upload-document-section">
              @for (
                document of formData.documentDetails?.documents;
                track document
              ) {
                <div class="document-container">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="file icon"
                    fontIcon="attach_file"
                    class="attach-file-icon material-icons-outlined"
                  />
                  <span class="bold">{{ document.name }}</span>
                </div>
              }
            </div>
          } @else {
            <p class="no-documents-uploaded">
              {{ "SUMMARY.NO_DOCUMENTS_UPLOADED" | translate }}
            </p>
          }
          @if (formData.documentDetails?.notes) {
            <app-summary-section-field
              [value]="formData.documentDetails?.notes"
              label="DOCUMENTS_UPLOAD.NOTES"
            />
          }
        </app-summary-section>
      }
      @switch (formData.selectedFlow) {
        @case (FLOW.REGISTRATION) {
          @if (formData.contact) {
            <app-registration-contact-data [contact]="formData.contact" />
          }
        }
        @case (FLOW.COMMISSIONING) {
          @if (formData.commissioningElectricityContact) {
            <app-commissioning-contact-data
              [contact]="formData.commissioningElectricityContact"
            />
          }
        }
      }
      <!-- print summary -->
      <app-summary-section
        heading="SUMMARY.PRINT_SUMMARY"
        icon="print"
        class="no-print print-section"
        onclick="window.print();"
      />
      <div class="space"></div>
      <div class="required-checkboxes">
        <mat-checkbox
          (ngModelChange)="acceptPrivacyPolicy.set($event)"
          [ngModel]="acceptPrivacyPolicy()"
          color="primary"
          data-testid="accept-privacy-policy"
          appRequiredSuffix
        >
          {{ "SUMMARY.ACCEPT_PRIVACY_POLICY.0" | translate }}
          <a
            [href]="
              (whiteLabelConfig$ | async)?.whitelabelSettings?.legalSettings
                ?.dataProtectionURL
            "
            class="bold"
            target="_blank"
            >{{ "SUMMARY.ACCEPT_PRIVACY_POLICY.1" | translate }}</a
          >
          {{ "SUMMARY.ACCEPT_PRIVACY_POLICY.2" | translate }}
        </mat-checkbox>
        <mat-checkbox
          (ngModelChange)="acceptCommunication.set($event)"
          [ngModel]="acceptCommunication()"
          color="primary"
          data-testid="accept-communication"
          appRequiredSuffix
          >{{ "SUMMARY.ACCEPT_COMMUNICATION" | translate }}
        </mat-checkbox>
      </div>
      <div class="space"></div>
      <!-- grid connection power change -->
      <ng-template #gridConnectionPowerChange>
        <app-summary-section
          [stepRoute]="routes.POWER_CHANGE_DETAILS"
          heading="POWER_CHANGE_DETAILS.HEADING"
        >
          <div class="section-fields-container">
            <app-summary-section-field
              [value]="
                (formData.powerChangeDetails?.additionalConsumers
                  ? 'COMMON.YES'
                  : 'COMMON.NO'
                ) | translate
              "
              label="POWER_CHANGE_DETAILS.ADDITIONAL_CONSUMERS"
            />
            <app-summary-section-field
              [value]="formData.powerChangeDetails?.notes"
              label="POWER_CHANGE_DETAILS.NOTES_TITLE"
            />
          </div>
          <p class="section-heading">
            {{ "POWER_CHANGE_DETAILS.POWER_DEMAND" | translate }}
          </p>
          <div class="section-fields-container">
            <app-summary-section-field
              [value]="formData.powerChangeDetails?.currentPowerDemand"
              label="POWER_CHANGE_DETAILS.CURRENT_POWER_DEMAND"
            />
            <app-summary-section-field
              [value]="formData.powerChangeDetails?.plannedPowerDemand"
              label="POWER_CHANGE_DETAILS.PLANNED_POWER_DEMAND"
            />
          </div>
        </app-summary-section>
      </ng-template>
    }
    <div class="submit-captcha-container">
      <app-ngx-turnstile
        (resolved)="onTokenResolve($event)"
        [resetWidget$]="turnstileCaptchaReset"
        [action]="'summary-captcha'"
      />
    </div>
  </div>
  <app-form-navigation
    (previous)="back()"
    (next)="submitData()"
    [previousConfig]="{ label: 'COMMON.BACK' }"
    [nextConfig]="{
      label: 'COMMON.SUBMIT',
      disabled: nextDisabled(),
    }"
    slot="navigation"
    class="no-print"
  />
</app-form-card>
