<app-form-card
  svgIcon="gas"
  cardTitle="WATER.CONSTRUCTION_WATER_REQUIREMENTS.HEADING"
  description="WATER.CONSTRUCTION_WATER_REQUIREMENTS.DESCRIPTION"
>
  <div class="space"></div>
  <form [formGroup]="requirementsForm">
    <h2 class="full-width">
      {{
        "WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_CONSUMPTION"
          | translate
      }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "WATER.CONSTRUCTION_WATER_REQUIREMENTS.FLOW_RATE" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="flowRate"
          formControlName="flowRate"
          min="0"
        />
        <mat-error appErrorMessages="flowRate" />
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="full-width" appRequiredSuffix>
      {{
        "WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_CONVERTED_SPACE"
          | translate
      }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <input
          matInput
          type="number"
          name="convertedSpace"
          formControlName="convertedSpace"
          min="0"
        />
        <mat-error appErrorMessages="convertedSpace" />
      </mat-form-field>
    </div>

    <div class="space"></div>

    <div class="date-row-container">
      <div class="full-width-flex-element">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              "WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_INSTALLATION_DATE"
                | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_INSTALLATION_DATE_INFO"
          />
        </h2>

        <mat-form-field
          class="full-width"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <input
            [min]="dateAfterTwoWeeks"
            [matDatepicker]="desiredInstallationDate"
            matInput
            formControlName="desiredInstallationDate"
          />
          <mat-datepicker-toggle
            [for]="desiredInstallationDate"
            matIconSuffix
          />
          <mat-datepicker #desiredInstallationDate />
          @if (
            requirementsForm | hasError: "desiredInstallationDate" : "required"
          ) {
            <mat-error>{{ errorMessageForInvalidDateFormat }} </mat-error>
          }
          @if (
            requirementsForm
              | hasError: "desiredInstallationDate" : "matDatepickerMin"
          ) {
            <mat-error
              >{{
                "WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_INSTALLATION_DATE_INFO"
                  | translate
              }}
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="full-width-flex-element">
        <h2 class="desired-deconstruction-date">
          {{
            "WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_DECONSTRUCTION_DATE"
              | translate
          }}
        </h2>

        <mat-form-field class="full-width" appearance="outline">
          <input
            [min]="
              requirementsForm
                | formLookup: 'desiredInstallationDate'
                | nextDayDate
            "
            [matDatepicker]="desiredDeconstructionDate"
            matInput
            formControlName="desiredDeconstructionDate"
          />
          <mat-datepicker-toggle
            [for]="desiredDeconstructionDate"
            matIconSuffix
          />
          <mat-datepicker #desiredDeconstructionDate />
          @if (
            requirementsForm
              | hasError: "desiredDeconstructionDate" : "matDatepickerParse"
          ) {
            <mat-error>{{ errorMessageForInvalidDateFormat }} </mat-error>
          }

          @if (
            requirementsForm
              | hasError: "desiredDeconstructionDate" : "matDatepickerMin"
          ) {
            <mat-error
              >{{
                "WATER.CONSTRUCTION_WATER_REQUIREMENTS.DESIRED_DECONSTRUCTION_DATE_MIN_DATE"
                  | translate
              }}
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>

    <div class="space"></div>

    <h2 class="full-width">
      {{ "WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_NOTES" | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <textarea
          #notes
          [placeholder]="
            'WATER.CONSTRUCTION_WATER_REQUIREMENTS.NOTES_PLACEHOLDER'
              | translate
          "
          [maxlength]="maxLength"
          matInput
          cols="50"
          rows="2"
          formControlName="notes"
        ></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes" />
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: requirementsForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
