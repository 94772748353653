<app-form-card
  titleIcon="waving_hand"
  cardTitle="MODULE_SELECTION.HEADING"
  dataTestId="module-selection-heading"
>
  <div class="space"></div>
  <h2>{{ "MODULE_SELECTION.DESCRIPTION" | translate }}</h2>
  <form [formGroup]="moduleSelectionForm">
    <app-selection-group>
      @for (moduleDetails of moduleDetailsList; track moduleDetails) {
        @if (moduleDetails.enabled) {
          <app-selection-card
            (click)="selectModule(moduleDetails.name)"
            [svgIcon]="moduleDetails.svgIcon"
            [icon]="moduleDetails.icon"
            [label]="moduleDetails.label"
            [symbolOutlinedIcon]="moduleDetails.symbolOutlinedIcon"
            [selected]="selectedModule === moduleDetails.name"
            [attr.data-testid]="moduleDetails.name"
            class="selection-card"
          />
        }
      }
      @if (allTouched && moduleSelectionForm.get("selectedModule")?.invalid) {
        <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
      }
    </app-selection-group>
  </form>

  @if (selectedModule) {
    <div class="space"></div>
    @switch (selectedModule) {
      @case (module.GAS) {
        <app-hint>
          <span>{{ "MODULE_SELECTION.GAS_INFO" | translate }}</span>
        </app-hint>
      }
      @case (module.HEAT) {
        <app-hint>
          <span>{{ "MODULE_SELECTION.HEAT_INFO" | translate }}</span>
        </app-hint>
      }
      @case (module.WATER) {
        <app-hint>
          <span>{{ "MODULE_SELECTION.WATER_INFO" | translate }}</span>
        </app-hint>
      }
      @case (module.ELECTRICITY) {
        <app-hint>
          <span
            >{{ "MODULE_SELECTION.ELECTRICITY_INFO" | translate }}<br
          /></span>
          @if (hasCommissioningModule(selectedModule)) {
            <span>{{
              "MODULE_SELECTION.ELECTRICITY_COMMISSIONING_INFO" | translate
            }}</span>
          }
        </app-hint>
        <app-hint [warning]="true" [showOutline]="false">
          <span>{{ "MODULE_SELECTION.ELECTRICITY_WARNING" | translate }}</span>
        </app-hint>
      }
    }
  }

  <app-form-navigation
    (next)="next()"
    [previousConfig]="{ visible: false }"
    [nextConfig]="{ label: 'MODULE_SELECTION.NEXT_BUTTON_TEXT', arrow: false }"
    slot="navigation"
  />
</app-form-card>
