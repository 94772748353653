import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import { SEWAGE_OPTION } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { DateHelperService } from "@app/services/date-helper.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

interface SewageOption {
  icon: string;
  label: string;
  type: SEWAGE_OPTION;
}

@Component({
  templateUrl: "./sewage-options.component.html",
  styleUrls: ["./sewage-options.component.scss"],
})
export class SewageOptionsComponent implements OnInit, OnDestroy {
  public readonly sewageOptionsList: SewageOption[] = [
    {
      icon: "add_circle",
      label: "WATER.SEWAGE_OPTIONS.NEW.LABEL",
      type: SEWAGE_OPTION.NEW,
    },
    {
      icon: "cancel",
      label: "WATER.SEWAGE_OPTIONS.DETACH.LABEL",
      type: SEWAGE_OPTION.DETACH,
    },
  ];

  public sewageOptions = SEWAGE_OPTION;
  public selectedOption?: SEWAGE_OPTION;
  public sewageOptionsForm!: FormGroup;
  public sewageOptionControl!: FormControl;
  public allTouched = false;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public dateForTomorrow = this.dateHelperService.getFutureDate(1);
  public errorMessageForInvalidDateFormat =
    this.dateHelperService.getErrorMessageForInvalidDateFormat();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private routeService: RouteService,
    private dateHelperService: DateHelperService,
  ) {}

  public ngOnInit(): void {
    this.createSewageOptionsForm();
    this.updateForm();
  }

  private createSewageOptionsForm(): void {
    this.sewageOptionsForm = this.formBuilder.group({
      sewageOption: [null, Validators.required],
    });
    this.sewageOptionControl = this.sewageOptionsForm.get(
      "sewageOption",
    ) as FormControl;
    this.watchSewageOption();
  }

  private watchSewageOption(): void {
    this.sewageOptionControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((sewageOption) => {
        this.removeControls();
        if (sewageOption === SEWAGE_OPTION.DETACH) {
          this.sewageOptionsForm.addControl(
            "permanentDeconstruction",
            new FormControl(null, Validators.required),
          );
          this.sewageOptionsForm.addControl(
            "meterNumber",
            new FormControl(null, {
              updateOn: "blur",
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            }),
          );
          this.watchPermanentDeconstruction();
        }
        this.sewageOptionsForm.updateValueAndValidity();
      });
  }

  private removeControls(): void {
    this.sewageOptionsForm.removeControl("permanentDeconstruction");
    this.sewageOptionsForm.removeControl("meterNumber");
    this.sewageOptionsForm.removeControl("deconstructionUntil");
  }

  private watchPermanentDeconstruction(): void {
    this.sewageOptionsForm
      .get("permanentDeconstruction")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((permanentDeconstruction) => {
        if (permanentDeconstruction) {
          this.sewageOptionsForm.removeControl("deconstructionUntil");
        } else {
          this.sewageOptionsForm.addControl(
            "deconstructionUntil",
            new FormControl(null, Validators.required),
          );
        }
      });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.sewageOptions),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((sewageOptions) => {
        this.sewageOptionsForm.patchValue(sewageOptions);
        this.selectedOption = sewageOptions.sewageOption;
      });
  }

  public selectType(selectedOption: SEWAGE_OPTION): void {
    if (this.sewageOptionsForm.get("sewageOption")?.value !== selectedOption) {
      this.sewageOptionsForm.get("sewageOption")?.setValue(selectedOption);
      this.selectedOption = selectedOption;
      this.sewageOptionsForm.markAsUntouched();
      this.allTouched = false;
    }
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.sewageOptionsForm.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.sewageOptionsForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  private updateState(): void {
    this.appStateService.updateFormData({
      sewageOptions: this.sewageOptionsForm.value,
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
