<app-form-card
  titleIcon="electric_bolt"
  cardTitle="CONSTRUCTION_ELECTRICITY_INFORMATION.HEADING"
  data-testId="construction-electricity-heading"
>
  <div class="space"></div>

  <form [formGroup]="constructionElectricityForm">
    <h2 appRequiredSuffix>
      {{ "CONSTRUCTION_ELECTRICITY_INFORMATION.SYSTEM_POWER" | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "CONSTRUCTION_ELECTRICITY_INFORMATION.SYSTEM_POWER_LABEL" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="systemPower"
          formControlName="systemPower"
          min="0"
          data-testid="system-power"
        />
        <mat-error appErrorMessages="systemPower" />
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      <span appRequiredSuffix>
        {{
          "CONSTRUCTION_ELECTRICITY_INFORMATION.DEVICES_REQUIRE_APPROVAL_QUESTION"
            | translate
        }}</span
      >
      <app-info-tooltip
        tooltipText="CONSTRUCTION_ELECTRICITY_INFORMATION.DEVICES_REQUIRE_APPROVAL_QUESTION_TOOLTIP"
      />
    </h2>

    <mat-radio-group
      color="primary"
      formControlName="devicesRequireApproval"
      class="input-container"
    >
      <div>
        <mat-radio-button [value]="true" data-testid="devices-require-approval">
          {{ "COMMON.YES" | translate }}
        </mat-radio-button>
        <mat-radio-button
          [value]="false"
          data-testid="no-devices-require-approval"
        >
          {{ "COMMON.NO" | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        [hideError]="!allTouched"
        appErrorMessages="devicesRequireApproval"
      />
    </mat-radio-group>

    @if (constructionElectricityForm | formLookup: "devicesRequireApproval") {
      <h2 appRequiredSuffix>
        {{
          "CONSTRUCTION_ELECTRICITY_INFORMATION.REQUIRED_DEVICES" | translate
        }}
      </h2>
      <div class="input-container">
        @for (
          requiredDevice of requiredDevices.controls;
          track requiredDevice;
          let i = $index
        ) {
          <ng-container formArrayName="requiredDevices">
            <div
              class="required-devices-container input-field-with-postfix-container"
            >
              <mat-form-field
                appearance="outline"
                class="input-field-with-postfix"
              >
                <mat-label>{{
                  "CONSTRUCTION_ELECTRICITY_INFORMATION.REQUIRED_DEVICES_LABEL"
                    | translate
                }}</mat-label>
                <input
                  [name]="'requiredDevice_' + i"
                  [formControlName]="i"
                  [attr.data-testid]="'required-device-' + i"
                  matInput
                  type="text"
                />
                <mat-error [appErrorMessages]="'' + i" />
              </mat-form-field>
              <button
                (click)="removeDevice(i)"
                [disabled]="i === 0"
                type="button"
                mat-icon-button
                class="input-field-postfix"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
        }
      </div>
      <button
        (click)="addMoreDevice()"
        [disabled]="requiredDevices.length | exceedsLimit"
        type="button"
        mat-stroked-button
      >
        <mat-icon>add</mat-icon>
        {{
          "CONSTRUCTION_ELECTRICITY_INFORMATION.ADD_MORE_DEVICE_BUTTON"
            | translate
        }}
      </button>
      <div class="space"></div>
    }

    <div class="date-row-container">
      <div class="full-width-flex-element">
        <h2 appRequiredSuffix>
          {{
            "CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_INSTALLATION_DATE"
              | translate
          }}
        </h2>

        <mat-form-field
          class="full-width"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <mat-label>{{
            "CONSTRUCTION_ELECTRICITY_INFORMATION.DATE_LABEL" | translate
          }}</mat-label>
          <input
            [min]="dateForTomorrow"
            [matDatepicker]="desiredInstallationDate"
            matInput
            formControlName="desiredInstallationDate"
            data-testid="desired-installation-date"
          />
          <mat-datepicker-toggle
            [for]="desiredInstallationDate"
            matIconSuffix
          />
          <mat-datepicker #desiredInstallationDate />
          @if (
            constructionElectricityForm
              | hasError: "desiredInstallationDate" : "required"
          ) {
            <mat-error>{{ errorMessageForInvalidDateFormat }} </mat-error>
          }
          @if (
            constructionElectricityForm
              | hasError: "desiredInstallationDate" : "matDatepickerMin"
          ) {
            <mat-error
              >{{ "VALIDATION.DATE_PICKER_MIN" | translate }}
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="full-width-flex-element">
        <h2>
          {{
            "CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_DECONSTRUCTION_DATE"
              | translate
          }}
        </h2>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            "CONSTRUCTION_ELECTRICITY_INFORMATION.DATE_LABEL" | translate
          }}</mat-label>
          <input
            [min]="
              constructionElectricityForm
                | formLookup: 'desiredInstallationDate'
            "
            [matDatepicker]="desiredDeconstructionDate"
            matInput
            formControlName="desiredDeconstructionDate"
          />
          <mat-datepicker-toggle
            [for]="desiredDeconstructionDate"
            matIconSuffix
          />
          <mat-datepicker #desiredDeconstructionDate />
          @if (
            constructionElectricityForm
              | hasError: "desiredDeconstructionDate" : "matDatepickerParse"
          ) {
            <mat-error>{{ errorMessageForInvalidDateFormat }} </mat-error>
          }

          @if (
            constructionElectricityForm
              | hasError: "desiredDeconstructionDate" : "matDatepickerMin"
          ) {
            <mat-error
              >{{
                "CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_DECONSTRUCTION_DATE_MIN_DATE"
                  | translate
              }}
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled:
        allTouched | nextButtonDisabled: constructionElectricityForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
