@if (buildingDetails) {
  <app-summary-section
    [stepRoute]="buildingDetailsRoute"
    heading="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.HEADING"
  >
    <app-summary-building-type [buildingType]="buildingDetails.buildingType" />
    @if (buildingDetails.area) {
      <app-summary-section-field
        [value]="buildingDetails.area"
        label="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.AREA"
      />
    }
    @if (buildingDetails.flatCount) {
      <app-summary-section-field
        [value]="buildingDetails.flatCount"
        label="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.FLAT_COUNT"
      />
    }
  </app-summary-section>
}

@if (electricityInformation) {
  <app-summary-section
    [stepRoute]="electricityInformationRoute"
    heading="COMMISSIONING_ELECTRICITY.INFORMATION.HEADING"
  >
    <p class="section-heading">
      {{ "COMMISSIONING_ELECTRICITY.INFORMATION.SUBHEADING" | translate }}
    </p>
    <app-summary-section-field
      [value]="electricityInformation.systemPower"
      label="COMMISSIONING_ELECTRICITY.INFORMATION.SYSTEM_POWER"
    />
    <app-summary-section-field
      [value]="electricityInformation.connectionFuse"
      label="COMMISSIONING_ELECTRICITY.INFORMATION.CONNECTION_FUSE"
    />
    <app-summary-section-field
      [value]="
        (electricityInformation.constructionElectricity
          ? 'COMMON.YES'
          : 'COMMON.NO'
        ) | translate
      "
      label="COMMISSIONING_ELECTRICITY.INFORMATION.CONSTRUCTION_ELECTRICITY"
    />
  </app-summary-section>
}
