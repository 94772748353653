import { NgOptimizedImage, registerLocaleData } from "@angular/common";
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from "@angular/common/http";
import localeDE from "@angular/common/locales/de";
import localeEN from "@angular/common/locales/en-GB";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular";
import { GoogleTagManagerConfiguration } from "angular-google-tag-manager";

import { httpErrorInterceptor } from "@app/interceptors/http-error.interceptor";
import { SharedModule } from "@app/modules/shared/shared.module";
import { CustomDateAdapter } from "@app/services/custom-date-adapter.service";
import { EnvironmentService } from "@app/services/environment.service";
import { IconInitializerService } from "@app/services/icon-initializer.service";

import { initializeApp } from "./app-initialization/app-initializer-factory";
import { WebpackTranslateLoader } from "./app-initialization/webpack-translation-loader";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./components/app/app.component";
import { CommissioningElectricityModule } from "./modules/commissioning-electricity/commissioning-electricity.module";
import { CommonStepsModule } from "./modules/common-steps/common-steps.module";
import { ElectricityModule } from "./modules/electricity/electricity.module";
import { GasModule } from "./modules/gas/gas.module";
import { HeatModule } from "./modules/heat/heat.module";
import { WaterModule } from "./modules/water/water.module";
import { WhiteLabelService } from "./services/white-label.service";

registerLocaleData(localeDE);
registerLocaleData(localeEN);

function initializeTagManager(
  environmentService: EnvironmentService,
  googleTagManagerConfiguration: GoogleTagManagerConfiguration,
): () => void {
  return () => {
    googleTagManagerConfiguration.set({
      id: environmentService.gtmId,
      gtm_preview: environmentService.gtmPreview,
      gtm_auth: environmentService.gtmAuth,
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CommonStepsModule,
    ElectricityModule,
    CommissioningElectricityModule,
    GasModule,
    WaterModule,
    HeatModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      defaultLanguage: "de",
    }),
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        IconInitializerService,
        WhiteLabelService,
        HttpClient,
        Sentry.TraceService,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTagManager,
      deps: [EnvironmentService, GoogleTagManagerConfiguration],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    provideHttpClient(withInterceptors([httpErrorInterceptor])),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
