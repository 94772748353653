<app-form-card
  titleIcon="category"
  cardTitle="WATER.WATER_AREA.HEADING"
  description="WATER.WATER_AREA.DESCRIPTION"
>
  <form>
    <app-selection-group>
      @for (waterArea of waterAreaDetailsList; track waterArea) {
        @if (waterArea.type | isModuleOptionEnabled: waterModule | async) {
          <app-selection-card
            (click)="selectArea(waterArea.type)"
            [svgIcon]="waterArea.svgIcon"
            [label]="waterArea.label"
            [selected]="selectedWaterArea === waterArea.type"
            class="selection-card"
          />
        }
      }
      @if (allTouched && waterAreaControl.invalid) {
        <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
      }
    </app-selection-group>

    @if (selectedWaterArea === formType.CONSTRUCTION_WATER) {
      <div class="space"></div>
      <app-hint>
        <span>{{
          "WATER.WATER_AREA.CONSTRUCTION_WATER_HINT" | translate
        }}</span>
      </app-hint>
    }
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: waterAreaControl.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
