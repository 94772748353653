<app-summary-section
  [stepRoute]="measurementDetailsRoute"
  heading="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.HEADING"
>
  @for (detail of measurementDetails; track detail; let i = $index) {
    <p class="section-heading">
      {{ i + 1 }}.
      {{ "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.NAME" | translate }}
    </p>
    <app-complex-measurement-detail-summary
      [measurementDetail]="detail"
      class="section-fields-container"
    />
  }
</app-summary-section>
