<div class="section-fields-container">
  <app-summary-section-field
    [value]="constructionWaterRequirements.flowRate"
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.FLOW_RATE"
  />

  <app-summary-section-field
    [value]="constructionWaterRequirements.convertedSpace"
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_CONVERTED_SPACE"
  />

  <app-summary-section-field
    [value]="
      (constructionWaterRequirements.desiredInstallationDate
        | date: 'shortDate' : '' : currentLanguageCulture)!
    "
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_INSTALLATION_DATE"
  />

  <app-summary-section-field
    [value]="
      (constructionWaterRequirements.desiredDeconstructionDate
        | date: 'shortDate' : '' : currentLanguageCulture) || undefined
    "
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_DECONSTRUCTION_DATE"
  />

  <app-summary-section-field
    [value]="constructionWaterRequirements.notes"
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_NOTES"
  />
</div>
