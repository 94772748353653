import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AbstractControl, ControlContainer, FormArray } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  of,
  startWith,
  takeUntil,
  zip,
} from "rxjs";

@Directive({
  selector: "[appErrorMessages]",
})
export class ErrorMessagesDirective implements OnInit, OnChanges, OnDestroy {
  @Input() public appErrorMessages!: string;
  @Input() public hideError = false;

  private control!: AbstractControl | null | undefined;
  private onDestroy$ = new Subject<void>();

  constructor(
    private controlContainer: ControlContainer,
    private elementRef: ElementRef,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    const controlIndex = Number(this.appErrorMessages);
    const isIndex = !isNaN(controlIndex);
    if (!isIndex) {
      this.control =
        this.controlContainer.control?.get(this.appErrorMessages) ??
        (this.controlContainer.control as FormArray);
    } else {
      const formArrayControls = (this.controlContainer.control as FormArray)
        .controls;
      if (formArrayControls.length > controlIndex) {
        this.control = formArrayControls[controlIndex];
      }
    }

    this.watchChanges();
  }

  public ngOnChanges(): void {
    this.watchChanges();
  }

  private watchChanges(): void {
    const changes$ = [
      this.controlContainer.valueChanges,
      this.controlContainer.statusChanges,
      this.control?.valueChanges,
      this.control?.statusChanges,
      this.control?.value?.valueChanges,
      this.control?.value?.statusChanges,
    ].map(this.nonNull);

    merge(...changes$)
      .pipe(
        startWith({}),
        filter(() => !this.hideError),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => this.updateErrors());
  }

  private nonNull(
    observable: Observable<unknown> | null | undefined,
  ): Observable<unknown> {
    return observable ?? of({});
  }

  public updateErrors(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: { type: string; error: any }[] = [];

    const formErrors = [this.control?.errors, this.control?.value?.errors];
    formErrors.forEach((controlErrors) => {
      if (controlErrors) {
        for (const error of Object.keys(controlErrors)) {
          errors.push({
            type: error.toUpperCase(),
            error: controlErrors?.[error],
          });
        }
      }
    });

    if (errors.length === 0) {
      this.elementRef.nativeElement.innerHTML = "";
    }
    zip(
      ...errors.map((error) =>
        this.translateService
          .get(`VALIDATION.${error.type}`, error.error)
          .pipe(map((translation) => `<span>${translation}</span>`)),
      ),
    )
      .pipe(
        map((errorElements) => errorElements.join("")),
        takeUntil(this.onDestroy$),
      )
      .subscribe(
        (errorHtml) => (this.elementRef.nativeElement.innerHTML = errorHtml),
      );
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
