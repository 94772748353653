import { DatePipe } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

import { getLanguageLocaleCulture } from "@app/constants/language";
import {
  ControllableDetailsDto,
  CONTROLLER,
  NETWORK_AND_SYSTEM_PROTECTION,
  NetworkAndSystemProtectionDetailsDto,
} from "@app/models/registration-form";
import { ROUTES } from "@app/models/routes.enum";
import { SharedModule } from "@app/modules/shared/shared.module";

@Component({
  selector: "app-storage-controllable-system-summary",
  standalone: true,
  imports: [DatePipe, SharedModule, TranslatePipe],
  templateUrl: "./storage-controllable-system-summary.component.html",
})
export class StorageControllableSystemSummaryComponent {
  @Input({ required: true })
  public controllableDetails!: ControllableDetailsDto;
  @Input({ required: false })
  public networkAndSystemProtectionDetails?: NetworkAndSystemProtectionDetailsDto;

  readonly #translateService = inject(TranslateService);

  public route = ROUTES.STORAGE_CONTROLLABLE_SYSTEM;
  public CONTROLLER = CONTROLLER;
  public readonly NETWORK_AND_SYSTEM_PROTECTION = NETWORK_AND_SYSTEM_PROTECTION;

  public currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang,
  );
}
