@if ({ config: whiteLabelConfiguration$ | async }; as data) {
  <div class="page-container">
    @if (data.config; as customerConfiguration) {
      @if (
        customerConfiguration.whitelabelSettings.assetSettings.showLogo &&
        customerConfiguration.whitelabelSettings.assetSettings.logoUrl
      ) {
        <img
          [ngStyle]="
            customerConfiguration.whitelabelSettings.assetSettings.maxLogoSize
              ? {
                  'max-width':
                    customerConfiguration.whitelabelSettings.assetSettings
                      .maxLogoSize,
                  'max-height':
                    customerConfiguration.whitelabelSettings.assetSettings
                      .maxLogoSize,
                }
              : null
          "
          [alt]="customerConfiguration.customerId + ' Logo'"
          [src]="customerConfiguration.whitelabelSettings.assetSettings.logoUrl"
          class="logo"
        />
      }
    }
    <div class="router-outlet-container">
      <router-outlet />
    </div>
    @if (data.config?.whitelabelSettings?.legalSettings; as legalSettings) {
      <div class="info-links">
        @if (legalSettings.termsConditionsURL) {
          <a
            [href]="legalSettings.termsConditionsURL"
            target="_blank"
            rel="noopener"
            >{{ "COMMON.GTC" | translate }}</a
          >
        }
        @if (legalSettings.dataProtectionURL) {
          <a
            [href]="legalSettings.dataProtectionURL"
            target="_blank"
            rel="noopener"
            >{{ "COMMON.DATA_PROTECTION" | translate }}</a
          >
        }
        @if (legalSettings.imprintURL) {
          <a [href]="legalSettings.imprintURL" target="_blank" rel="noopener">{{
            "COMMON.IMPRINT" | translate
          }}</a>
        }
      </div>
    }
  </div>
}
