@if (measurementDetail) {
  <app-summary-section-field
    [value]="
      'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.TYPES.' +
        measurementDetail.changeType | translate
    "
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.SUBHEADING"
  />
  @if (
    measurementDetail.meterNumber &&
    measurementDetail.changeType !== MEASUREMENT_DEVICE_CHANGE_TYPE.INSTALLATION
  ) {
    <app-summary-section-field
      [value]="measurementDetail.meterNumber"
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.METER_NUMBER"
    />
  }
  <app-summary-section-field
    [value]="
      (
        measurementDetail.measurementTypes
        | mapWithPrefix
          : 'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASUREMENT_TYPE.TYPES.'
        | mapTranslate
      ).join(', ')
    "
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASUREMENT_TYPE.SUBHEADING"
  />
  <app-summary-section-field
    [value]="
      'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES.' +
        measurementDetail.meterLocation.locationType | translate
    "
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.SUBHEADING"
  />
  @if (measurementDetail.meterLocation.locationType === METER_LOCATION.OTHER) {
    <app-summary-section-field
      [value]="measurementDetail.meterLocation.otherLocation"
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES.OTHER"
    />
  }
  <app-summary-section-field
    [value]="
      'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.TYPES.' +
        measurementDetail.fasteningType | translate
    "
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.SUBHEADING"
  />
  <app-summary-section-field
    [value]="measurementDetail.converterSize"
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CONVERTER_SIZE"
  />
  <app-summary-section-field
    [value]="
      measurementDetail.measuringPointOperator.basicResponsible
        | booleanTranslateKey
        | translate
    "
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.BASIC_RESPONSIBLE"
  />
  @if (!measurementDetail.measuringPointOperator.basicResponsible) {
    <app-summary-section-field
      [value]="measurementDetail.measuringPointOperator.otherOperator"
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.OTHER_OPERATOR"
    />
  }
}
