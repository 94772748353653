import { Component } from "@angular/core";
import { Observable } from "rxjs";

import { AppState } from "@app/models/form-data.interface";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";

@Component({
  selector: "app-responsibility-check-fail",
  templateUrl: "./responsibility-check-fail.component.html",
})
export class ResponsibilityCheckFailComponent {
  public appState$: Observable<AppState>;
  constructor(
    private routeService: RouteService,
    private appStateService: AppStateService,
  ) {
    this.appState$ = appStateService.observeState();
  }

  public next(): void {
    this.routeService.navigateToNextStep();
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }
}
