import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  selector: "app-gas-power-requirement",
  templateUrl: "./gas-power-requirement.component.html",
})
export class GasPowerRequirementComponent implements OnInit, OnDestroy {
  public powerRequirementForm!: FormGroup;
  public allTouched = false;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createPowerRequirementForm();
    this.updateForm();
    this.watchForm();
  }

  private createPowerRequirementForm(): void {
    this.powerRequirementForm = this.formBuilder.group({
      powerDemand: [null, [Validators.required, Validators.min(0)]],
      notes: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ],
        },
      ],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.gasPowerRequirement),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((gasPowerRequirement) => {
        this.powerRequirementForm.patchValue(gasPowerRequirement);
      });
  }

  private watchForm(): void {
    this.powerRequirementForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((gasPowerRequirement) => {
        this.appStateService.updateFormData({ gasPowerRequirement });
      });
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.powerRequirementForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.powerRequirementForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
