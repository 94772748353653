import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import { SEWAGE_TYPE } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";

@Component({
  templateUrl: "./sewage-type.component.html",
})
export class SewageTypeComponent implements OnInit, OnDestroy {
  public sewageTypeControl!: FormControl;
  public allTouched = false;
  public sewageTypeList = Object.keys(SEWAGE_TYPE);

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private routeService: RouteService,
    private appStateService: AppStateService,
  ) {}

  public ngOnInit(): void {
    this.createControl();
    this.updateControl();
    this.watchControl();
  }

  private createControl(): void {
    this.sewageTypeControl = new FormControl("", Validators.required);
  }

  private updateControl(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.sewageType),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((sewageType) => this.sewageTypeControl.patchValue(sewageType));
  }

  private watchControl(): void {
    this.sewageTypeControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((sewageType) =>
        this.appStateService.updateFormData({
          sewageType,
        }),
      );
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.sewageTypeControl.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.sewageTypeControl.markAllAsTouched();
    }
  }

  private updateState(): void {
    this.appStateService.updateFormData({
      sewageType: this.sewageTypeControl.value,
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
