<app-summary-section
  [stepRoute]="route"
  heading="CHARGING_DEVICE_INFORMATION.HEADING"
>
  <p class="section-heading">
    {{ "CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.TITLE" | translate }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="chargingDeviceInformation.systemManufacturer"
      label="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.MANUFACTURER"
    />

    <app-summary-section-field
      [value]="chargingDeviceInformation.systemType"
      label="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.TYPE"
    />

    <app-summary-section-field
      [value]="chargingDeviceInformation.systemCount"
      label="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.NUMBER_OF_IDENTICAL_CHARGING_DEVICES"
    />
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        'CHARGING_DEVICE_INFORMATION.TYPE_OF_CHARGING_DEVICE.' +
          chargingDeviceInformation.type | translate
      "
      label="CHARGING_DEVICE_INFORMATION.TYPE_OF_CHARGING_DEVICE.TITLE"
    />

    <app-summary-section-field
      [value]="
        'CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.' +
          (chargingDeviceInformation.public
            ? 'OPEN_TO_PUBLIC'
            : 'NOT_OPEN_TO_PUBLIC') | translate
      "
      label="CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.TITLE"
    />

    <app-summary-section-field
      [value]="
        (chargingDeviceInformation.specialRights ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      "
      label="CHARGING_DEVICE_INFORMATION.SPECIAL_RIGHTS.TITLE"
    />
  </div>

  <p class="section-heading">
    {{
      "CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.TITLE" | translate
    }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="chargingDeviceInformation.chargingPointACCount"
      label="CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_AC"
    />

    <app-summary-section-field
      [value]="chargingDeviceInformation.chargingPointDCCount"
      label="CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_DC"
    />
  </div>

  <p class="section-heading">
    {{ "CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.TITLE" | translate }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="chargingDeviceInformation.maxGridPower"
      label="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_POWER"
    />

    <app-summary-section-field
      [value]="chargingDeviceInformation.maxGridFeedInPower"
      label="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_FEED_IN_POWER"
    />

    <app-summary-section-field
      [value]="chargingDeviceInformation.systemPower"
      label="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.POWER"
    />
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        (chargingDeviceInformation.separateMeter ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      "
      label="CHARGING_DEVICE_INFORMATION.MEASUREMENT_VIA_SEPARATE_METER.TITLE"
    />

    <app-summary-section-field
      [value]="
        (chargingDeviceInformation.meterAvailable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      "
      label="CHARGING_DEVICE_INFORMATION.METER_ALREADY_EXISTS.TITLE"
    />

    @if (chargingDeviceInformation.meterAvailable) {
      <app-summary-section-field
        [value]="chargingDeviceInformation.meterNumber"
        label="CHARGING_DEVICE_INFORMATION.METER_ALREADY_EXISTS.METER_NUMBER"
      />
    }

    <app-summary-section-field
      [value]="
        'CHARGING_DEVICE_INFORMATION.LOAD_MANAGEMENT.' +
          chargingDeviceInformation.loadManagement | translate
      "
      label="CHARGING_DEVICE_INFORMATION.LOAD_MANAGEMENT.TITLE"
    />
  </div>
</app-summary-section>
