import { Component, Input } from "@angular/core";

import { ContactDetails } from "@app/models/registration-form";

@Component({
  selector: "app-contact-details",
  templateUrl: "./contact-details.component.html",
  styleUrls: ["./contact-details.component.scss"],
})
export class ContactDetailsComponent {
  @Input({ required: true }) public contactDetails!: ContactDetails;
}
