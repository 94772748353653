import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ROUTES } from "@app/models/routes.enum";
import { CommissioningElectricityMeasurementDetailsComponent } from "@app/modules/commissioning-electricity/commissioning-electricity-measurement-details/commissioning-electricity-measurement-details.component";
import { CommissioningElectricitySimpleMeasurementDetailsComponent } from "@app/modules/commissioning-electricity/customer-facility/commissioning-electricity-simple-measurement-details/commissioning-electricity-simple-measurement-details.component";
import { CommissioningElectricitySystemsComponent } from "@app/modules/commissioning-electricity/customer-facility/commissioning-electricity-systems/commissioning-electricity-systems.component";
import { CommissioningElectricityInformationComponent } from "@app/modules/commissioning-electricity/main-power-supply/commissioning-electricity-information/commissioning-electricity-information.component";
import { CommissioningElectricityPropertyTypeComponent } from "@app/modules/commissioning-electricity/main-power-supply/commissioning-electricity-property-type/commissioning-electricity-property-type.component";
import { MainPowerSupplyTypeComponent } from "@app/modules/commissioning-electricity/main-power-supply/main-power-supply-type/main-power-supply-type.component";
import { PowerIncreaseDetailsComponent } from "@app/modules/commissioning-electricity/main-power-supply/power-increase-details/power-increase-details.component";
import { BalconyPvExitComponent } from "@app/modules/electricity/balcony-pv/balcony-pv-exit/balcony-pv-exit.component";
import { BalconyPvInfoComponent } from "@app/modules/electricity/balcony-pv/balcony-pv-info/balcony-pv-info.component";
import { BalconyPvSummaryComponent } from "@app/modules/electricity/balcony-pv/balcony-pv-summary/balcony-pv-summary.component";
import { GridConnectionChangeComponent } from "@app/modules/electricity/grid-connection/grid-connection-change/grid-connection-change.component";
import { PowerChangeDetailsComponent } from "@app/modules/electricity/grid-connection/power-change-details/power-change-details.component";
import { OtherControllableSystemComponent } from "@app/modules/electricity/other-2/other-controllable-system/other-controllable-system.component";
import { OtherCustomerFacilityComponent } from "@app/modules/electricity/other-2/other-customer-facility/other-customer-facility.component";
import { OtherDetailsComponent } from "@app/modules/electricity/other-2/other-details/other-details.component";
import { OtherSystemDetailsComponent } from "@app/modules/electricity/other-2/other-system-details/other-system-details.component";
import { OtherTypeComponent } from "@app/modules/electricity/other-2/other-type/other-type.component";
import { StorageControllableSystemComponent } from "@app/modules/electricity/storage-2/storage-controllable-system/storage-controllable-system.component";
import { StorageCustomerFacilityComponent } from "@app/modules/electricity/storage-2/storage-customer-facility/storage-customer-facility.component";
import { StorageDetailsComponent } from "@app/modules/electricity/storage-2/storage-details/storage-details.component";

import { CommissioningElectricityContactComponent } from "./modules/commissioning-electricity/commissioning-electricity-contact/commissioning-electricity-contact.component";
import { CommissioningElectricityOptionsComponent } from "./modules/commissioning-electricity/commissioning-electricity-options/commissioning-electricity-options.component";
import { AddressFormComponent } from "./modules/common-steps/address-form/address-form.component";
import { ContactComponent } from "./modules/common-steps/contact/contact.component";
import { DocumentsUploadComponent } from "./modules/common-steps/documents-upload/documents-upload.component";
import { ErrorComponent } from "./modules/common-steps/error/error.component";
import { FlowSelectionComponent } from "./modules/common-steps/flow-selection/flow-selection.component";
import { MainDomainPageComponent } from "./modules/common-steps/main-domain-page/main-domain-page.component";
import { ModuleSelectionComponent } from "./modules/common-steps/module-selection/module-selection.component";
import { ResponsibilityCheckFailComponent } from "./modules/common-steps/responsibility-check-fail/responsibility-check-fail.component";
import { SuccessComponent } from "./modules/common-steps/success/success.component";
import { SummaryComponent } from "./modules/common-steps/summary/summary.component";
import { ChargingDeviceControllableSystemComponent } from "./modules/electricity/charging-device-2/charging-device-controllable-system/charging-device-controllable-system.component";
import { ChargingDeviceInformationComponent } from "./modules/electricity/charging-device-2/charging-device-information/charging-device-information.component";
import { ChargingDeviceSystemInformationComponent } from "./modules/electricity/charging-device-2/charging-device-system-information/charging-device-system-information.component";
import { ConstructionElectricityComponent } from "./modules/electricity/construction-electricity/construction-electricity.component";
import { GridConnectionConsumerComponent } from "./modules/electricity/grid-connection/grid-connection-consumer/grid-connection-consumer.component";
import { GridConnectionPropertyTypeComponent } from "./modules/electricity/grid-connection/grid-connection-property-type/grid-connection-property-type.component";
import { GridConnectionTypeComponent } from "./modules/electricity/grid-connection/grid-connection-type/grid-connection-type.component";
import { HeatPumpControllableDetailsComponent } from "./modules/electricity/heat-pump-2/heat-pump-controllable-details/heat-pump-controllable-details.component";
import { HeatPumpInformationComponent } from "./modules/electricity/heat-pump-2/heat-pump-information/heat-pump-information.component";
import { HeatPumpSystemInformationComponent } from "./modules/electricity/heat-pump-2/heat-pump-system-information/heat-pump-system-information.component";
import { ProductSelectionComponent } from "./modules/electricity/product-selection/product-selection.component";
import { PvFacilityInformationComponent } from "./modules/electricity/pv-system/pv-facility-information/pv-facility-information.component";
import { PvGenerationUnitInformationComponent } from "./modules/electricity/pv-system/pv-generation-unit-information/pv-generation-unit-information.component";
import { PvInverterInformationComponent } from "./modules/electricity/pv-system/pv-inverter-information/pv-inverter-information.component";
import { GasOptionsComponent } from "./modules/gas/gas-options/gas-options.component";
import { GasPowerRequirementComponent } from "./modules/gas/gas-power-requirement/gas-power-requirement.component";
import { GasPropertyTypeComponent } from "./modules/gas/gas-property-type/gas-property-type.component";
import { HeatOptionsComponent } from "./modules/heat/heat-options/heat-options.component";
import { HeatPropertyTypeComponent } from "./modules/heat/heat-property-type/heat-property-type.component";
import { HeatRequirementsComponent } from "./modules/heat/heat-requirements/heat-requirements.component";
import { ConstructionWaterRequirementsComponent } from "./modules/water/construction-water-requirements/construction-water-requirements.component";
import { SewageOptionsComponent } from "./modules/water/sewage-options/sewage-options.component";
import { SewageTypeComponent } from "./modules/water/sewage-type/sewage-type.component";
import { WaterAreaComponent } from "./modules/water/water-area/water-area.component";
import { WaterOptionsComponent } from "./modules/water/water-options/water-options.component";
import { WaterPropertyTypeComponent } from "./modules/water/water-property-type/water-property-type.component";
import { WaterRequirementsComponent } from "./modules/water/water-requirements/water-requirements.component";

const routes: Routes = [
  {
    path: "",
    component: ModuleSelectionComponent,
  },
  {
    path: ROUTES.MODULE_SELECTION,
    component: ModuleSelectionComponent,
  },
  {
    path: ROUTES.FLOW_SELECTION,
    component: FlowSelectionComponent,
  },
  {
    path: ROUTES.MAIN_DOMAIN_PAGE,
    component: MainDomainPageComponent,
  },
  {
    path: ROUTES.ADDRESS,
    component: AddressFormComponent,
  },
  {
    path: ROUTES.RESPONSIBILITY_CHECK_FAIL,
    component: ResponsibilityCheckFailComponent,
  },
  // commissioning electricity steps routes
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS,
    component: CommissioningElectricityOptionsComponent,
  },
  // commissioning - main power supply
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE,
    component: MainPowerSupplyTypeComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_PROPERTY_TYPE,
    component: CommissioningElectricityPropertyTypeComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION,
    component: CommissioningElectricityInformationComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS,
    component: PowerIncreaseDetailsComponent,
  },
  // commissioning - customer facility
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS,
    component: CommissioningElectricitySystemsComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS,
    component: CommissioningElectricitySimpleMeasurementDetailsComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS,
    component: CommissioningElectricityMeasurementDetailsComponent,
  },
  // commissioning - shared electricity routes
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_CONTACT,
    component: CommissioningElectricityContactComponent,
  },
  // gas steps routes
  {
    path: ROUTES.GAS_OPTIONS,
    component: GasOptionsComponent,
  },
  {
    path: ROUTES.GAS_PROPERTY_TYPE,
    component: GasPropertyTypeComponent,
  },
  {
    path: ROUTES.GAS_POWER_REQUIREMENT,
    component: GasPowerRequirementComponent,
  },
  // water routes
  {
    path: ROUTES.WATER_AREA,
    component: WaterAreaComponent,
  },
  {
    path: ROUTES.CONSTRUCTION_WATER_REQUIREMENTS,
    component: ConstructionWaterRequirementsComponent,
  },
  {
    path: ROUTES.SEWAGE_OPTIONS,
    component: SewageOptionsComponent,
  },
  {
    path: ROUTES.SEWAGE_TYPE,
    component: SewageTypeComponent,
  },
  {
    path: ROUTES.WATER_OPTIONS,
    component: WaterOptionsComponent,
  },
  {
    path: ROUTES.WATER_PROPERTY_TYPE,
    component: WaterPropertyTypeComponent,
  },
  {
    path: ROUTES.WATER_REQUIREMENTS,
    component: WaterRequirementsComponent,
  },
  {
    path: ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    component: ProductSelectionComponent,
  },
  {
    path: ROUTES.PV_FACILITY_INFORMATION,
    component: PvFacilityInformationComponent,
  },
  {
    path: ROUTES.PV_INVERTER_INFORMATION,
    component: PvInverterInformationComponent,
  },
  {
    path: ROUTES.PV_GENERATION_UNIT_INFORMATION,
    component: PvGenerationUnitInformationComponent,
  },
  {
    path: ROUTES.STORAGE_CUSTOMER_FACILITY,
    component: StorageCustomerFacilityComponent,
  },
  {
    path: ROUTES.STORAGE_CONTROLLABLE_SYSTEM,
    component: StorageControllableSystemComponent,
  },
  {
    path: ROUTES.STORAGE_DETAILS,
    component: StorageDetailsComponent,
  },
  {
    path: ROUTES.CHARGING_DEVICE_SYSTEM_INFORMATION,
    component: ChargingDeviceSystemInformationComponent,
  },
  {
    path: ROUTES.CHARGING_DEVICE_CONTROLLABLE_SYSTEM,
    component: ChargingDeviceControllableSystemComponent,
  },
  {
    path: ROUTES.CHARGING_DEVICE_INFORMATION,
    component: ChargingDeviceInformationComponent,
  },
  {
    path: ROUTES.HEAT_PUMP_SYSTEM_INFORMATION,
    component: HeatPumpSystemInformationComponent,
  },
  {
    path: ROUTES.HEAT_PUMP_CONTROLLABLE_DETAILS,
    component: HeatPumpControllableDetailsComponent,
  },
  {
    path: ROUTES.HEAT_PUMP_INFORMATION,
    component: HeatPumpInformationComponent,
  },
  {
    path: ROUTES.BALCONY_PV_INFO,
    component: BalconyPvInfoComponent,
  },
  {
    path: ROUTES.BALCONY_PV_EXIT,
    component: BalconyPvExitComponent,
  },
  {
    path: ROUTES.BALCONY_PV_SUMMARY,
    component: BalconyPvSummaryComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_TYPE,
    component: GridConnectionTypeComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_PROPERTY_TYPE,
    component: GridConnectionPropertyTypeComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_CONSUMER,
    component: GridConnectionConsumerComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_CHANGE,
    component: GridConnectionChangeComponent,
  },
  {
    path: ROUTES.POWER_CHANGE_DETAILS,
    component: PowerChangeDetailsComponent,
  },
  {
    path: ROUTES.CONSTRUCTION_ELECTRICITY,
    component: ConstructionElectricityComponent,
  },
  {
    path: ROUTES.OTHER_2_TYPE,
    component: OtherTypeComponent,
  },
  {
    path: ROUTES.OTHER_2_CUSTOMER_FACILITY,
    component: OtherCustomerFacilityComponent,
  },
  {
    path: ROUTES.OTHER_2_CONTROLLABLE_SYSTEM,
    component: OtherControllableSystemComponent,
  },
  {
    path: ROUTES.OTHER_2_SYSTEM_DETAILS,
    component: OtherSystemDetailsComponent,
  },
  {
    path: ROUTES.OTHER_2_DETAILS,
    component: OtherDetailsComponent,
  },
  {
    path: ROUTES.HEAT_OPTIONS,
    component: HeatOptionsComponent,
  },
  {
    path: ROUTES.HEAT_PROPERTY_TYPE,
    component: HeatPropertyTypeComponent,
  },
  {
    path: ROUTES.HEAT_REQUIREMENTS,
    component: HeatRequirementsComponent,
  },
  {
    path: ROUTES.DOCUMENTS_UPLOAD,
    component: DocumentsUploadComponent,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactComponent,
  },
  {
    path: ROUTES.SUMMARY,
    component: SummaryComponent,
  },
  {
    path: ROUTES.SUCCESS,
    component: SuccessComponent,
  },
  {
    path: ROUTES.ERROR,
    component: ErrorComponent,
  },
  { path: "**", redirectTo: ROUTES.MODULE_SELECTION },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
