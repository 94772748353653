import { FLOW_MODULE } from "@app/models/form-data.interface";

export enum CHARGING_DEVICE_TYPE {
  WALLBOX = "WALLBOX",
  CHARGING_STATION = "CHARGING_STATION",
  OTHER = "OTHER",
}

export enum LOAD_MANAGEMENT {
  NO = "NO",
  DYNAMIC = "DYNAMIC",
  STATIC = "STATIC",
}

export enum FORM_OF_FEED_IN_SALE {
  // Unentgeltliche Abnahme
  FREE_OF_CHARGE = "FREE_OF_CHARGE",
  // Geförderte Direktvermarktung (Marktprämie) nach § 20 EEG
  PROMOTED_DIRECT_MARKETING = "PROMOTED_DIRECT_MARKETING",
  // Einspeisevergütung
  FEED_IN_TARIFF = "FEED_IN_TARIFF",
  // Mieterstromzuschlag nach § 21 Abs. 3 EEG
  TENANT_ELECTRICITY_SURCHARGE = "TENANT_ELECTRICITY_SURCHARGE",
  // Sonstige Direktvermarktung nach § 21a EEG
  OTHER_DIRECT_MARKETING = "OTHER_DIRECT_MARKETING",
  // Vermarktung ohne gesetzliche Vergütung
  MARKETING_WITHOUT_LEGAL_REMUNERATION = "MARKETING_WITHOUT_LEGAL_REMUNERATION",
}

export enum BUILDING_TYPE {
  HOUSE = "HOUSE",
  MULTI_FAMILY_HOUSE = "MULTI_FAMILY_HOUSE",
  INDUSTRY = "INDUSTRY",
  MIXED = "MIXED",
}

export enum DOCUMENT_TYPE {
  MANUFACTURER_DOCUMENTS = "MANUFACTURER_DOCUMENTS",
  SITE_PLAN = "SITE_PLAN",
  FLOOR_PLAN = "FLOOR_PLAN",
  CONSUMER_LINEUP = "CONSUMER_LINEUP",
  UNIT_CERTIFICATE = "UNIT_CERTIFICATE",
  DATA_SHEET_MANUFACTURER = "DATA_SHEET_MANUFACTURER",
  DATA_SHEET_GENERATION_PLANTS = "DATA_SHEET_GENERATION_PLANTS",
  DATA_SHEET_STORAGE = "DATA_SHEET_STORAGE",
  UNIT_CERTIFICATE_INVERTER = "UNIT_CERTIFICATE_INVERTER",
  GRID_SYSTEM_PROTECTION_CERTIFICATE = "GRID_SYSTEM_PROTECTION_CERTIFICATE",
  UNIT_CERTIFICATE_GENERATION_PLANT = "UNIT_CERTIFICATE_GENERATION_PLANT",
  UNIT_CERTIFICATE_STORAGE = "UNIT_CERTIFICATE_STORAGE",
  MEASUREMENT_CONCEPT = "MEASUREMENT_CONCEPT",
  MEASUREMENT_CONCEPT_PV = "MEASUREMENT_CONCEPT_PV",
  WIRING_DIAGRAM = "WIRING_DIAGRAM",
  PROOF_OF_OWNERSHIP = "PROOF_OF_OWNERSHIP",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  INSTALLER_LICENCE_ELECTRICITY = "INSTALLER_LICENCE_ELECTRICITY",
  INSTALLER_LICENCE_WATER = "INSTALLER_LICENCE_WATER",
  OTHER = "OTHER",
  COMMISSIONING_PROTOCOL = "COMMISSIONING_PROTOCOL",
  OVERVIEW_CIRCUIT_DIAGRAM = "OVERVIEW_CIRCUIT_DIAGRAM",
  COMMISSIONING_PICTURES = "COMMISSIONING_PICTURES",
  DATA_SHEET_DEVICES_REQUIRING_APPROVAL = "DATA_SHEET_DEVICES_REQUIRING_APPROVAL",
  FINAL_REPORT = "FINAL_REPORT",
  POWER_FLOW_MONITORING_CERTIFICATE = "POWER_FLOW_MONITORING_CERTIFICATE",
  NA_PROTECTION_CERTIFICATE = "NA_PROTECTION_CERTIFICATE",
}

export enum ON_BEHALF_TYPE {
  INSTALLER = "INSTALLER",
  ARCHITECT = "ARCHITECT",
  BUILDER = "BUILDER",
  LEGAL_PERSON = "LEGAL_PERSON",
}

export enum FORM_TYPE {
  PV_2 = "PV_2",
  GRID_CONNECTION = "GRID_CONNECTION",
  CHARGING_DEVICE_2 = "CHARGING_DEVICE_2",
  STORAGE_2 = "STORAGE_2",
  GAS = "GAS",
  WATER = "WATER",
  SEWAGE = "SEWAGE",
  CONSTRUCTION_WATER = "CONSTRUCTION_WATER",
  CONSTRUCTION_ELECTRICITY = "CONSTRUCTION_ELECTRICITY",
  HEAT_PUMP_2 = "HEAT_PUMP_2",
  OTHER_2 = "OTHER_2",
  HEAT_NEW = "HEAT_NEW",
  HEAT_CHANGE = "HEAT_CHANGE",
  HEAT_DETACH = "HEAT_DETACH",
}

export enum GRID_CONNECTION_TYPE {
  NEW = "NEW",
  POWER = "POWER",
  CHANGE = "CHANGE",
  DETACH = "DETACH",
}

export enum GAS_OPTION {
  NEW = "NEW",
  CHANGE = "CHANGE",
  DETACH = "DETACH",
}

export enum WATER_OPTION {
  NEW = "NEW",
  CHANGE = "CHANGE",
  DETACH = "DETACH",
}

export enum SEWAGE_OPTION {
  NEW = "NEW",
  DETACH = "DETACH",
}

export enum HEAT_OPTION {
  NEW = "HEAT_NEW",
  CHANGE = "HEAT_CHANGE",
  DETACH = "HEAT_DETACH",
}

export enum CONSUMER_TYPE {
  HEAT_PUMP = "HEAT_PUMP",
  FLOW_HEATER = "FLOW_HEATER",
  WALLBOX = "WALLBOX",
  ELEVATOR = "ELEVATOR",
}

export enum CHANGE_TYPE {
  CHANGE_LINE_ROUTING = "CHANGE_LINE_ROUTING",
  RELOCATION = "RELOCATION",
  NEW_LINE = "NEW_LINE",
}

export enum GRID_CONNECTION_CONSUMER_TYPE {
  HEAT_PUMP = "HEAT_PUMP",
  FLOW_HEATER = "FLOW_HEATER",
  WALLBOX = "WALLBOX",
}

export enum BUILDING_STRUCTURE {
  NEW = "NEW",
  EXISTING = "EXISTING",
}

export enum CURRENT_ENERGY_SOURCE {
  OIL = "OIL",
  GAS = "GAS",
  ELECTRICITY = "ELECTRICITY",
  COAL_WOOD = "COAL_WOOD",
  LOCAL_DISTRICT_HEATING = "LOCAL_DISTRICT_HEATING",
  HEAT_PUMP = "HEAT_PUMP",
  OTHER = "OTHER",
}

export enum HOUSE_INLET {
  SINGLE_SECTION = "SINGLE_SECTION",
  MULTI_SECTION = "MULTI_SECTION",
}

export enum SEWAGE_TYPE {
  DOMESTIC_WASTE_WATER = "DOMESTIC_WASTE_WATER",
  DOMESTIC_WASTE_WATER_AND_RAIN_WATER = "DOMESTIC_WASTE_WATER_AND_RAIN_WATER",
  INDUSTRIAL_WASTE_WATER = "INDUSTRIAL_WASTE_WATER",
  INDUSTRIAL_WASTE_WATER_AND_RAIN_WATER = "INDUSTRIAL_WASTE_WATER_AND_RAIN_WATER",
  RAIN_WATER = "RAIN_WATER",
}

export enum CONTROLLABLE_POWER {
  CONTROLLABLE_CONSUMPTION_DEVICES = "CONTROLLABLE_CONSUMPTION_DEVICES",
  ENERGY_MANAGEMENT_SYSTEM = "ENERGY_MANAGEMENT_SYSTEM",
  NO = "NO",
}

export enum OPERATING_MODE {
  EXCESS_FEED_IN = "EXCESS_FEED_IN",
  FULL_FEED_IN = "FULL_FEED_IN",
  ZERO_FEED_IN = "ZERO_FEED_IN",
}

export enum STORAGE_OPERATING_MODE {
  NONE = "NONE",
  SUPPLY = "SUPPLY",
  FEED_IN = "FEED_IN",
  SUPPLY_AND_FEED_IN = "SUPPLY_AND_FEED_IN",
}

export enum NETWORK_AND_SYSTEM_PROTECTION_TYPE {
  METER_LOCATION = "METER_LOCATION",
  INVERTER = "INVERTER",
  ZERO_FEED_IN = "ZERO_FEED_IN",
}

export enum NETWORK_AND_SYSTEM_PROTECTION {
  METER_LOCATION = "METER_LOCATION",
  INVERTER = "INVERTER",
}

export enum UNIT_COUPLING {
  OWN_INVERTER = "OWN_INVERTER",
  COMMON_INVERTER = "COMMON_INVERTER",
}

export enum MEASUREMENT_CONCEPT {
  NO_EZA_AND_NO_CONSUMPTION_SYSTEM = "NO_EZA_AND_NO_CONSUMPTION_SYSTEM",
  GENERATING_SYSTEM_AND_NO_CONSUMPTION_SYSTEM = "GENERATING_SYSTEM_AND_NO_CONSUMPTION_SYSTEM",
  PRODUCTION_PATH_AND_CONSUMPTION_PATH = "PRODUCTION_PATH_AND_CONSUMPTION_PATH",
  ISOLATED_OPERATION = "ISOLATED_OPERATION",
  CASCADE_CONNECTION = "CASCADE_CONNECTION",
  MULTIPLE_APPLICATIONS = "MULTIPLE_APPLICATIONS",
}

export enum CONTROLLABLE_CONSUMPTION_SYSTEM {
  NONE = "NONE",
  CHARGING_POINT = "CHARGING_POINT",
  HEAT_PUMP = "HEAT_PUMP",
  AC = "AIR_CONDITIONING",
  STORAGE = "STORAGE",
}

export enum PV_CONTROL {
  PRIMARY_METERING_POINT_OPERATOR = "PRIMARY_METERING_POINT_OPERATOR",
  COMPETITIVE_METERING_POINT_OPERATOR = "COMPETITIVE_METERING_POINT_OPERATOR",
  USE_OF_EXISTING_TECHNICAL_FACILITIES_POSSIBLE = "USE_OF_EXISTING_TECHNICAL_FACILITIES_POSSIBLE",
  NOT_RELEVANT = "NOT_RELEVANT",
}

export enum PV_OPERATOR {
  PRIMARY = "PRIMARY",
  COMPETITIVE = "COMPETITIVE",
}

export enum GRID_FEED_IN {
  ONE_PHASE = "ONE_PHASE",
  TWO_PHASE = "TWO_PHASE",
  THREE_PHASE = "THREE_PHASE",
  THREE_PHASE_CURRENT = "THREE_PHASE_CURRENT",
}

export enum PV_INSTALLATION_PLACE {
  ROOF = "ROOF",
  OPEN_SPACE = "OPEN_SPACE",
  OTHER = "OTHER",
}

export type Consumer = {
  type: CONSUMER_TYPE;
};

type ConsumerDetails = {
  consumers: Consumer[];
  systemPower: number;
  connectionFuse: number;
  maxConcurrentPower: number;
  additionalConsumers?: string | null;
};

export type Document = {
  type: DOCUMENT_TYPE;
  name: string;
};

export type DocumentDetails = {
  documents: Document[];
  notes?: string;
};

export type Address = {
  zipCode: string;
  city: string;
  street?: string;
  streetNumber?: string;
};

export type Location = {
  address: Address;
  district?: string;
  parcel?: string;
  parcelNumber?: string;
};

export type ContactDetails = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type Contact = {
  onBehalf: boolean;
  onBehalfType?: ON_BEHALF_TYPE | null;
  powerOfAttorney?: boolean | null;
  powerOfAttorneyDocument?: Document;
  requesterContact: ContactDetails;
  differentAddress: boolean;
  differentRequesterAddress?: Address | null;
  onBehalfContact?: ContactDetails | null;
};

export type Metadata = {
  customerConfigurationId: string;
  createdAt?: string; // set by backend
};

export type GasBuildingDetails = {
  buildingType: BUILDING_TYPE;
  buildingStructure: BUILDING_STRUCTURE;
  houseInlet?: HOUSE_INLET;
  currentEnergySource?: CURRENT_ENERGY_SOURCE;
};

export type WaterBuildingDetails = {
  buildingType: BUILDING_TYPE;
  buildingStructure: BUILDING_STRUCTURE;
};

export type HeatBuildingDetails = {
  buildingType: BUILDING_TYPE;
  buildingStructure: BUILDING_STRUCTURE;
};

export type ChargingDevice2 = {
  type: FORM_TYPE.CHARGING_DEVICE_2;
  controllable: boolean;
  plannedCommissioningDate?: string;
  controllableDetails?: ControllableDetailsDto;
  details: ChargingDeviceDetailsDto;
};

export type ChargingDeviceDetailsDto = {
  systemManufacturer?: string;
  systemType?: string;
  systemCount: number;
  type: CHARGING_DEVICE_TYPE;
  public: boolean;
  specialRights: boolean;
  chargingPointACCount: number;
  chargingPointDCCount: number;
  maxGridPower: number;
  maxGridFeedInPower: number;
  systemPower: number;
  separateMeter: boolean;
  meterAvailable: boolean;
  meterNumber?: string; // Required, if meterAvailable = true
  loadManagement: LOAD_MANAGEMENT;
};

export type GridConnection = {
  details: GridConnectionDetails;
  type: FORM_TYPE.GRID_CONNECTION;
};

export type GridConnectionDetails = BaseGridConnectionDetails &
  (
    | NewGridConnectionDetails
    | PowerGridConnectionDetails
    | ChangeGridConnectionDetails
    | DetachGridConnectionDetails
  );

type BaseGridConnectionDetails = {
  type: GRID_CONNECTION_TYPE;
};

export type NewGridConnectionDetails = {
  buildingType: BUILDING_TYPE;
  basementAvailable: boolean;
  externalConnection: boolean;
  flatCount?: number;
  area?: number;
  consumerDetails: ConsumerDetails;
  type: GRID_CONNECTION_TYPE.NEW;
};

export type PowerGridConnectionDetails = {
  details: PowerChangeDetails;
  type: GRID_CONNECTION_TYPE.POWER;
};

export type ChangeGridConnectionDetails = {
  changeType: CHANGE_TYPE;
  totalPowerChanged: boolean;
  ditchLength: number;
  powerChangeDetails?: PowerChangeDetails | null;
  type: GRID_CONNECTION_TYPE.CHANGE;
};

export type DetachGridConnectionDetails = {
  type: GRID_CONNECTION_TYPE.DETACH;
};

export type PowerChangeDetails = {
  additionalConsumers: boolean;
  additionalConsumerInfo?: string | null;
  notes?: string;
  currentPowerDemand: number;
  plannedPowerDemand: number;
};

export type HeatPump2 = {
  type: FORM_TYPE.HEAT_PUMP_2;
  controllable: boolean;
  plannedCommissioningDate?: string;
  controllableDetails?: ControllableDetailsDto;
  details: HeatPumpInformationDto;
};

export type HeatPumpInformationDto = {
  systemManufacturer?: string;
  systemModel?: string;
  systemCount: number;
  systemType: HEAT_PUMP_SYSTEM_TYPE;
  power: number;
  additionalHeatingPower: number;
  maxStartingCurrent?: number;
  maxGridPower: number;
  separateMeter: boolean;
  meterAvailable: boolean;
  meterNumber?: string; // Required, if meterAvailable = true
  operationalPurposeOrCriticalInfrastructure: boolean;
  loadManagement: LOAD_MANAGEMENT;
};

export type Other2 = {
  details: OtherDetails;
  type: FORM_TYPE.OTHER_2;
};

export type OtherDetails = NewOtherDetailsDto | ChangeOtherDetailsDto;

export enum OTHER_TYPE {
  NEW = "NEW",
  CHANGE = "CHANGE",
}

export type NewOtherDetailsDto = {
  controllable: boolean;
  plannedCommissioningDate?: string;
  controllableDetails?: ControllableDetailsDto;
  details: OtherSystemDetailsDto;
  type: OTHER_TYPE.NEW;
};

export type OtherSystemDetailsDto = {
  systemManufacturer?: string;
  systemModel?: string;
  systemCount: number;
  systemType: OTHER_SYSTEM_TYPE;
  otherSystemType?: string;
  power: number;
  maxStartingCurrent?: number;
  maxControllablePowerSupply: number;
  separateMeter: boolean;
  meterAvailable: boolean;
  meterNumber?: string;
  operationalPurposeOrCriticalInfrastructure: boolean;
  loadManagement: LOAD_MANAGEMENT;
};

export enum OTHER_SYSTEM_TYPE {
  AIR_CONDITIONING = "AIR_CONDITIONING",
  COMBINED_HEAT_AND_POWER_PLANT = "COMBINED_HEAT_AND_POWER_PLANT",
  KWK = "KWK",
  WIND_TURBINE = "WIND_TURBINE",
  OTHER = "OTHER",
}

export type ChangeOtherDetailsDto = {
  changeSystem: CHANGE_SYSTEM_TYPE;
  otherChangeSystem?: string;
  controllable: boolean;
  changeType: OTHER_CHANGE_TYPE;
  power?: number;
  plannedPower?: number;
  description: string;
  type: OTHER_TYPE.CHANGE;
};

export enum CHANGE_SYSTEM_TYPE {
  PV = "PV",
  STORAGE = "STORAGE",
  CHARGING_DEVICE = "CHARGING_DEVICE",
  HEAT_PUMP = "HEAT_PUMP",
  OTHER = "OTHER",
}

export enum OTHER_CHANGE_TYPE {
  POWER_INCREASE = "POWER_INCREASE",
  MODE_OF_OPERATION = "MODE_OF_OPERATION",
  FEED_IN = "FEED_IN",
  OTHER = "OTHER",
}

export type PV = {
  details: PV2Dto;
  type: FORM_TYPE.PV_2;
};

export type PV_2 = {
  customerFacility: PVCustomerFacilityDto;
  generationUnit: PVGenerationUnitDto;
  inverter: PVInverterDto;
  type: FORM_TYPE.PV_2;
};

export type PVCustomerFacilityDto = {
  controllableConsumptionSystems: CONTROLLABLE_CONSUMPTION_SYSTEM[];
  control: PV_CONTROL;
  plannedCommissioningDate?: string;
  operator: PV_OPERATOR;
  formOfFeedInSale: FORM_OF_FEED_IN_SALE;
};

export type PVInverterDto = {
  operatingMode: OPERATING_MODE;
  isolatedOperational?: boolean;
  systemManufacturer: string;
  systemType: string;
  systemCount: number;
  maxApparentPower: number;
  maxControllablePower: number;
  gridFeedIn?: GRID_FEED_IN;
  meterAvailable: boolean;
  meterNumber?: string;
};

export type PVGenerationUnitDto = {
  powerPerModule: number;
  moduleCount: number;
  installationPlace: PV_INSTALLATION_PLACE;
  powerFlowMonitoring?: boolean;
  powerFlowMonitoringManufacturer?: string;
  powerFlowMonitoringType?: string;
  powerFlowMonitoringPower?: number;
  networkAndSystemProtectionDetails: NetworkAndSystemProtectionDetailsDto;
};
export type PV2Dto = {
  type: FORM_TYPE.PV_2;
  customerFacility: PVCustomerFacilityDto;
  inverter: PVInverterDto;
  generationUnit: PVGenerationUnitDto;
};

export type StorageDetails = {
  manufacturer: string;
  type: string;
  maxControllablePower: number;
  storageUnitCount: number;
  maxApparentPowerSum: number;
  unitCoupling: UNIT_COUPLING;
  operatingMode: STORAGE_OPERATING_MODE;
  measurementConcept?: MEASUREMENT_CONCEPT;
};

export type Storage2 = {
  controllable: boolean;
  plannedCommissioningDate?: string;
  formOfFeedInSale: FORM_OF_FEED_IN_SALE;
  // if controllable = true
  controllableDetails?: ControllableDetailsDto;
  networkAndSystemProtectionDetails?: NetworkAndSystemProtectionDetailsDto;
  details: StorageDetailsDto;
  type: FORM_TYPE.STORAGE_2;
};

export enum CONTROLLER {
  PRIMARY_METERING_POINT_OPERATOR = "PRIMARY_METERING_POINT_OPERATOR",
  COMPETITIVE_METERING_POINT_OPERATOR = "COMPETITIVE_METERING_POINT_OPERATOR",
  NETWORK_OPERATOR = "NETWORK_OPERATOR",
}

export enum EXISTING_SYSTEM {
  YES_WITHOUT_CONTROL = "YES_WITHOUT_CONTROL",
  YES_WITH_CONTROL = "YES_WITH_CONTROL",
  NO = "NO",
}

export enum CONTROL_TYPE {
  DIRECT = "DIRECT",
  EMS = "EMS",
}

export enum NETWORK_FEE_MODULE {
  MODULE_1 = "MODULE_1",
  MODULE_2 = "MODULE_2",
}

export enum HEAT_PUMP_SYSTEM_TYPE {
  AIR_TO_AIR = "AIR_TO_AIR",
  AIR_TO_WATER = "AIR_TO_WATER",
  WATER_TO_WATER = "WATER_TO_WATER",
  BRINE_TO_WATER = "BRINE_TO_WATER",
  OTHER = "OTHER",
}

export type ControllableDetailsDto = {
  totalMinimumPower: number;
  compliant: boolean;
  controller: CONTROLLER;
  existingSystem: EXISTING_SYSTEM;
  controlType: CONTROL_TYPE;
  networkFeeModule: NETWORK_FEE_MODULE;
  // Required, if controller = COMPETITIVE_METERING_POINT_OPERATOR
  controllerName?: string;
};

export type NetworkAndSystemProtectionDetailsDto = {
  location?: NETWORK_AND_SYSTEM_PROTECTION;
  manufacturer?: string;
  type?: string;
};

export type StorageDetailsDto = {
  operatingMode: STORAGE_OPERATING_MODE;
  maxControllablePowerSupply: number;
  loadManagement: LOAD_MANAGEMENT;
  capacity: number;
  unitCoupling: UNIT_COUPLING;
  // Required, if unitCoupling = OWN_INVERTER
  systemManufacturer?: string;
  // Required, if unitCoupling = OWN_INVERTER
  systemType?: string;
  // Required, if unitCoupling = OWN_INVERTER
  systemCount?: number;
  // Required, if unitCoupling = OWN_INVERTER
  // Scheinleistung
  maxApparentPower?: number;
  // Required, if unitCoupling = OWN_INVERTER
  separateMeter?: boolean;
  // Required, if unitCoupling = OWN_INVERTER
  meterAvailable?: boolean;
  // Required, if unitCoupling = OWN_INVERTER + meterAvailable = true
  meterNumber?: string;
};

export type ConstructionElectricity = {
  type: FORM_TYPE.CONSTRUCTION_ELECTRICITY;
  systemPower: number;
  devicesRequireApproval: boolean;
  requiredDevices?: string[];
  desiredInstallationDate: Date;
  desiredDeconstructionDate?: string | null;
};

export type ElectricityRegistrationFlowDetails = GenericFlowDetails<
  | ChargingDevice2
  | GridConnection
  | HeatPump2
  | Other2
  | PV
  | Storage2
  | ConstructionElectricity
  | PV_2
>;

export type Gas = {
  details: GasConnectionDetails;
  type: FORM_TYPE.GAS;
};

export type GasConnectionDetails =
  | NewGasDetails
  | ChangeGasDetails
  | DetachGasDetails;

export type NewGasDetails = {
  buildingDetails: GasBuildingDetails;
  powerDemand: number;
  notes?: string;
  type: GAS_OPTION.NEW;
};

export type ChangeGasDetails = {
  changePower: boolean;
  relocate: boolean;
  currentPowerDemand?: number;
  plannedPowerDemand?: number;
  ditchLength?: number;
  buildingDetails?: GasBuildingDetails;
  type: GAS_OPTION.CHANGE;
};

export type DetachGasDetails = {
  partialDeconstructionDesired: boolean;
  meterNumber: string;
  type: GAS_OPTION.DETACH;
};

export type GasRegistrationFlowDetails = GenericFlowDetails<Gas>;

export type Water = {
  type: FORM_TYPE.WATER;
  details: WaterDetails;
};

export type WaterDetails =
  | NewWaterDetails
  | ChangeWaterDetails
  | DetachWaterDetails;

export type NewWaterDetails = {
  buildingDetails: WaterBuildingDetails;
  numberOfHousingUnits: number;
  numberOfResidents?: number;
  numberOfFloors: number;
  extractionPointHeight: number;
  flowRate?: number;
  additionalConsumers?: string;
  type: WATER_OPTION.NEW;
};

export type ChangeWaterDetails = {
  changeFlowRate: boolean;
  relocate: boolean;
  currentFlowRate?: number;
  plannedFlowRate?: number;
  additionalDemand?: string;
  ditchLength?: number;
  buildingDetails: WaterBuildingDetails;
  type: WATER_OPTION.CHANGE;
};

export type DetachWaterDetails = {
  permanentDeconstruction: boolean;
  meterNumber: string;
  deconstructionUntil?: string;
  type: WATER_OPTION.DETACH;
};

export type Sewage = {
  type: FORM_TYPE.SEWAGE;
  details: SewageDetails;
};

export type SewageDetails = NewSewageDetails | DetachSewageDetails;

export type NewSewageDetails = {
  buildingDetails: WaterBuildingDetails;
  sewageType: SEWAGE_TYPE;
  type: SEWAGE_OPTION.NEW;
};

export type DetachSewageDetails = {
  permanentDeconstruction: boolean;
  meterNumber: string;
  deconstructionUntil?: string;
  type: SEWAGE_OPTION.DETACH;
};

export type ConstructionWater = {
  flowRate?: number;
  convertedSpace: number;
  desiredInstallationDate: string;
  desiredDeconstructionDate?: string;
  notes?: string;
  type: FORM_TYPE.CONSTRUCTION_WATER;
};

export type WaterRegistrationFlowDetails = GenericFlowDetails<
  Water | Sewage | ConstructionWater
>;

export type NewHeatDetails = {
  buildingDetails: HeatBuildingDetails;
  heatOutputDemand: number;
  otherConsumers?: string;
  otherConsumersPower?: number;
  notes?: string;
  type: FORM_TYPE.HEAT_NEW;
};

export type ChangeHeatDetails = {
  changeHeatPower: boolean;
  changeOther: boolean;
  currentHeatPower?: number;
  plannedHeatPower?: number;
  additionalHeatDemand?: string;
  otherChanges?: string;
  buildingDetails: HeatBuildingDetails;
  type: FORM_TYPE.HEAT_CHANGE;
};

export type DetachHeatDetails = {
  permanentDeconstruction: boolean;
  meterNumber: string;
  deconstructionUntil?: string;
  type: FORM_TYPE.HEAT_DETACH;
};

export type HeatRegistrationFlowDetails = GenericFlowDetails<
  NewHeatDetails | ChangeHeatDetails | DetachHeatDetails
>;

type BaseRegistrationFlowDocumentDetails = {
  documentDetails: DocumentDetails;
};

type RequiredRegistrationFlowDetailsFields = {
  type: FORM_TYPE;
};

type GenericFlowDetails<T extends RequiredRegistrationFlowDetailsFields> =
  RequiredRegistrationFlowDetailsFields & T;

export type RegistrationFlow = {
  flowDetails: RegistrationFlowDetailsWithDocuments;
  module: FLOW_MODULE;
};

export type RegistrationFlowDetails =
  | ElectricityRegistrationFlowDetails
  | GasRegistrationFlowDetails
  | WaterRegistrationFlowDetails
  | HeatRegistrationFlowDetails;

export type RegistrationFlowDetailsWithDocuments =
  BaseRegistrationFlowDocumentDetails & RegistrationFlowDetails;

export type RegistrationForm = {
  id?: string; // set by backend
  requestId?: string; // set by backend
  flow: RegistrationFlow;
  location: Location;
  contact: Contact;
  metadata: Metadata;
  requestType: "REGISTRATION";
};
