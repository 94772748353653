import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { filter, map, Subject, take, takeUntil } from "rxjs";

import { FLOW } from "@app/models/form-data.interface";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { WhiteLabelService } from "@app/services/white-label.service";

interface FlowDetails {
  enabled: boolean;
  name: FLOW;
  icon?: string;
  svgIcon?: string;
  label: string;
}

@Component({
  selector: "app-flow-selection",
  templateUrl: "./flow-selection.component.html",
})
export class FlowSelectionComponent implements OnInit, OnDestroy {
  public selectedFlow?: FLOW;
  public flow = FLOW;
  public flowSelectionForm!: FormGroup;
  public allTouched = false;
  public readonly flowDetailsList: FlowDetails[] = [
    {
      enabled: false,
      name: FLOW.REGISTRATION,
      label: "FLOW_SELECTION.REGISTRATION",
      icon: "app_registration",
    },
    {
      enabled: false,
      name: FLOW.COMMISSIONING,
      label: "FLOW_SELECTION.COMMISSIONING",
      icon: "engineering",
    },
  ];

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private whiteLabelService: WhiteLabelService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.evalEnabledFlows();
    this.createFlowSelectionForm();
    this.updateForm();
    this.watchForm();
  }

  private evalEnabledFlows(): void {
    this.whiteLabelService.whiteLabelConfig$
      .pipe(filter(Boolean), take(1))
      .subscribe((configuration) => {
        this.flowDetailsList.forEach((flow) => {
          flow.enabled = this.appStateService.isFlowAvailable(
            flow.name,
            configuration,
          );
        });
      });
  }

  private createFlowSelectionForm(): void {
    this.flowSelectionForm = this.formBuilder.group({
      selectedFlow: [null, [Validators.required]],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.selectedFlow),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((selectedFlow) => {
        this.flowSelectionForm.get("selectedFlow")?.setValue(selectedFlow);
        this.selectedFlow = selectedFlow;
      });
  }

  private watchForm(): void {
    this.flowSelectionForm
      .get("selectedFlow")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((selectedFlow) =>
        this.appStateService.updateFormData({
          selectedFlow,
        }),
      );
  }

  public selectFlow(selectedFlow: FLOW): void {
    if (this.selectedFlow !== selectedFlow) {
      this.appStateService.clearState(false, true);
      this.flowSelectionForm.get("selectedFlow")?.setValue(selectedFlow);
      this.selectedFlow = selectedFlow;
    }
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.flowSelectionForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.flowSelectionForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
