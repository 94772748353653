<app-summary-section-field
  [value]="address.zipCode"
  label="ADDRESS.FORM.ZIP_CODE"
/>

<app-summary-section-field [value]="address.city" label="ADDRESS.FORM.CITY" />

<app-summary-section-field
  [value]="address.street"
  label="ADDRESS.FORM.STREET"
/>

<app-summary-section-field
  [value]="address.streetNumber"
  label="ADDRESS.FORM.STREET_NUMBER"
/>
