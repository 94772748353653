<app-form-card
  titleIcon="electric_bolt"
  cardTitle="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.HEADING"
  data-testid="commissioning-electricity-simple-meter-details-heading"
>
  <form [formGroup]="measurementForm">
    @if (measurementDetails) {
      <ng-container formArrayName="measurementDetails">
        @for (
          control of measurementDetails.controls;
          track control;
          let i = $index
        ) {
          <ng-container [formArrayName]="i">
            <section>
              <h2 appRequiredSuffix>
                {{
                  "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.SUBHEADING"
                    | translate
                }}
              </h2>
              <mat-radio-group
                color="primary"
                formControlName="changeType"
                class="input-container"
              >
                <div>
                  @for (changeType of changeTypes; track changeType) {
                    <mat-radio-button
                      [value]="changeType"
                      [attr.data-testid]="
                        i + '-' + changeType + '-radio-button'
                      "
                      >{{
                        "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.TYPES." +
                          changeType | translate
                      }}</mat-radio-button
                    >
                  }
                </div>
                <mat-error
                  [hideError]="!allTouched"
                  appErrorMessages="changeType"
                />
              </mat-radio-group>
            </section>
            @if (
              (control | formLookup: "changeType") &&
              (control | formLookup: "changeType") !==
                measurementDeviceChangeType.INSTALLATION
            ) {
              <section>
                <h2 class="tooltip-heading-label">
                  {{
                    "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.SUBHEADING"
                      | translate
                  }}
                  <app-info-tooltip
                    tooltipText="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.TOOLTIP"
                  />
                </h2>
                <div class="input-container">
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.METER_NUMBER"
                        | translate
                    }}</mat-label>
                    <input
                      [attr.data-testid]="i + '-meter-number'"
                      matInput
                      type="text"
                      name="meterNumber"
                      formControlName="meterNumber"
                    />
                    <mat-error appErrorMessages="meterNumber" />
                  </mat-form-field>
                </div>
                <div class="space"></div>
              </section>
            }
            <div class="two-column-layout-container">
              <section class="two-column-layout-item">
                <h2 appRequiredSuffix>
                  {{
                    "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASUREMENT_TYPE.SUBHEADING"
                      | translate
                  }}
                </h2>
                <ng-container formArrayName="measurementTypes">
                  @for (
                    _ of getMeasurementTypesControl(i)?.controls;
                    track _;
                    let j = $index
                  ) {
                    <p>
                      <mat-checkbox
                        [formControlName]="j"
                        [attr.data-testid]="
                          i + '-measurement-type-checkbox-' + j
                        "
                        color="primary"
                        >{{ measurementTypes[j].label | translate }}
                      </mat-checkbox>
                    </p>
                  }
                  @if (allTouched) {
                    <mat-error appErrorMessages="measurementTypes" />
                  }
                </ng-container>
              </section>
              <section class="two-column-layout-item">
                <h2 appRequiredSuffix>
                  {{
                    "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.SUBHEADING"
                      | translate
                  }}
                </h2>
                <mat-radio-group
                  color="primary"
                  formControlName="locationType"
                  class="input-container"
                >
                  @for (locationType of locationTypes; track locationType) {
                    <mat-radio-button
                      [value]="locationType"
                      [attr.data-testid]="
                        i + '-' + locationType + '-radio-button'
                      "
                      >{{
                        "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES." +
                          locationType | translate
                      }}</mat-radio-button
                    >
                  }
                  <mat-error
                    [hideError]="!allTouched"
                    appErrorMessages="locationType"
                  />
                </mat-radio-group>
                @if (
                  (control | formLookup: "locationType") === meterLocation.OTHER
                ) {
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES.OTHER"
                        | translate
                    }}</mat-label>
                    <input
                      [attr.data-testid]="i + '-other-location'"
                      matInput
                      type="text"
                      name="otherLocation"
                      formControlName="otherLocation"
                    />
                    <mat-error appErrorMessages="otherLocation" />
                  </mat-form-field>
                }
              </section>
            </div>
            <div class="two-column-layout-container">
              <section class="two-column-layout-item">
                <div class="space"></div>
                <h2 appRequiredSuffix>
                  {{
                    "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.SUBHEADING"
                      | translate
                  }}
                </h2>
                <mat-radio-group
                  color="primary"
                  formControlName="fasteningType"
                  class="input-container"
                >
                  <mat-radio-button
                    [value]="fasteningType.THREE_POINT"
                    [attr.data-testid]="
                      i + '-' + fasteningType.THREE_POINT + '-radio-button'
                    "
                    >{{
                      "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.TYPES.THREE_POINT"
                        | translate
                    }}</mat-radio-button
                  >
                  <mat-radio-button
                    [value]="fasteningType.PLUG"
                    [attr.data-testid]="
                      i + '-' + fasteningType.PLUG + '-radio-button'
                    "
                  >
                    {{
                      "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.TYPES.PLUG"
                        | translate
                    }}
                  </mat-radio-button>
                  <mat-error
                    [hideError]="!allTouched"
                    appErrorMessages="fasteningType"
                  />
                </mat-radio-group>
              </section>
              <section class="two-column-layout-item">
                <div class="space"></div>
                <h2 appRequiredSuffix>
                  {{
                    "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.SUBHEADING"
                      | translate
                  }}
                </h2>
                <mat-checkbox
                  [attr.data-testid]="i + '-basic-responsible'"
                  color="primary"
                  formControlName="basicResponsible"
                  >{{
                    "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.BASIC_RESPONSIBLE"
                      | translate
                  }}</mat-checkbox
                >
                @if (!(control | formLookup: "basicResponsible")) {
                  <mat-form-field
                    appearance="outline"
                    class="input-field other-operator"
                  >
                    <mat-label>{{
                      "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.OTHER_OPERATOR"
                        | translate
                    }}</mat-label>
                    <input
                      [attr.data-testid]="i + '-other-operator'"
                      matInput
                      type="text"
                      name="otherOperator"
                      formControlName="otherOperator"
                    />
                    <mat-error appErrorMessages="otherOperator" />
                  </mat-form-field>
                }
              </section>
              <section class="two-column-layout-item">
                <h2>
                  {{
                    "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CONVERTER_SIZE"
                      | translate
                  }}
                </h2>
                <div class="input-container">
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CONVERTER_SIZE"
                        | translate
                    }}</mat-label>
                    <input
                      [attr.data-testid]="i + '-' + 'converter-size'"
                      matInput
                      type="number"
                      name="converterSize"
                      min="0"
                      formControlName="converterSize"
                    />
                    <mat-error appErrorMessages="converterSize" />
                  </mat-form-field>
                </div>
              </section>
            </div>
            @if (i > 0) {
              <div class="space"></div>
              <button
                (click)="removeDetails(i)"
                type="button"
                mat-stroked-button
              >
                <mat-icon>delete</mat-icon>
                {{
                  "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.REMOVE_BUTTON"
                    | translate
                }}
              </button>
            }
            @if (i < measurementDetails.controls.length - 1) {
              <div class="space"></div>
              <hr />
              <div class="space"></div>
            }
          </ng-container>
        }
        <div class="space"></div>
        @if (measurementDetails.controls.length > 1) {
          <hr />
          <div class="space"></div>
        }
        <button
          (click)="addDetails()"
          [disabled]="measurementDetails.controls.length | exceedsLimit"
          type="button"
          mat-stroked-button
          data-testid="add-button"
        >
          <mat-icon>add</mat-icon>
          {{
            "COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.ADD_MORE_BUTTON"
              | translate
          }}
        </button>
        <div class="space"></div>
      </ng-container>
    }
  </form>
  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: measurementForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
