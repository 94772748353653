<app-form-card titleIcon="home" cardTitle="WATER.WATER_PROPERTY_TYPE.HEADING">
  <h2 class="full-width">
    {{ "WATER.WATER_PROPERTY_TYPE.DESCRIPTION" | translate }}
  </h2>
  <app-selection-group>
    @for (property of propertyDetailsList; track property) {
      <app-selection-card
        (click)="selectPropertyType(property.type)"
        [icon]="property.icon"
        [label]="property.label"
        [selected]="selectedPropertyType === property.type"
      />
    }

    @if (allTouched && !selectedPropertyType) {
      <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
    }
  </app-selection-group>

  <div class="space"></div>

  <form [formGroup]="propertyDetailsForm">
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "SHARED.BUILDING_STRUCTURE.LABEL" | translate
        }}</mat-label>
        <mat-select formControlName="buildingStructure">
          @for (structureType of buildingStructures; track structureType) {
            <mat-option [value]="structureType">
              {{ "SHARED.BUILDING_STRUCTURE." + structureType | translate }}
            </mat-option>
          }
        </mat-select>
        <mat-error appErrorMessages="buildingStructure" />
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: propertyDetailsForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
