import { DOCUMENT } from "@angular/common";
import {
  Component,
  DestroyRef,
  HostListener,
  inject,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { filter, map, Observable } from "rxjs";

import {
  APP_LANGUAGE,
  DEFAULT_APP_LANGUAGE,
  LanguageKey,
} from "@app/constants/app.constants";
import { AppStateService } from "@app/services/app-state.service";
import { EnvironmentService } from "@app/services/environment.service";
import { ResizeObserverService } from "@app/services/resize-observer.service";
import { RouteService } from "@app/services/route.service";
import {
  PublicCustomerConfigurationDto,
  WhiteLabelService,
} from "@app/services/white-label.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #router = inject(Router);
  readonly #gtmService = inject(GoogleTagManagerService);
  readonly #whiteLabelService = inject(WhiteLabelService);
  readonly #environment = inject(EnvironmentService);
  readonly #translateService = inject(TranslateService);
  readonly #routeService = inject(RouteService);
  readonly #appStateService = inject(AppStateService);
  readonly #renderer2 = inject(Renderer2);
  readonly #resizeObserverService = inject(ResizeObserverService);

  private appLanguage!: LanguageKey;
  public whiteLabelConfiguration$: Observable<
    PublicCustomerConfigurationDto | undefined
  >;

  constructor(@Inject(DOCUMENT) readonly _document: Document) {
    this.whiteLabelConfiguration$ = this.#whiteLabelService.whiteLabelConfig$;
    this.#routeService.init(this.#whiteLabelService.hasWhitelabel());
  }

  public ngOnInit(): void {
    this.setApplicationLanguageAndDateLocale();
    this.initConsentBanner();
    this.#initAnalyticsTracking();
    this.#resizeObserverService.initObservation();
  }

  private setApplicationLanguageAndDateLocale(): void {
    const browserLanguage =
      this.#translateService.getBrowserLang() as LanguageKey;
    const availableLanguages = Object.values(APP_LANGUAGE).map(
      (languageLocale) => languageLocale.locale,
    );

    this.appLanguage = availableLanguages.includes(browserLanguage)
      ? browserLanguage
      : DEFAULT_APP_LANGUAGE.locale;

    this.#translateService.use(this.appLanguage);
  }

  @HostListener("window:beforeunload", ["$event"])
  public checkUnsavedChanges(event: BeforeUnloadEvent): void {
    if (this.#appStateService.isModified()) {
      // custom messages in dialogs are not supported
      // it is just used to show the default dialog if needed
      // see: https://developer.chrome.com/blog/chrome-51-deprecations/#remove-custom-messages-in-onbeforeunload-dialogs
      // (behaviour is similar for every modern browser)
      event.returnValue = "Wait!";
    }
  }

  private initConsentBanner(): void {
    if (this.#environment.isConsentBannerEnabled) {
      const script = this.#renderer2.createElement("script");

      script.id = `usercentrics-cmp`;
      script.type = `application/javascript`;
      script.src = `https://app.usercentrics.eu/browser-ui/latest/loader.js`;
      script.setAttribute("data-settings-id", "Kpwq7_5C1vKFS1");
      script.setAttribute("data-language", this.appLanguage);
      script.async = true;
      this.#renderer2.appendChild(this._document.head, script);
    }
  }

  #initAnalyticsTracking(): void {
    this.#router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((event) => {
        const gtmTag = {
          event: "page",
          pageName: event.url,
        };
        this.#gtmService.pushTag(gtmTag);
      });
  }
}
