<app-form-card
  titleIcon="contact_support"
  cardTitle="ADDRESS.HEADING"
  description="ADDRESS.DESCRIPTION"
  dataTestId="address-heading"
>
  @if (loading) {
    <app-loading-spinner />
  }
  <form [formGroup]="addressForm">
    <h2>{{ "ADDRESS.SUBHEADING" | translate }}</h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ "ADDRESS.FORM.ZIP_CODE" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="zipCode"
          formControlName="zipCode"
          data-testid="zip-code"
        />
        <mat-error appErrorMessages="zipCode" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ "ADDRESS.FORM.CITY" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="city"
          data-testid="city"
          formControlName="city"
        />
        <mat-error appErrorMessages="city" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ "ADDRESS.FORM.STREET" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="street"
          data-testid="street"
          formControlName="street"
        />
        <mat-error appErrorMessages="street" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ "ADDRESS.FORM.STREET_NUMBER" | translate }}</mat-label>
        <input
          matInput
          formControlName="streetNumber"
          name="streetNumber"
          data-testid="street-number"
        />
        <mat-error appErrorMessages="streetNumber" />
      </mat-form-field>
    </div>

    <p class="street-not-listed-selection">
      <mat-checkbox formControlName="streetNotListed" color="primary">{{
        "ADDRESS.FORM.STREET_NOT_LISTED" | translate
      }}</mat-checkbox>
      <app-info-tooltip tooltipText="ADDRESS.FORM.STREET_NOT_LISTED_TOOLTIP" />
    </p>

    @if (addressForm | formLookup: "streetNotListed") {
      <div formGroupName="additionalAddressData" class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ "ADDRESS.FORM.DISTRICT" | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="district"
            formControlName="district"
          />
          <mat-error appErrorMessages="district" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="nested-input-field">
          <mat-label>{{ "ADDRESS.FORM.PARCEL" | translate }}</mat-label>
          <input matInput type="text" name="parcel" formControlName="parcel" />
          <mat-error appErrorMessages="parcel" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="nested-input-field">
          <mat-label>{{ "ADDRESS.FORM.PARCEL_NUMBER" | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="parcelNumber"
            formControlName="parcelNumber"
          />
          <mat-error appErrorMessages="parcelNumber" />
        </mat-form-field>
      </div>
    }

    @if (selectedModule$ | async | hasResponsibilityCheck) {
      <div class="address-captcha-container">
        <app-ngx-turnstile
          (resolved)="onTokenResolve($event)"
          [action]="'address-captcha'"
        />
      </div>
    }
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled:
        allTouched
        | nextButtonDisabledAddress
          : selectedModule
          : !!turnstileCaptchaToken
          : addressForm.valid
          : loading,
    }"
    slot="navigation"
  />
</app-form-card>
