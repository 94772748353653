<div class="summary-section-field-container">
  @if (innerHTMLLabel) {
    <p [innerHTML]="innerHTMLLabel | translate" class="label"></p>
  } @else {
    <p class="label">
      {{ label ? (label | translate) : "-" }}
    </p>
  }

  <p class="bold">{{ value ?? "-" }}</p>
</div>
