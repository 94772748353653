import { Component, Input } from "@angular/core";

import { Address } from "@app/models/registration-form";

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class AddressComponent {
  @Input({ required: true }) address!: Address;
}
