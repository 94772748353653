<div class="card-container">
  <div class="card">
    @if (titleIcon || svgIcon) {
      <mat-icon
        [fontIcon]="titleIcon || ''"
        [svgIcon]="svgIcon || ''"
        aria-hidden="false"
        aria-label="Card icon"
        class="card-icon material-icons-outlined"
      />
    }
    @if (cardTitle) {
      <h1 [attr.data-testid]="dataTestId">
        {{ cardTitle | translate }}
      </h1>
    }
    @if (description) {
      <p class="description">
        {{ description | translate }}
      </p>
    }
    <div class="content">
      <ng-content />
    </div>
    <div class="navigation">
      <ng-content select="[slot=navigation]" />
    </div>
  </div>
</div>
