<app-form-card
  titleIcon="3p"
  cardTitle="COMMISSIONING_ELECTRICITY.CONTACT.CONTACT_DETAILS"
  description="COMMISSIONING_ELECTRICITY.CONTACT.DESCRIPTION"
  dataTestId="contact-heading"
>
  <form [formGroup]="contactForm">
    <h2>
      {{ "COMMISSIONING_ELECTRICITY.CONTACT.CONNECTION_RECIPIENT" | translate }}
    </h2>
    <div class="input-container">
      <ng-container [formGroup]="contactDetailsForm">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ "CONTACT.FIRST_NAME" | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="firstName"
            formControlName="firstName"
            data-testid="first-name"
          />
          <mat-error appErrorMessages="firstName" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ "CONTACT.LAST_NAME" | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="lastName"
            formControlName="lastName"
            data-testid="last-name"
          />
          <mat-error appErrorMessages="lastName" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ "CONTACT.EMAIL" | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="email"
            formControlName="email"
            data-testid="email"
          />
          <mat-error appErrorMessages="email" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ "CONTACT.PHONE" | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="phone"
            formControlName="phone"
            data-testid="phone"
          />
          <mat-error appErrorMessages="phone" />
        </mat-form-field>
      </ng-container>
    </div>

    <div class="space"></div>

    <h2 appRequiredSuffix>
      {{
        "COMMISSIONING_ELECTRICITY.CONTACT.CONNECTION_ADDRESS_QUESTION"
          | translate
      }}
    </h2>
    <mat-radio-group formControlName="differentAddress" class="input-container">
      <div>
        <mat-radio-button
          [value]="false"
          color="primary"
          data-testid="no-different-address"
          >{{ "COMMON.YES" | translate }}</mat-radio-button
        >
        <mat-radio-button
          [value]="true"
          color="primary"
          data-testid="different-address"
        >
          {{ "COMMON.NO" | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        [hideError]="!allTouched"
        appErrorMessages="differentAddress"
      />
    </mat-radio-group>

    @if (contactForm | formLookup: "differentAddress") {
      <ng-container formGroupName="differentRequesterAddress">
        <h2>
          {{ "CONTACT.REQUESTER_ADDRESS" | translate }}
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>{{ "ADDRESS.FORM.ZIP_CODE" | translate }}</mat-label>
            <input
              matInput
              type="text"
              name="zipCode"
              formControlName="zipCode"
              data-testid="different-address-zip"
            />
            <mat-error appErrorMessages="zipCode" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>{{ "ADDRESS.FORM.CITY" | translate }}</mat-label>
            <input
              matInput
              type="text"
              name="city"
              formControlName="city"
              data-testid="different-address-city"
            />
            <mat-error appErrorMessages="city" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>{{ "ADDRESS.FORM.STREET" | translate }}</mat-label>
            <input
              matInput
              type="text"
              name="street"
              formControlName="street"
              data-testid="different-address-street"
            />
            <mat-error appErrorMessages="street" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>{{
              "ADDRESS.FORM.STREET_NUMBER" | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              name="streetNumber"
              formControlName="streetNumber"
              data-testid="different-address-street-number"
            />
            <mat-error appErrorMessages="streetNumber" />
          </mat-form-field>
        </div>
      </ng-container>
    }

    <p [innerHTML]="'COMMISSIONING_ELECTRICITY.CONTACT.INFO' | translate"></p>

    <div class="space"></div>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.CONTACT.GRID_OPERATOR" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="gridOperator"
          formControlName="gridOperator"
          data-testid="grid-operator"
        />
        <mat-error appErrorMessages="gridOperator" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.CONTACT.ID_NUMBER" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="idNumber"
          formControlName="idNumber"
          data-testid="id-number"
        />
        <mat-error appErrorMessages="idNumber" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.CONTACT.ELECTRICIAN_NAME" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="electricianName"
          formControlName="electricianName"
          data-testid="electrician-name"
        />
        <mat-error appErrorMessages="electricianName" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.CONTACT.ELECTRICIAN_EMAIL" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="electricianEmail"
          formControlName="electricianEmail"
          data-testid="electrician-email"
        />
        <mat-error appErrorMessages="electricianEmail" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.CONTACT.PHONE" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="phone"
          formControlName="phone"
          data-testid="installer-phone"
        />
        <mat-error appErrorMessages="phone" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.CONTACT.LOCATION" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="location"
          formControlName="location"
          data-testid="installer-location"
        />
        <mat-error appErrorMessages="location" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.CONTACT.DATE" | translate
        }}</mat-label>
        <input [matDatepicker]="picker" matInput formControlName="date" />
        <mat-datepicker-toggle [for]="picker" matIconSuffix />
        <mat-datepicker #picker />
      </mat-form-field>
    </div>
  </form>
  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: contactForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
