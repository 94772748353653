import { Component, Input } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";

import { HeatPumpInformationDto } from "@app/models/registration-form";
import { ROUTES } from "@app/models/routes.enum";
import { SharedModule } from "@app/modules/shared/shared.module";

@Component({
  selector: "app-heat-pump-information-summary",
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: "./heat-pump-information-summary.component.html",
})
export class HeatPumpInformationSummaryComponent {
  @Input({ required: true })
  public heatPumpInformation!: HeatPumpInformationDto;
  public readonly route = ROUTES.HEAT_PUMP_INFORMATION;
}
