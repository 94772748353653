<app-form-card
  titleIcon="electric_bolt"
  cardTitle="PV_GENERATION_UNIT_INFORMATION.HEADING"
  description="PV_GENERATION_UNIT_INFORMATION.DESCRIPTION"
  dataTestId="pv-generation-unit-heading"
>
  <div class="space"></div>

  <form [formGroup]="pvGenerationUnitForm">
    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>
            {{ "PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_DATA" | translate }}
          </label>
        </h2>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>
              {{
                "PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_PV_MODULE_IN_KWP"
                  | translate
              }}
            </mat-label>
            <input
              [attr.data-testid]="GENERATION_UNIT_FORM.POWER_PER_MODULE"
              [name]="GENERATION_UNIT_FORM.POWER_PER_MODULE"
              [formControlName]="GENERATION_UNIT_FORM.POWER_PER_MODULE"
              matInput
              type="number"
              min="0"
            />
            <mat-error
              [appErrorMessages]="GENERATION_UNIT_FORM.POWER_PER_MODULE"
            />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_PV_MODULE_IN_KWP_TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>
              {{ "PV_GENERATION_UNIT_INFORMATION.MODULE_COUNT" | translate }}
            </mat-label>
            <input
              [attr.data-testid]="GENERATION_UNIT_FORM.MODULE_COUNT"
              [name]="GENERATION_UNIT_FORM.MODULE_COUNT"
              [formControlName]="GENERATION_UNIT_FORM.MODULE_COUNT"
              matInput
              type="number"
              min="0"
            />
            <mat-error [appErrorMessages]="GENERATION_UNIT_FORM.MODULE_COUNT" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_GENERATION_UNIT_INFORMATION.MODULE_COUNT_TOOLTIP"
          />
        </div>
        <div class="space"></div>
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label class="label-required">
            {{
              "PV_GENERATION_UNIT_INFORMATION.INSTALLATION_PLACE" | translate
            }}
          </label>
          <app-info-tooltip
            tooltipText="PV_GENERATION_UNIT_INFORMATION.INSTALLATION_PLACE_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="GENERATION_UNIT_FORM.INSTALLATION_PLACE"
          class="input-container"
        >
          @for (option of installationPlaceOptions; track option) {
            <mat-radio-button
              [value]="option"
              [attr.data-testid]="option"
              color="primary"
            >
              {{ "PV_GENERATION_UNIT_INFORMATION." + option | translate }}
            </mat-radio-button>
          }
          <mat-error
            [appErrorMessages]="GENERATION_UNIT_FORM.INSTALLATION_PLACE"
            [hideError]="!allTouched"
          />
        </mat-radio-group>
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>
            {{
              "PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING" | translate
            }}
          </label>
          <app-info-tooltip
            tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING"
          class="input-container"
        >
          <mat-radio-button
            [value]="true"
            [attr.data-testid]="
              GENERATION_UNIT_FORM.POWER_FLOW_MONITORING + '-true'
            "
            color="primary"
            >{{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button
            [value]="false"
            [attr.data-testid]="
              GENERATION_UNIT_FORM.POWER_FLOW_MONITORING + '-false'
            "
            color="primary"
          >
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [appErrorMessages]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING"
            [hideError]="!allTouched"
          />
        </mat-radio-group>

        @if (
          pvGenerationUnitForm.get(GENERATION_UNIT_FORM.POWER_FLOW_MONITORING)
            ?.value === true
        ) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label>
                {{
                  "PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER"
                    | translate
                }}
              </mat-label>
              <input
                [name]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_MANUFACTURER"
                [formControlName]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_MANUFACTURER
                "
                matInput
              />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_MANUFACTURER
                "
              />
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER_TOOLTIP"
            />
          </div>

          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label>
                {{
                  "PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE"
                    | translate
                }}
              </mat-label>
              <input
                [name]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_TYPE"
                [formControlName]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_TYPE
                "
                matInput
              />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_TYPE
                "
              />
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_TOOLTIP"
            />
          </div>

          <h2 class="tooltip-heading-label">
            <label class="label-required:">
              {{
                "PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_POWER_HEADLINE"
                  | translate
              }}
            </label>
          </h2>
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label>
                {{
                  "PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_POWER"
                    | translate
                }}
              </mat-label>
              <input
                [name]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_POWER"
                [formControlName]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_POWER
                "
                matInput
                type="number"
                min="0"
              />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_POWER
                "
              />
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_POWER_TOOLTIP"
            />
          </div>
        }
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>{{
            "PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS"
              | translate
          }}</label>
          <app-info-tooltip
            tooltipText="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="
            GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION
          "
          color="primary"
          class="input-container"
        >
          @for (option of networksSystemProtection; track option) {
            <mat-radio-button
              [value]="option"
              [attr.data-testid]="
                'network-and-system-protection-details-' + $index
              "
            >
              {{ "PV_GENERATION_UNIT_INFORMATION." + option | translate }}
            </mat-radio-button>
          }
          <mat-error
            [appErrorMessages]="
              GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION
            "
            [hideError]="!allTouched"
          />
        </mat-radio-group>

        @if (
          pvGenerationUnitForm.get(
            GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION
          )?.value === "METER_LOCATION"
        ) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label>
                {{
                  "PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER"
                    | translate
                }}
              </mat-label>
              <input
                [name]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER
                "
                [formControlName]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER
                "
                matInput
              />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER
                "
              />
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER_TOOLTIP"
            />
          </div>

          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label>
                {{
                  "PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE"
                    | translate
                }}
              </mat-label>
              <input
                [name]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE
                "
                [formControlName]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE
                "
                matInput
              />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE
                "
              />
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE_TOOLTIP"
            />
          </div>
        }
      </section>
    </div>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: pvGenerationUnitForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
