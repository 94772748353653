<app-form-card
  titleIcon="home"
  cardTitle="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.HEADING"
>
  <h2 class="full-width">
    {{ "COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.DESCRIPTION" | translate }}
  </h2>
  <app-selection-group>
    @for (property of propertyDetailsList; track property) {
      <app-selection-card
        (click)="selectPropertyType(property.type)"
        [icon]="property.icon"
        [label]="property.label"
        [selected]="selectedPropertyType === property.type"
        [attr.data-testid]="property.type"
      />
    }

    @if (allTouched && !selectedPropertyType) {
      <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
    }
  </app-selection-group>

  <div class="space"></div>
  <form [formGroup]="propertyDetailsForm">
    @if (
      selectedPropertyType === propertyType.INDUSTRY ||
      selectedPropertyType === propertyType.MIXED
    ) {
      <section>
        <div class="input-container">
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>
              {{ "COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.AREA" | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              name="area"
              formControlName="area"
              min="0"
              data-testid="area"
            />
            <mat-error appErrorMessages="area" />
          </mat-form-field>
        </div>
      </section>
    }

    @if (selectedPropertyType === propertyType.MULTI_FAMILY_HOUSE) {
      <section>
        <div class="input-container">
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>
              {{
                "COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.FLAT_COUNT" | translate
              }}
            </mat-label>
            <input
              matInput
              type="number"
              name="flatCount"
              formControlName="flatCount"
              min="0"
              data-testid="flat-count"
            />
            <mat-error appErrorMessages="flatCount" />
          </mat-form-field>
        </div>
      </section>
    }
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: propertyDetailsForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
