import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";

export interface ButtonConfig {
  visible: boolean;
  disabled: boolean;
  arrow: boolean;
  label: string;
}

@Component({
  selector: "app-form-navigation",
  templateUrl: "./form-navigation.component.html",
  styleUrls: ["./form-navigation.component.scss"],
})
export class FormNavigationComponent implements OnChanges {
  public _previousConfig: ButtonConfig = {
    visible: true,
    disabled: false,
    arrow: true,
    label: "COMMON.BACK",
  };

  public _nextConfig: ButtonConfig = {
    visible: true,
    disabled: false,
    arrow: true,
    label: "COMMON.NEXT",
  };

  @Input()
  public set previousConfig(config: Partial<ButtonConfig>) {
    this._previousConfig = {
      ...this._previousConfig,
      ...config,
    };
  }

  @Input()
  public set nextConfig(config: Partial<ButtonConfig>) {
    this._nextConfig = {
      ...this._nextConfig,
      ...config,
    };
  }

  @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
  @Output() public next: EventEmitter<void> = new EventEmitter<void>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["previousConfig"]) {
      this.previousConfig = changes["previousConfig"]
        .currentValue as Partial<ButtonConfig>;
    }
    if (changes["nextConfig"]) {
      this.nextConfig = changes["nextConfig"]
        .currentValue as Partial<ButtonConfig>;
    }
  }
}
