import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  of,
  Subject,
  take,
  takeUntil,
  tap,
} from "rxjs";

import { FLOW_MODULE } from "@app/models/form-data.interface";
import { Address } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { ResponsibilityCheckService } from "@app/services/responsibility-check.service";
import { RouteService } from "@app/services/route.service";
import { SnackBarService } from "@app/services/snack-bar.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  selector: "app-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"],
})
export class AddressFormComponent implements OnInit, OnDestroy {
  readonly #formBuilder = inject(FormBuilder);
  readonly #appStateService = inject(AppStateService);
  readonly #routeService = inject(RouteService);
  readonly #responsibilityCheckService = inject(ResponsibilityCheckService);
  readonly #snackBarService = inject(SnackBarService);

  public addressForm!: FormGroup;
  public streetControl!: FormControl<string>;
  public streetNumberControl!: FormControl<string>;
  public loading = false;
  public selectedModule: FLOW_MODULE | null = null;
  public readonly selectedModule$ = this.#appStateService.observeState().pipe(
    map(({ formData }) => formData.selectedModule ?? null),
    distinctUntilChanged(),
    tap((selectedModule) => (this.selectedModule = selectedModule)),
  );
  public allTouched = false;
  public readonly turnstileCaptchaToken: WritableSignal<string | null> =
    signal(null);
  private onDestroy$: Subject<void> = new Subject();

  public ngOnInit(): void {
    this.createAddressForm();
    this.updateForm();
    this.watchForm();
  }

  public onTokenResolve($event: string | null): void {
    this.turnstileCaptchaToken.set($event);
  }

  private createAddressForm(): void {
    this.addressForm = this.#formBuilder.group({
      zipCode: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.germanZip,
          ],
        },
      ],
      city: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      street: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      streetNumber: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      streetNotListed: false,
    });
    this.streetControl = this.addressForm.get("street") as FormControl;
    this.streetNumberControl = this.addressForm.get(
      "streetNumber",
    ) as FormControl;
    this.watchStreetNotListed();
  }

  private watchStreetNotListed(): void {
    this.addressForm
      .get("streetNotListed")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((streetNotListed) => {
        if (streetNotListed) {
          this.addressForm.addControl(
            "additionalAddressData",
            this.#formBuilder.group(
              {
                district: [
                  null,
                  [
                    CustomValidators.trimValidator,
                    CustomValidators.shortText,
                    Validators.required,
                  ],
                ],
                parcel: [
                  null,
                  [
                    CustomValidators.trimValidator,
                    CustomValidators.shortText,
                    Validators.required,
                  ],
                ],
                parcelNumber: [
                  null,
                  [
                    CustomValidators.trimValidator,
                    CustomValidators.shortText,
                    Validators.required,
                  ],
                ],
              },
              { updateOn: "blur" },
            ),
          );
          this.streetControl?.removeValidators(Validators.required);
          this.streetNumberControl?.removeValidators(Validators.required);
        } else {
          this.addressForm.removeControl("additionalAddressData");
          this.streetControl?.addValidators(Validators.required);
          this.streetNumberControl?.addValidators(Validators.required);
        }
        this.streetControl?.updateValueAndValidity();
        this.streetNumberControl?.updateValueAndValidity();
      });
  }

  private updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.address),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((addressData) => this.addressForm.patchValue(addressData));
  }

  private watchForm(): void {
    this.addressForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((address) =>
        this.#appStateService.updateFormData({ address }),
      );
  }

  public next(): void {
    if (this.addressForm.valid) {
      if (
        !this.#responsibilityCheckService.hasResponsibilityCheck(
          this.selectedModule,
        )
      ) {
        this.#routeService.navigateToNextStep();
      } else {
        this.verifyAddress();
      }
    } else {
      this.allTouched = true;
      this.addressForm.markAllAsTouched();
    }
  }

  private verifyAddress(): void {
    if (!this.turnstileCaptchaToken()) {
      this.#snackBarService.openSnackBar(
        "SHARED.SNACKBAR.CAPTCHA_MISSING",
        false,
      );
      return;
    }
    this.loading = true;
    const addressForResponsibilityCheck: Address = {
      zipCode: this.addressForm.get("zipCode")?.value,
      city: this.addressForm.get("city")?.value,
      street: this.streetControl.value,
      streetNumber: this.streetNumberControl.value,
    };

    this.#responsibilityCheckService
      .verifyAddress(
        addressForResponsibilityCheck,
        this.selectedModule,
        this.turnstileCaptchaToken(),
      )
      .pipe(
        takeUntil(this.onDestroy$),
        map((response) => response.responsible),
        catchError(() => of(true)),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: (responsible) => {
          this.#appStateService.updateOtherData({
            responsibilityCheckFail: !responsible,
          });
          this.#routeService.navigateToNextStep();
        },
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public previous(): void {
    this.#routeService.navigateToPreviousStep();
  }
}
