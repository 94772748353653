<app-summary-section [stepRoute]="route" heading="OTHER_2_DETAILS.HEADING">
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        'OTHER_2_DETAILS.CHANGE_SYSTEM.' + otherDetails.changeSystem | translate
      "
      label="OTHER_2_DETAILS.CHANGE_SYSTEM"
    />
    @if (otherDetails.changeSystem === CHANGE_SYSTEM_TYPE.OTHER) {
      <app-summary-section-field
        [value]="otherDetails.otherChangeSystem"
        label="OTHER_2_DETAILS.OTHER_CHANGE_SYSTEM"
      />
    }
    <app-summary-section-field
      [value]="
        (otherDetails.controllable ? 'COMMON.YES' : 'COMMON.NO') | translate
      "
      label="OTHER_2_DETAILS.CONTROLLABLE"
    />
    <app-summary-section-field
      [value]="
        'OTHER_2_DETAILS.OTHER_CHANGE_TYPE.' + otherDetails.changeType
          | translate
      "
      label="OTHER_2_DETAILS.OTHER_CHANGE_TYPE"
    />
    @if (otherDetails.changeType === OTHER_CHANGE_TYPE.POWER_INCREASE) {
      <app-summary-section-field
        [value]="otherDetails.power"
        label="OTHER_2_DETAILS.POWER"
      />
      <app-summary-section-field
        [value]="otherDetails.plannedPower"
        label="OTHER_2_DETAILS.PLANNED_POWER"
      />
    }
    <app-summary-section-field
      [value]="otherDetails.description"
      label="OTHER_2_DETAILS.DESCRIPTION.TITLE"
    />
  </div>
</app-summary-section>
