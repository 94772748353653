import { AfterViewInit, Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appRequiredSuffix]",
  standalone: true,
})
export class RequiredSuffixDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    // Add '*' to the end of the element
    const starElement = this.renderer.createText(" *");
    this.renderer.appendChild(this.el.nativeElement, starElement);
  }
}
