import { Component, inject } from "@angular/core";

import { RouteService } from "@app/services/route.service";

@Component({
  selector: "app-success",
  templateUrl: "./success.component.html",
  styleUrls: ["./success.component.scss"],
})
export class SuccessComponent {
  readonly #routeService: RouteService = inject(RouteService);

  public startNewRequest(): void {
    this.#routeService.navigateToModuleSelectionPage();
  }
}
