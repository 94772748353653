import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { distinctUntilChanged, filter, map, take } from "rxjs";

import {
  NETWORK_AND_SYSTEM_PROTECTION,
  PV_INSTALLATION_PLACE,
} from "@app/models/registration-form";
import { SharedModule } from "@app/modules/shared/shared.module";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

export enum GENERATION_UNIT_FROM {
  POWER_PER_MODULE = "powerPerModule",
  MODULE_COUNT = "moduleCount",
  INSTALLATION_PLACE = "installationPlace",
  POWER_FLOW_MONITORING = "powerFlowMonitoring",
  NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION = "networkAndSystemProtectionDetailsLocation",
  NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER = "networkAndSystemProtectionDetailsManufacturer",
  NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE = "networkAndSystemProtectionDetailsType",
  POWER_FLOW_MONITORING_MANUFACTURER = "powerFlowMonitoringManufacturer",
  POWER_FLOW_MONITORING_TYPE = "powerFlowMonitoringType",
  POWER_FLOW_MONITORING_POWER = "powerFlowMonitoringPower",
}

@Component({
  selector: "app-pv-generation-unit-information",
  standalone: true,
  imports: [SharedModule, TranslateModule],
  templateUrl: "./pv-generation-unit-information.component.html",
})
export class PvGenerationUnitInformationComponent implements OnInit {
  readonly #fb = inject(FormBuilder);
  readonly #appStateService = inject(AppStateService);
  readonly #routeService = inject(RouteService);
  readonly #destroyRef: DestroyRef = inject(DestroyRef);

  public readonly GENERATION_UNIT_FORM = GENERATION_UNIT_FROM;
  public pvGenerationUnitForm!: FormGroup;
  public allTouched = false;
  public readonly installationPlaceOptions = Object.keys(PV_INSTALLATION_PLACE);
  public readonly networksSystemProtection = Object.keys(
    NETWORK_AND_SYSTEM_PROTECTION,
  );

  public ngOnInit(): void {
    this.#createPvGenerationUnitForm();
    this.#updateForm();
    this.#watchForm();
    this.#watchConditionalRequiredFields();
  }

  #createPvGenerationUnitForm(): void {
    this.pvGenerationUnitForm = this.#fb.group({
      [GENERATION_UNIT_FROM.POWER_PER_MODULE]: [null, Validators.required],
      [GENERATION_UNIT_FROM.MODULE_COUNT]: [null, Validators.required],
      [GENERATION_UNIT_FROM.INSTALLATION_PLACE]: [null, Validators.required],
      [GENERATION_UNIT_FROM.POWER_FLOW_MONITORING]: [null],
      [GENERATION_UNIT_FROM.POWER_FLOW_MONITORING_MANUFACTURER]: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      [GENERATION_UNIT_FROM.POWER_FLOW_MONITORING_TYPE]: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      [GENERATION_UNIT_FROM.POWER_FLOW_MONITORING_POWER]: [null],
      [GENERATION_UNIT_FROM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION]: [
        null,
      ],
      [GENERATION_UNIT_FROM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER]:
        [
          null,
          {
            updateOn: "blur",
            validators: [
              CustomValidators.trimValidator,
              CustomValidators.shortText,
            ],
          },
        ],
      [GENERATION_UNIT_FROM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE]: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.pvGenerationUnit),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((pvGenerationUnit) =>
        this.pvGenerationUnitForm.patchValue(pvGenerationUnit),
      );
  }

  #watchForm(): void {
    this.pvGenerationUnitForm.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef), distinctUntilChanged())
      .subscribe((pvGeneratingUnitFormValue) =>
        this.#appStateService.updateFormData({
          pvGenerationUnit: pvGeneratingUnitFormValue,
        }),
      );
  }

  #watchConditionalRequiredFields(): void {
    const powerFlowMonitoringManufacturer = this.pvGenerationUnitForm.get(
      GENERATION_UNIT_FROM.POWER_FLOW_MONITORING_MANUFACTURER,
    );
    const powerFlowMonitoringType = this.pvGenerationUnitForm.get(
      GENERATION_UNIT_FROM.POWER_FLOW_MONITORING_TYPE,
    );
    const networkAndSystemManufacturer = this.pvGenerationUnitForm.get(
      GENERATION_UNIT_FROM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER,
    );
    const networkAndSystemType = this.pvGenerationUnitForm.get(
      GENERATION_UNIT_FROM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE,
    );
    this.pvGenerationUnitForm
      .get(GENERATION_UNIT_FROM.POWER_FLOW_MONITORING)
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((powerFlowMonitoring) => {
        if (powerFlowMonitoring === true) {
          powerFlowMonitoringManufacturer?.enable();
          powerFlowMonitoringType?.enable();
        } else {
          powerFlowMonitoringManufacturer?.disable();
          powerFlowMonitoringType?.disable();
        }
      });

    this.pvGenerationUnitForm
      .get(GENERATION_UNIT_FROM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION)
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((networkAndSystem) => {
        if (networkAndSystem === NETWORK_AND_SYSTEM_PROTECTION.METER_LOCATION) {
          networkAndSystemManufacturer?.enable();
          networkAndSystemType?.enable();
        } else {
          networkAndSystemManufacturer?.disable();
          networkAndSystemType?.disable();
        }
      });
  }

  public previous(): void {
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.pvGenerationUnitForm.valid) {
      this.#routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.pvGenerationUnitForm.markAllAsTouched();
    }
  }
}
