export enum ROUTES {
  MODULE_SELECTION = "module-selection",
  MAIN_DOMAIN_PAGE = "main",
  ADDRESS = "address",
  RESPONSIBILITY_CHECK_FAIL = "responsibility-check-fail",
  DOCUMENTS_UPLOAD = "documents-upload",
  CONTACT = "contact",
  ELECTRICITY_PRODUCT_SELECTION = "electricity-product-selection",
  PV_FACILITY_INFORMATION = "pv-facility-information",
  PV_INVERTER_INFORMATION = "pv-inverter-information",
  PV_GENERATION_UNIT_INFORMATION = "pv-generation-unit-information",
  STORAGE_CUSTOMER_FACILITY = "storage-customer-facility",
  STORAGE_CONTROLLABLE_SYSTEM = "storage-controllable-system",
  STORAGE_DETAILS = "storage-details",
  CHARGING_DEVICE_SYSTEM_INFORMATION = "charging-device-system-information",
  CHARGING_DEVICE_CONTROLLABLE_SYSTEM = "charging-device-controllable-system",
  CHARGING_DEVICE_INFORMATION = "charging-device-information",
  BALCONY_PV_INFO = "balcony-pv-info",
  BALCONY_PV_EXIT = "balcony-pv-exit",
  BALCONY_PV_SUMMARY = "balcony-pv-summary",
  CHANGE_PV_DETAILS = "change-pv-details",
  GRID_CONNECTION_TYPE = "grid-connection-type",
  GRID_CONNECTION_PROPERTY_TYPE = "grid-connection-property-type",
  GRID_CONNECTION_CONSUMER = "grid-connection-consumer",
  GRID_CONNECTION_CHANGE = "grid-connection-change",
  POWER_CHANGE_DETAILS = "power-change-details",
  HEAT_PUMP_SYSTEM_INFORMATION = "heat-pump-system-information",
  HEAT_PUMP_CONTROLLABLE_DETAILS = "heat-pump-controllable-details",
  HEAT_PUMP_INFORMATION = "heat-pump-information",
  OTHER_2_TYPE = "other-2-type",
  OTHER_2_CUSTOMER_FACILITY = "other-2-customer-facility",
  OTHER_2_CONTROLLABLE_SYSTEM = "other-2-controllable-system",
  OTHER_2_SYSTEM_DETAILS = "other-2-system-details",
  OTHER_2_DETAILS = "other-2-details",
  SUMMARY = "summary",
  SUCCESS = "success",
  ERROR = "error",
  FLOW_SELECTION = "flow-selection",
  GAS_OPTIONS = "gas-options",
  GAS_PROPERTY_TYPE = "gas-property-type",
  GAS_POWER_REQUIREMENT = "gas-power-requirement",
  WATER_OPTIONS = "water-options",
  SEWAGE_OPTIONS = "sewage-options",
  SEWAGE_TYPE = "sewage-type",
  WATER_AREA = "water-area",
  WATER_PROPERTY_TYPE = "water-property-type",
  WATER_REQUIREMENTS = "water-requirements",
  CONSTRUCTION_WATER_REQUIREMENTS = "construction-water-requirements",
  HEAT_OPTIONS = "heat-options",
  HEAT_PROPERTY_TYPE = "heat-property-type",
  HEAT_REQUIREMENTS = "heat-requirements",
  COMMISSIONING_ELECTRICITY_OPTIONS = "commissioning-electricity-options",
  COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE = "commissioning-main-power-supply-type",
  COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS = "commissioning-main-power-supply-power-increase-details",
  COMMISSIONING_ELECTRICITY_PROPERTY_TYPE = "commissioning-electricity-property-type",
  COMMISSIONING_ELECTRICITY_INFORMATION = "commissioning-electricity-information",
  COMMISSIONING_ELECTRICITY_CONTACT = "commissioning-electricity-contact",
  COMMISSIONING_ELECTRICITY_SYSTEMS = "commissioning-electricity-systems",
  COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS = "commissioning-electricity-simple-measurement-details",
  COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS = "commissioning-electricity-measurement-details",
  CONSTRUCTION_ELECTRICITY = "construction-electricity",
}
