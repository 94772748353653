@if (simpleMeasurementDetails) {
  <app-summary-section
    [stepRoute]="simpleMeasurementDetailsRoute"
    heading="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.HEADING"
  >
    <app-summary-section-field
      [value]="simpleMeasurementDetails.meterNumbers.join(', ')"
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.METER_NUMBER"
    />
  </app-summary-section>
}
