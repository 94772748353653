<app-form-card
  titleIcon="electric_bolt"
  cardTitle="BALCONY_PV_INFO.HEADING"
  description="BALCONY_PV_INFO.DESCRIPTION"
  data-testid="balcony-pv-heading"
>
  <form [formGroup]="form">
    <div class="space"></div>
    <div>
      <h2 class="tooltip-heading-label">
        <span appRequiredSuffix>{{
          "BALCONY_PV_INFO.MODULE_POWER" | translate
        }}</span>
        <app-info-tooltip tooltipText="BALCONY_PV_INFO.MODULE_POWER.TOOLTIP" />
      </h2>
      <mat-radio-group
        color="primary"
        formControlName="modulePower"
        aria-labelledby="module-power-radio-group-label"
        class="input-container"
      >
        <mat-radio-button [value]="true" data-testId="module-power-yes">
          {{ "COMMON.YES" | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testId="module-power-no">
          {{ "COMMON.NO" | translate }}
        </mat-radio-button>
        <mat-error [hideError]="!allTouched" appErrorMessages="modulePower" />
      </mat-radio-group>
    </div>

    <div class="space"></div>

    <div>
      <h2 class="tooltip-heading-label">
        <span appRequiredSuffix>{{
          "BALCONY_PV_INFO.INVERTER_POWER" | translate
        }}</span>
        <app-info-tooltip
          tooltipText="BALCONY_PV_INFO.INVERTER_POWER.TOOLTIP"
        />
      </h2>
      <mat-radio-group
        color="primary"
        formControlName="inverterPower"
        aria-labelledby="inverter-power-radio-group-label"
        class="input-container"
      >
        <mat-radio-button [value]="true" data-testId="inverter-power-yes">
          {{ "COMMON.YES" | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testId="inverter-power-no">
          {{ "COMMON.NO" | translate }}
        </mat-radio-button>
        <mat-error [hideError]="!allTouched" appErrorMessages="inverterPower" />
      </mat-radio-group>
    </div>

    <div class="space"></div>

    <div>
      <h2 class="tooltip-heading-label">
        <span appRequiredSuffix>{{
          "BALCONY_PV_INFO.FREE_FEED_IN" | translate
        }}</span>
        <app-info-tooltip tooltipText="BALCONY_PV_INFO.FREE_FEED_IN.TOOLTIP" />
      </h2>
      <mat-radio-group
        color="primary"
        formControlName="freeFeedIn"
        aria-labelledby="free-feed-in-radio-group-label"
        class="input-container"
      >
        <mat-radio-button [value]="true" data-testId="free-feed-in-yes">
          {{ "COMMON.YES" | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testId="free-feed-in-no">
          {{ "COMMON.NO" | translate }}
        </mat-radio-button>
        <mat-error [hideError]="!allTouched" appErrorMessages="freeFeedIn" />
      </mat-radio-group>
    </div>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
