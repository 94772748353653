import { Component, Input } from "@angular/core";

import { CommissioningElectricitySimpleMeasurementDetails } from "@app/models/form-data.interface";
import { ROUTES } from "@app/models/routes.enum";

@Component({
  selector: "app-simple-measurement-details-summary",
  templateUrl: "./simple-measurement-details-summary.component.html",
})
export class SimpleMeasurementDetailsSummaryComponent {
  @Input({ required: true })
  public simpleMeasurementDetails!: CommissioningElectricitySimpleMeasurementDetails;
  public readonly simpleMeasurementDetailsRoute: ROUTES =
    ROUTES.COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS;
}
