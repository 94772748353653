import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { TranslatePipe } from "@ngx-translate/core";
import { filter, map, take } from "rxjs";

import { StorageCustomerFacility } from "@app/models/form-data.interface";
import { FORM_OF_FEED_IN_SALE } from "@app/models/registration-form";
import { SharedModule } from "@app/modules/shared/shared.module";
import { AppStateService } from "@app/services/app-state.service";
import { CustomDateAdapter } from "@app/services/custom-date-adapter.service";
import { DateHelperService } from "@app/services/date-helper.service";
import { RouteService } from "@app/services/route.service";
import { RequiredSuffixDirective } from "@app/shared/directives/required-suffix.directive";
import { NextButtonDisabledPipe } from "@app/shared/pipes/next-button-disabled.pipe";

@Component({
  selector: "app-storage-customer-facility",
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    RequiredSuffixDirective,
    SharedModule,
    TranslatePipe,
  ],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
  templateUrl: "./storage-customer-facility.component.html",
})
export class StorageCustomerFacilityComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dateHelperService = inject(DateHelperService);

  public form!: FormGroup;
  public allTouched = false;
  public readonly FORM_OF_FEED_IN_SALE = Object.values(FORM_OF_FEED_IN_SALE);
  public readonly errorMessageForInvalidDateFormat =
    this.#dateHelperService.getErrorMessageForInvalidDateFormat();
  public readonly dateForTomorrow = this.#dateHelperService.getFutureDate(1);

  public ngOnInit(): void {
    this.#createForm();
    this.#updateForm();
  }

  #createForm(): void {
    this.form = this.#formBuilder.group({
      controllable: [null, Validators.required],
      plannedCommissioningDate: [null],
      formOfFeedInSale: [null, Validators.required],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map((state) => state.formData.storageCustomerFacility),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((storageDetails) => this.form.patchValue(storageDetails));
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  #updateState(): void {
    const storageCustomerFacility: StorageCustomerFacility = this.form.value;
    this.#appStateService.updateFormData({ storageCustomerFacility });
  }
}
