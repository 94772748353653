<app-form-card
  titleIcon="electric_bolt"
  cardTitle="OTHER_2_CUSTOMER_FACILITY.HEADING"
  dataTestId="other-2-customer-facility-heading"
>
  <p>
    {{ "OTHER_2_CUSTOMER_FACILITY.DESCRIPTION" | translate }}
  </p>

  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span>{{
            "OTHER_2_CUSTOMER_FACILITY.PLANNED_COMMISSIONING_DATE.TITLE"
              | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_CUSTOMER_FACILITY.PLANNED_COMMISSIONING_DATE.TOOLTIP"
          />
        </h2>
        <mat-form-field
          class="full-width"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <mat-label>{{
            "OTHER_2_CUSTOMER_FACILITY.PLANNED_COMMISSIONING_DATE" | translate
          }}</mat-label>
          <input
            [min]="dateForTomorrow"
            [matDatepicker]="plannedCommissioningDate"
            matInput
            formControlName="plannedCommissioningDate"
            data-testid="planned-commissioning-date"
          />
          <mat-datepicker-toggle
            [for]="plannedCommissioningDate"
            matIconSuffix
          />
          <mat-datepicker #plannedCommissioningDate />
          @if (
            form | hasError: "plannedCommissioningDate" : "matDatepickerParse"
          ) {
            <mat-error>{{ errorMessageForInvalidDateFormat }} </mat-error>
          } @else if (
            form | hasError: "plannedCommissioningDate" : "matDatepickerMin"
          ) {
            <mat-error
              >{{ "VALIDATION.DATE_PICKER_MIN" | translate }}
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "OTHER_2_CUSTOMER_FACILITY.CONTROLLABLE.TITLE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_CUSTOMER_FACILITY.CONTROLLABLE.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="controllable"
          aria-labelledby="controllable-radio-group-label"
          class="input-container"
        >
          <mat-radio-button [value]="true" data-testId="controllable-yes">
            {{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" data-testId="controllable-no">
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="controllable"
          />
        </mat-radio-group>
      </div>
    </div>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
