<app-form-card
  titleIcon="electric_bolt"
  cardTitle="BALCONY_PV_SUMMARY.HEADING"
  data-testid="balcony-pv-summary-heading"
>
  <p class="bold">{{ "BALCONY_PV_SUMMARY.DESCRIPTION" | translate }}</p>
  <div class="space"></div>
  <p>{{ "BALCONY_PV_SUMMARY.DESCRIPTION_2" | translate }}</p>
  <app-form-navigation
    (next)="next()"
    [previousConfig]="{ visible: false }"
    [nextConfig]="{
      label: 'BALCONY_PV_SUMMARY.NEXT_BUTTON',
    }"
    slot="navigation"
  />
</app-form-card>
