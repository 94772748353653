import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";

import { AppModule } from "@app/app.module";
import { environment } from "@environments/environment";

Sentry.init({
  environment: environment.environment,
  dsn: "https://28889133d1dc4e4742306593b2f8776d@sentry.eon.com/200",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"],
});

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
