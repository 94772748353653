import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import {
  BUILDING_STRUCTURE,
  BUILDING_TYPE,
} from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";

interface PropertyDetails {
  icon: string;
  label: string;
  type: BUILDING_TYPE;
}

@Component({
  selector: "app-heat-property-type",
  templateUrl: "./heat-property-type.component.html",
})
export class HeatPropertyTypeComponent implements OnInit, OnDestroy {
  public readonly propertyDetailsList: PropertyDetails[] = [
    {
      icon: "home",
      label: "SHARED.BUILDING_TYPES.HOUSE",
      type: BUILDING_TYPE.HOUSE,
    },
    {
      icon: "other_houses",
      label: "SHARED.BUILDING_TYPES.MULTI_FAMILY_HOUSE",
      type: BUILDING_TYPE.MULTI_FAMILY_HOUSE,
    },
    {
      icon: "apartment",
      label: "SHARED.BUILDING_TYPES.INDUSTRY",
      type: BUILDING_TYPE.INDUSTRY,
    },
    {
      icon: "garage_home",
      label: "SHARED.BUILDING_TYPES.MIXED",
      type: BUILDING_TYPE.MIXED,
    },
  ];
  public buildingStructures = Object.keys(BUILDING_STRUCTURE);
  public selectedPropertyType?: BUILDING_TYPE;
  public allTouched = false;
  public propertyDetailsForm!: FormGroup;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createPropertyTypeForm();
    this.updateForm();
    this.watchForm();
  }

  private createPropertyTypeForm(): void {
    this.propertyDetailsForm = this.formBuilder.group({
      buildingType: [null, Validators.required],
      buildingStructure: [null, Validators.required],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.heatBuildingDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((heatBuildingDetails) => {
        this.propertyDetailsForm.patchValue(heatBuildingDetails);
        this.selectedPropertyType = heatBuildingDetails.buildingType;
      });
  }

  private watchForm(): void {
    this.propertyDetailsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((heatBuildingDetails) =>
        this.appStateService.updateFormData({
          heatBuildingDetails,
        }),
      );
  }

  public selectPropertyType(selectedOption: BUILDING_TYPE): void {
    this.propertyDetailsForm.get("buildingType")?.setValue(selectedOption);
    this.selectedPropertyType = selectedOption;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.propertyDetailsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.propertyDetailsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
