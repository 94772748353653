<app-form-card
  titleIcon="category"
  cardTitle="FLOW_SELECTION.HEADING"
  dataTestId="flow-selection-page"
>
  <h2>{{ "FLOW_SELECTION.SUBHEADING" | translate }}</h2>
  <form [formGroup]="flowSelectionForm">
    <app-selection-group>
      @for (flowDetails of flowDetailsList; track flowDetails) {
        @if (flowDetails.enabled) {
          <app-selection-card
            (click)="selectFlow(flowDetails.name)"
            [svgIcon]="flowDetails.svgIcon"
            [icon]="flowDetails.icon"
            [label]="flowDetails.label"
            [selected]="selectedFlow === flowDetails.name"
            [attr.data-testid]="flowDetails.name"
            class="selection-card"
          />
        }
      }
      @if (allTouched && flowSelectionForm.get("selectedFlow")?.invalid) {
        <mat-error
          >{{ "VALIDATION.REQUIRED_SELECTION" | translate }}
        </mat-error>
      }
    </app-selection-group>

    @if (selectedFlow) {
      <div class="space"></div>
      @switch (selectedFlow) {
        @case (flow.REGISTRATION) {
          <app-hint>
            <span>{{ "FLOW_SELECTION.REGISTRATION_INFO" | translate }}</span>
          </app-hint>
          <app-hint [warning]="true" [showOutline]="false">
            <span>{{ "FLOW_SELECTION.REGISTRATION_WARNING" | translate }}</span>
          </app-hint>
        }
        @case (flow.COMMISSIONING) {
          <app-hint>
            <span>{{ "FLOW_SELECTION.COMMISSIONING_INFO" | translate }}</span>
          </app-hint>
          <app-hint [warning]="true" [showOutline]="false">
            <span>{{
              "FLOW_SELECTION.COMMISSIONING_WARNING" | translate
            }}</span>
          </app-hint>
        }
      }
    }
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: flowSelectionForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
