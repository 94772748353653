import { NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { WebpackTranslateLoader } from "@app/app-initialization/webpack-translation-loader";
import { RequiredSuffixDirective } from "@app/shared/directives/required-suffix.directive";
import { ExceedsLimitPipe } from "@app/shared/pipes/exceeds-limit.pipe";
import { IsModuleOptionEnabledPipe } from "@app/shared/pipes/is-module-option-enabled.pipe";

import { ConstructionElectricityComponent } from "./construction-electricity/construction-electricity.component";
import { GridConnectionChangeComponent } from "./grid-connection/grid-connection-change/grid-connection-change.component";
import { GridConnectionConsumerComponent } from "./grid-connection/grid-connection-consumer/grid-connection-consumer.component";
import { GridConnectionPropertyTypeComponent } from "./grid-connection/grid-connection-property-type/grid-connection-property-type.component";
import { GridConnectionTypeComponent } from "./grid-connection/grid-connection-type/grid-connection-type.component";
import { PowerChangeDetailsComponent } from "./grid-connection/power-change-details/power-change-details.component";
import { SelectedPvComponent } from "./product-selection/components/selected-pv/selected-pv.component";
import { ProductSelectionComponent } from "./product-selection/product-selection.component";
import { PvFacilityInformationComponent } from "./pv-system/pv-facility-information/pv-facility-information.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    GridConnectionChangeComponent,
    GridConnectionConsumerComponent,
    GridConnectionPropertyTypeComponent,
    GridConnectionTypeComponent,
    PowerChangeDetailsComponent,
    ProductSelectionComponent,
    PvFacilityInformationComponent,
    ConstructionElectricityComponent,
  ],
  imports: [
    SharedModule,
    SelectedPvComponent,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    RequiredSuffixDirective,
    IsModuleOptionEnabledPipe,
    ExceedsLimitPipe,
  ],
})
export class ElectricityModule {}
