<section>
  <a
    [routerLink]="stepRoute ? '/' + stepRoute : null"
    [skipLocationChange]="true"
    class="section-title"
  >
    <h1>{{ heading | translate }}</h1>

    <mat-icon
      [fontIcon]="icon"
      aria-hidden="false"
      aria-label="navigate to step"
      class="heading-icon material-symbols-outlined"
    />
  </a>
  <div>
    <ng-content />
  </div>
  @if (showSeparator) {
    <hr />
  }
</section>
