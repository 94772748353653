import { forkJoin, Observable } from "rxjs";

import { IconInitializerService } from "@app/services/icon-initializer.service";

import { WhiteLabelService } from "../services/white-label.service";

export const initializeApp = (
  iconInitializerService: IconInitializerService,
  whiteLabelService: WhiteLabelService,
) => {
  return (): Observable<[void, void]> =>
    forkJoin([
      iconInitializerService.configured$,
      whiteLabelService.configured$,
    ]);
};
