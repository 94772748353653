import { Component, Input } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";

import { ChargingDeviceDetailsDto } from "@app/models/registration-form";
import { ROUTES } from "@app/models/routes.enum";
import { SharedModule } from "@app/modules/shared/shared.module";

@Component({
  selector: "app-charging-device-information-summary",
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: "./charging-device-information-summary.component.html",
})
export class ChargingDeviceInformationSummaryComponent {
  @Input({ required: true })
  public chargingDeviceInformation!: ChargingDeviceDetailsDto;
  public readonly route = ROUTES.CHARGING_DEVICE_INFORMATION;
}
