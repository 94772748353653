<span mat-dialog-title class="dialog-title">{{
  dialogData.heading | translate
}}</span>

<div mat-dialog-content>
  {{ dialogData.message | translate: dialogData.translateParameters }}
</div>

<div mat-dialog-actions>
  <button
    (click)="reject()"
    type="button"
    color="primary"
    mat-button
    class="reject-button"
  >
    {{ dialogData.rejectLabel | translate }}
  </button>
  <button
    (click)="accept()"
    type="button"
    color="primary"
    mat-raised-button
    class="accept-button"
  >
    {{ dialogData.acceptLabel | translate }}
  </button>
</div>
