import { Component, inject, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { getLanguageLocaleCulture } from "@app/constants/language";
import { FormDataObject } from "@app/models/form-data.interface";
import { HEAT_OPTION } from "@app/models/registration-form";
import { ROUTES } from "@app/models/routes.enum";

@Component({
  selector: "app-heat-summary",
  templateUrl: "./heat-summary.component.html",
  styleUrls: ["./heat-summary.component.scss"],
})
export class HeatSummaryComponent {
  @Input({ required: true }) formData!: Partial<FormDataObject>;

  public routes = ROUTES;
  public heatOptions = HEAT_OPTION;
  readonly #translateService = inject(TranslateService);
  public currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang,
  );
}
