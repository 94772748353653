import { AbstractControl, FormGroup, Validators } from "@angular/forms";

export class FormUtils {
  public static setValidator(
    formControl: AbstractControl | null,
    validationEnabled: boolean,
    validators = [Validators.required],
  ): void {
    if (formControl instanceof FormGroup) {
      const formGroup = formControl as FormGroup;

      if (validationEnabled) {
        formGroup.enable();
      } else {
        formGroup.disable();
      }
    }

    if (validationEnabled) {
      formControl?.setValidators(validators);
    } else {
      formControl?.setValidators([]);
    }

    formControl?.updateValueAndValidity();
  }
}
