<div [formGroup]="formGroupControl" class="multi-selection-card-container">
  <app-selection-card
    (click)="cardClicked()"
    [icon]="icon"
    [label]="label"
    [selected]="formGroupControl | formLookup: controlName"
    class="selection-card"
  />
  <mat-checkbox [formControlName]="controlName" color="primary" />
</div>
