import { HttpHeaders } from "@angular/common/http";

export class HeaderUtils {
  public static toHttpHeaders(key: string, value: string | null): HttpHeaders {
    let headers = new HttpHeaders();
    if (value) {
      headers = headers.set(key, value);
    }
    return headers;
  }
}
