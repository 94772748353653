<app-summary-section
  [stepRoute]="contactRoute"
  heading="CONTACT.CUSTOMER_DETAILS"
>
  <p class="section-heading">
    {{ "CONTACT.CONTACT_DETAILS" | translate }}
  </p>

  <p class="section-fields-container">
    <app-contact-details [contactDetails]="contact.contactDetails" />
  </p>

  <ng-container>
    <p class="section-heading">
      {{ "CONTACT.REQUESTER_ADDRESS" | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="
          (contact.differentAddress ? 'COMMON.NO' : 'COMMON.YES') | translate
        "
        label="CONTACT.CONNECTION_ADDRESS_DESCRIPTION"
      />
      @if (contact.differentAddress && contact.differentRequesterAddress) {
        <app-address [address]="contact.differentRequesterAddress" />
      }
    </div>
  </ng-container>

  <ng-container>
    <p class="section-heading">
      {{ "CONTACT.CONTACT_DETAILS" | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="contact.gridOperator"
        label="COMMISSIONING_ELECTRICITY.CONTACT.GRID_OPERATOR"
      />
      <app-summary-section-field
        [value]="contact.idNumber"
        label="COMMISSIONING_ELECTRICITY.CONTACT.ID_NUMBER"
      />
      <app-summary-section-field
        [value]="contact.electricianName"
        label="COMMISSIONING_ELECTRICITY.CONTACT.ELECTRICIAN_NAME"
      />
      <app-summary-section-field
        [value]="contact.electricianEmail"
        label="COMMISSIONING_ELECTRICITY.CONTACT.ELECTRICIAN_EMAIL"
      />
      <app-summary-section-field
        [value]="contact.phone"
        label="COMMISSIONING_ELECTRICITY.CONTACT.PHONE"
      />
      <app-summary-section-field
        [value]="contact.location"
        label="COMMISSIONING_ELECTRICITY.CONTACT.LOCATION"
      />
      <app-summary-section-field
        [value]="
          (contact.date | date: 'shortDate' : '' : currentLanguageCulture) ||
          '-'
        "
        label="COMMISSIONING_ELECTRICITY.CONTACT.DATE"
        id="commissioning-electricity-contact-date"
      />
    </div>
  </ng-container>
</app-summary-section>
