import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { TranslatePipe } from "@ngx-translate/core";
import { distinctUntilChanged, filter, map, take } from "rxjs";

import {
  HEAT_PUMP_SYSTEM_TYPE,
  LOAD_MANAGEMENT,
} from "@app/models/registration-form";
import { SharedModule } from "@app/modules/shared/shared.module";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { RequiredSuffixDirective } from "@app/shared/directives/required-suffix.directive";
import { NextButtonDisabledPipe } from "@app/shared/pipes/next-button-disabled.pipe";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  selector: "app-heat-pump-information",
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    SharedModule,
    TranslatePipe,
    RequiredSuffixDirective,
  ],
  templateUrl: "./heat-pump-information.component.html",
})
export class HeatPumpInformationComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);

  public form!: FormGroup;
  public allTouched = false;
  public readonly heatPumpSystemTypes = Object.keys(HEAT_PUMP_SYSTEM_TYPE);
  public readonly loadManagementOptions = Object.values(LOAD_MANAGEMENT);

  public ngOnInit(): void {
    this.#createForm();
    this.#updateForm();
    this.#watchForm();
  }

  #createForm(): void {
    this.form = this.#formBuilder.group({
      systemManufacturer: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      systemModel: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      systemCount: [null, Validators.required],
      systemType: [null, Validators.required],
      power: [null, Validators.required],
      additionalHeatingPower: [null, Validators.required],
      maxStartingCurrent: [null],
      maxGridPower: [null, Validators.required],
      separateMeter: [null, Validators.required],
      meterAvailable: [null, Validators.required],
      meterNumber: [
        null,
        {
          updateOn: "blur",
          validators: [
            Validators.required,
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      operationalPurposeOrCriticalInfrastructure: [null, Validators.required],
      loadManagement: [null, Validators.required],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.heatPumpInformation),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((heatPumpInformation) => {
        this.form.patchValue(heatPumpInformation);
        this.#configureMeterNumberControl();
      });
  }

  #watchForm(): void {
    this.form
      .get("meterAvailable")!
      .valueChanges.pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(() => this.#configureMeterNumberControl());
  }

  #configureMeterNumberControl(): void {
    if (this.form.get("meterAvailable")!.value) {
      this.form.get("meterNumber")!.enable();
    } else {
      this.form.get("meterNumber")!.disable();
    }
  }

  #updateState(): void {
    this.#appStateService.updateFormData({
      heatPumpInformation: this.form.value,
    });
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }
}
