import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { filter, map, Subject, take, takeUntil } from "rxjs";

import { GRID_CONNECTION_CONSUMER_TYPE } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  selector: "app-grid-connection-consumer",
  templateUrl: "./grid-connection-consumer.component.html",
  styleUrls: ["./grid-connection-consumer.component.scss"],
})
export class GridConnectionConsumerComponent implements OnInit, OnDestroy {
  public consumerType = GRID_CONNECTION_CONSUMER_TYPE;
  public consumerForm!: FormGroup;
  public consumerTypeControl!: FormGroup;
  public consumerTypeHeatPumpControl!: FormControl<boolean>;
  public consumerTypeWaterHeaterControl!: FormControl<boolean>;
  public consumerTypeWallboxControl!: FormControl<boolean>;
  public allTouched = false;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createGridConnectionConsumerForm();
    this.updateForm();
    this.watchForm();
  }

  private createGridConnectionConsumerForm(): void {
    this.consumerForm = this.formBuilder.group({
      type: this.formBuilder.group({
        [this.consumerType.HEAT_PUMP]: false,
        [this.consumerType.FLOW_HEATER]: false,
        [this.consumerType.WALLBOX]: false,
      }),
      additionalConsumers: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      systemPower: [null, [Validators.required, Validators.min(0)]],
      connectionFuse: [null, [Validators.required, Validators.min(0)]],
      maxConcurrentPower: [null, [Validators.required, Validators.min(0)]],
    });

    this.consumerTypeControl = this.consumerForm.get("type") as FormGroup;
    this.consumerTypeHeatPumpControl = this.consumerTypeControl.get(
      this.consumerType.HEAT_PUMP,
    ) as FormControl;
    this.consumerTypeWaterHeaterControl = this.consumerTypeControl.get(
      this.consumerType.FLOW_HEATER,
    ) as FormControl;
    this.consumerTypeWallboxControl = this.consumerTypeControl.get(
      this.consumerType.WALLBOX,
    ) as FormControl;
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.gridConnectionConsumer),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((gridConnectionConsumer) =>
        this.consumerForm.patchValue(gridConnectionConsumer),
      );
  }

  private watchForm(): void {
    this.consumerForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((gridConnectionConsumer) =>
        this.appStateService.updateFormData({ gridConnectionConsumer }),
      );
  }

  public toggleSelectionCardValue(formControlName: string): void {
    this.consumerTypeControl
      .get(formControlName)
      ?.setValue(!this.consumerTypeControl.get(formControlName)?.value);
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.consumerForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.consumerForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
