import { Component, Input } from "@angular/core";

import { ROUTES } from "@app/models/routes.enum";

@Component({
  selector: "app-summary-section",
  templateUrl: "./summary-section.component.html",
  styleUrls: ["./summary-section.component.scss"],
})
export class SummarySectionComponent {
  @Input() public heading!: string;
  @Input() public stepRoute?: ROUTES;
  @Input() public showSeparator = true;
  @Input() public icon = "edit";
}
