import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, filter, map, take } from "rxjs";

import { GRID_FEED_IN, OPERATING_MODE } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

import { SharedModule } from "../../../shared/shared.module";

export enum INVERTER_FORM {
  OPERATING_MODE = "operatingMode",
  ISOLATED_OPERATIONAL = "isolatedOperational",
  SYSTEM_MANUFACTURER = "systemManufacturer",
  SYSTEM_TYPE = "systemType",
  SYSTEM_COUNT = "systemCount",
  MAX_APPARENT_POWER = "maxApparentPower",
  MAX_CONTROLLABLE_POWER = "maxControllablePower",
  GRID_FEED_IN = "gridFeedIn",
  METER_AVAILABLE = "meterAvailable",
  METER_NUMBER = "meterNumber",
}
@Component({
  selector: "app-pv-inverter-information",
  standalone: true,
  imports: [SharedModule, TranslateModule],
  templateUrl: "./pv-inverter-information.component.html",
  styleUrls: ["./pv-inverter-information.component.scss"],
})
export class PvInverterInformationComponent implements OnInit {
  readonly #fb = inject(FormBuilder);
  readonly #appStateService = inject(AppStateService);
  readonly #routeService = inject(RouteService);
  readonly #destroyRef: DestroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);

  public pvInverterForm!: FormGroup;
  public readonly operatorModeOptions = Object.keys(OPERATING_MODE);
  public readonly gridFeedInOptions = Object.keys(GRID_FEED_IN);
  public readonly INVERTER_FORM = INVERTER_FORM;
  public allTouched = false;
  public local = this.#translateService.currentLang;

  public maxApparentPowerSum: WritableSignal<number> = signal(0);
  public maxControllablePowerSum: WritableSignal<number> = signal(0);

  public ngOnInit(): void {
    this.#createPvInverterInformationForm();
    this.pvInverterForm.get(INVERTER_FORM.METER_NUMBER)?.disable();

    this.#updateForm();
    this.#watchForm();
  }

  #createPvInverterInformationForm(): void {
    this.pvInverterForm = this.#fb.group({
      [INVERTER_FORM.OPERATING_MODE]: [null, Validators.required],
      [INVERTER_FORM.ISOLATED_OPERATIONAL]: [null],
      [INVERTER_FORM.SYSTEM_MANUFACTURER]: [
        null,
        {
          updateOn: "blur",
          validators: [
            Validators.required,
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      [INVERTER_FORM.SYSTEM_TYPE]: [
        null,
        {
          updateOn: "blur",
          validators: [
            Validators.required,
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      [INVERTER_FORM.SYSTEM_COUNT]: [null, Validators.required],
      [INVERTER_FORM.MAX_APPARENT_POWER]: [null, Validators.required],
      [INVERTER_FORM.MAX_CONTROLLABLE_POWER]: [null, Validators.required],
      [INVERTER_FORM.GRID_FEED_IN]: [null],
      [INVERTER_FORM.METER_AVAILABLE]: [null, Validators.required],
      [INVERTER_FORM.METER_NUMBER]: [
        null,
        {
          updateOn: "blur",
          validators: [
            Validators.required,
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
    });
    this.#watchConditionalRequiredFields();
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.pvInverter),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((pvInverterInformation) => {
        this.pvInverterForm.patchValue(pvInverterInformation);
        this.maxApparentPowerSum.set(pvInverterInformation.maxApparentPowerSum);
        this.maxControllablePowerSum.set(
          pvInverterInformation.maxControllablePowerSum,
        );
      });
  }

  #watchForm(): void {
    this.pvInverterForm.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef), distinctUntilChanged())
      .subscribe((pvInverterFormValue) => {
        this.maxApparentPowerSum.set(
          this.#calcPowerSum(
            this.pvInverterForm.get(INVERTER_FORM.MAX_APPARENT_POWER)?.value,
            this.pvInverterForm.get(INVERTER_FORM.SYSTEM_COUNT)?.value,
          ),
        );
        this.maxControllablePowerSum.set(
          this.#calcPowerSum(
            this.pvInverterForm.get(INVERTER_FORM.MAX_CONTROLLABLE_POWER)
              ?.value,
            this.pvInverterForm.get(INVERTER_FORM.SYSTEM_COUNT)?.value,
          ),
        );
        this.#appStateService.updateFormData({
          pvInverter: {
            ...pvInverterFormValue,
            maxApparentPowerSum: this.maxApparentPowerSum(),
            maxControllablePowerSum: this.maxControllablePowerSum(),
          },
        });
      });
  }

  #watchConditionalRequiredFields(): void {
    this.pvInverterForm
      .get(INVERTER_FORM.METER_AVAILABLE)
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((meterAvailableForm) =>
        this.#updateValidators(meterAvailableForm),
      );
  }

  #updateValidators(meterAvailableValue: boolean): void {
    if (meterAvailableValue) {
      this.pvInverterForm.get(INVERTER_FORM.METER_NUMBER)?.enable();
    } else {
      this.pvInverterForm.get(INVERTER_FORM.METER_NUMBER)?.disable();
    }
  }

  #calcPowerSum(multiplicand = 0.0, multiplier = 0.0): number {
    return multiplicand * multiplier;
  }

  public previous(): void {
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.pvInverterForm.valid) {
      this.#routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.pvInverterForm.markAllAsTouched();
    }
  }
}
