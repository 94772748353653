<app-summary-section-field
  [value]="contactDetails.firstName"
  label="CONTACT.FIRST_NAME"
/>

<app-summary-section-field
  [value]="contactDetails.lastName"
  label="CONTACT.LAST_NAME"
/>

<app-summary-section-field
  [value]="contactDetails.email"
  label="CONTACT.EMAIL"
/>

<app-summary-section-field
  [value]="contactDetails.phone"
  label="CONTACT.PHONE"
/>
