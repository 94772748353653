import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { combineLatest, filter, map, Subject, take, takeUntil } from "rxjs";

import { DocumentDetails } from "@app/constants/document-mappings";
import { DOCUMENT_TYPE, ON_BEHALF_TYPE } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";
import { FormUtils } from "@app/shared/validators/form-utils";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public contactForm!: FormGroup;
  public differentContactForm!: FormGroup;
  public differentAddressForm!: FormGroup;
  public onBehalfTypes = Object.keys(ON_BEHALF_TYPE);
  public allTouched = false;
  public powerOfAttorneyDocument: DocumentDetails = {
    typeName: DOCUMENT_TYPE.POWER_OF_ATTORNEY,
  };
  public powerOfAttorneyDocumentControl!: FormControl;
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.setupValidation();
    this.initFormData();
    this.watchForm();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private createForm(): void {
    this.contactForm = this.formBuilder.group(
      {
        firstName: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        lastName: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        email: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.email,
          ],
        ],
        phone: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.phoneNumberValidator(),
          ],
        ],
      },
      {
        updateOn: "blur",
        validators: [Validators.required],
      },
    );

    this.differentContactForm = this.formBuilder.group(
      {
        firstName: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        lastName: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        email: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.email,
          ],
        ],
        phone: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.phoneNumberValidator(),
          ],
        ],
      },
      {
        updateOn: "blur",
      },
    );
    this.differentAddressForm = this.formBuilder.group(
      {
        zipCode: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.germanZip,
          ],
        ],
        city: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        street: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        streetNumber: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
      },
      {
        updateOn: "blur",
      },
    );
    this.form = this.formBuilder.group({
      onBehalf: [null, [Validators.required]],
      onBehalfType: [null, []],
      powerOfAttorney: [false, Validators.requiredTrue],
      powerOfAttorneyDocument: this.formBuilder.control(null),
      contactForm: this.contactForm,
      differentContactForm: this.differentContactForm,
      differentAddress: [null, [Validators.required]],
      differentAddressForm: this.differentAddressForm,
    });
    this.powerOfAttorneyDocumentControl = this.form.get(
      "powerOfAttorneyDocument",
    ) as FormControl;
  }

  private setupValidation(): void {
    combineLatest([
      this.form.get("onBehalf")!.valueChanges,
      this.form.get("differentAddress")!.valueChanges,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([onBehalf, differentAddress]) => {
        if (onBehalf) {
          FormUtils.setValidator(this.form.get("powerOfAttorney"), true, [
            Validators.requiredTrue,
          ]);
          FormUtils.setValidator(this.form.get("onBehalfType"), true);
          FormUtils.setValidator(this.differentContactForm, true);
        } else {
          FormUtils.setValidator(this.form.get("powerOfAttorney"), false);
          FormUtils.setValidator(this.form.get("onBehalfType"), false);
          FormUtils.setValidator(this.differentContactForm, false);
        }
        if (differentAddress) {
          FormUtils.setValidator(this.differentAddressForm, true);
        } else {
          FormUtils.setValidator(this.differentAddressForm, false);
        }
      });
  }

  private initFormData(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.contact),
        filter(Boolean),
        take(1),
      )
      .subscribe((contactData) => this.form.patchValue(contactData));
  }

  private watchForm(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((contact) =>
        this.appStateService.updateFormData({
          contact,
        }),
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.form.markAllAsTouched();
    }
  }
}
