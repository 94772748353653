<app-form-card
  titleIcon="electric_bolt"
  cardTitle="OTHER_2_DETAILS.HEADING"
  dataTestId="other-2-details-heading"
>
  <p>
    {{ "OTHER_2_DETAILS.DESCRIPTION" | translate }}
  </p>
  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2>
          <span appRequiredSuffix>{{
            "OTHER_2_DETAILS.CHANGE_SYSTEM" | translate
          }}</span>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="changeSystem"
          aria-labelledby="change-system-radio-group-label"
          class="input-container"
        >
          @for (changeSystem of CHANGE_SYSTEM_TYPE; track changeSystem) {
            <mat-radio-button
              [value]="changeSystem"
              [attr.data-testId]="'change-system-' + changeSystem"
            >
              {{ "OTHER_2_DETAILS.CHANGE_SYSTEM." + changeSystem | translate }}
            </mat-radio-button>
          }
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="changeSystem"
          />
        </mat-radio-group>
        @if (form.get("changeSystem")?.value === CHANGE_SYSTEM_TYPE_OTHER) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label
                >{{ "OTHER_2_DETAILS.OTHER_CHANGE_SYSTEM" | translate }}
              </mat-label>
              <input
                matInput
                name="otherChangeSystem"
                formControlName="otherChangeSystem"
                data-testId="other-change-system"
              />
              <mat-error appErrorMessages="otherChangeSystem" />
            </mat-form-field>
          </div>
        }
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "OTHER_2_DETAILS.CONTROLLABLE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_DETAILS.CONTROLLABLE.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="controllable"
          aria-labelledby="controllable-radio-group-label"
          class="input-container"
        >
          <mat-radio-button [value]="true" data-testId="controllable-yes">
            {{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" data-testId="controllable-no">
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="controllable"
          />
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "OTHER_2_DETAILS.OTHER_CHANGE_TYPE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_DETAILS.OTHER_CHANGE_TYPE.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="changeType"
          aria-labelledby="change-type-radio-group-label"
          class="input-container"
        >
          @for (changeType of OTHER_CHANGE_TYPE; track changeType) {
            <mat-radio-button
              [value]="changeType"
              [attr.data-testId]="'change-type-' + changeType"
            >
              {{
                "OTHER_2_DETAILS.OTHER_CHANGE_TYPE." + changeType | translate
              }}
            </mat-radio-button>
          }
          <mat-error [hideError]="!allTouched" appErrorMessages="changeType" />
        </mat-radio-group>
        @if (
          form.get("changeType")?.value === OTHER_CHANGE_TYPE_POWER_INCREASE
        ) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label>{{ "OTHER_2_DETAILS.POWER" | translate }} </mat-label>
              <input
                matInput
                type="number"
                min="0"
                name="power"
                formControlName="power"
                data-testId="power"
              />
              <mat-error appErrorMessages="power" />
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="OTHER_2_DETAILS.POWER.TOOLTIP"
            />
          </div>
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label
                >{{ "OTHER_2_DETAILS.PLANNED_POWER" | translate }}
              </mat-label>
              <input
                matInput
                type="number"
                min="0"
                name="plannedPower"
                formControlName="plannedPower"
                data-testId="planned-power"
              />
              <mat-error appErrorMessages="plannedPower" />
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="OTHER_2_DETAILS.PLANNED_POWER.TOOLTIP"
            />
          </div>
        }
      </div>

      <div class="two-column-layout-item">
        <h2>
          <span appRequiredSuffix>{{
            "OTHER_2_DETAILS.DESCRIPTION.TITLE" | translate
          }}</span>
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="textarea-field">
            <mat-label>{{
              "OTHER_2_DETAILS.DESCRIPTION.PLACEHOLDER" | translate
            }}</mat-label>
            <textarea
              #description
              [maxlength]="maxLength"
              matInput
              cols="50"
              rows="6"
              formControlName="description"
              data-testId="description"
            ></textarea>
            <mat-hint align="end"
              >{{ description.value.length }} / {{ maxLength }}</mat-hint
            >
            <mat-error appErrorMessages="description" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
