import { Component, Input } from "@angular/core";

import {
  MainPowerSupplyPowerIncrease,
  MAIN_POWER_SUPPLY_TYPE,
} from "@app/models/commissioning-mapped-data.interface";
import {
  CommissioningElectricityBuildingDetails,
  CommissioningElectricityInformation,
} from "@app/models/form-data.interface";
import { ROUTES } from "@app/models/routes.enum";

@Component({
  selector: "app-main-power-supply-summary",
  templateUrl: "./main-power-supply-summary.component.html",
})
export class MainPowerSupplySummaryComponent {
  @Input({ required: true })
  public mainPowerSupplyType!: MAIN_POWER_SUPPLY_TYPE;
  @Input()
  public buildingDetails?: CommissioningElectricityBuildingDetails;
  @Input()
  public electricityInformation?: CommissioningElectricityInformation;
  @Input()
  public powerIncreaseDetails?: MainPowerSupplyPowerIncrease;
  public type = MAIN_POWER_SUPPLY_TYPE;
  protected readonly routes = ROUTES;
}
