<app-summary-section
  [stepRoute]="route"
  heading="STORAGE_CONTROLLABLE_DETAILS.HEADING"
>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="controllableDetails.totalMinimumPower"
      label="STORAGE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER"
    />
    <app-summary-section-field
      [value]="
        'STORAGE_CONTROLLABLE_DETAILS.COMPLIANT.' +
          (controllableDetails.compliant ? 'YES' : 'NO') | translate
      "
      label="STORAGE_CONTROLLABLE_DETAILS.COMPLIANT.TITLE"
    />
    <app-summary-section-field
      [value]="
        'STORAGE_CONTROLLABLE_DETAILS.CONTROLLER.' +
          controllableDetails.controller | translate
      "
      label="STORAGE_CONTROLLABLE_DETAILS.CONTROLLER.TITLE"
    />
    @if (
      controllableDetails.controller ===
      CONTROLLER.COMPETITIVE_METERING_POINT_OPERATOR
    ) {
      <app-summary-section-field
        [value]="controllableDetails.controllerName"
        label="STORAGE_CONTROLLABLE_DETAILS.CONTROLLER_NAME"
      />
    }
    <app-summary-section-field
      [value]="
        networkAndSystemProtectionDetails?.location
          ? ('STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.' +
              networkAndSystemProtectionDetails!.location | translate)
          : '-'
      "
      label="STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.TITLE"
    />
    @if (
      networkAndSystemProtectionDetails?.location ===
      NETWORK_AND_SYSTEM_PROTECTION.METER_LOCATION
    ) {
      <app-summary-section-field
        [value]="networkAndSystemProtectionDetails?.manufacturer"
        label="STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.MANUFACTURER"
      />
      <app-summary-section-field
        [value]="networkAndSystemProtectionDetails?.type"
        label="STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.TYPE"
      />
    }
    <app-summary-section-field
      [label]="
        'STORAGE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.TITLE'
          | translate
            : {
                date:
                  '01.01.2024'
                  | date: 'shortDate' : '' : currentLanguageCulture,
              }
      "
      [value]="
        'STORAGE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.' +
          controllableDetails.existingSystem | translate
      "
    />
    <app-summary-section-field
      [value]="
        'STORAGE_CONTROLLABLE_DETAILS.CONTROL_TYPE.' +
          controllableDetails.controlType | translate
      "
      label="STORAGE_CONTROLLABLE_DETAILS.CONTROL_TYPE.TITLE"
    />
    <app-summary-section-field
      [value]="
        'STORAGE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.' +
          controllableDetails.networkFeeModule | translate
      "
      label="STORAGE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.TITLE"
    />
  </div>
</app-summary-section>
