<app-summary-section
  [stepRoute]="electricitySystemsRoute"
  heading="COMMISSIONING_ELECTRICITY.SYSTEM.HEADING"
>
  <app-summary-section-field
    [value]="(meterChanges ? 'COMMON.YES' : 'COMMON.NO') | translate"
    label="COMMISSIONING_ELECTRICITY.SYSTEM.METER_CHANGES"
  />
  @if (electricitySystems) {
    @for (system of selectedSystems; track system) {
      <p class="section-heading">
        {{
          "COMMISSIONING_ELECTRICITY.SYSTEM.TYPES." + system.type | translate
        }}
      </p>
      <div class="section-fields-container">
        @if (system.systemPower) {
          <app-summary-section-field
            [value]="system.systemPower"
            label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_POWER"
          />
        }
        @if (system.systemCount) {
          <app-summary-section-field
            [value]="system.systemCount"
            label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_COUNT"
          />
        }
        @if (system.systemType) {
          <app-summary-section-field
            [value]="system.systemType"
            label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_TYPE"
          />
        }
        @if (system.notes) {
          <app-summary-section-field
            [value]="system.notes"
            label="COMMISSIONING_ELECTRICITY.SYSTEM.NOTES"
          />
        }
      </div>
    }
  }
</app-summary-section>

@if (meterChanges) {
  @if (complexMeasurementDetails) {
    <app-complex-measurement-details-summary
      [measurementDetails]="complexMeasurementDetails"
    />
  }
} @else {
  @if (simpleMeasurementDetails) {
    <app-simple-measurement-details-summary
      [simpleMeasurementDetails]="simpleMeasurementDetails"
    />
  }
}
