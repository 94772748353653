import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "app-loading-spinner",
  templateUrl: "./loading-spinner.component.html",
  styleUrls: ["./loading-spinner.component.scss"],
})
export class LoadingSpinnerComponent {
  @Input()
  @HostBinding("class.round-border")
  public roundBorder = true;
}
