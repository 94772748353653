<app-form-card
  titleIcon="electric_bolt"
  cardTitle="PV_INVERTER_INFORMATION.HEADING"
  description="PV_INVERTER_INFORMATION.DESCRIPTION"
  dataTestId="pv-inverter-heading"
>
  <div class="space"></div>

  <form [formGroup]="pvInverterForm">
    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>{{
            ("PV_INVERTER_INFORMATION.OPERATING_MODE" | translate) + "*"
          }}</label>
          <app-info-tooltip
            tooltipText="PV_INVERTER_INFORMATION.OPERATING_MODE_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="INVERTER_FORM.OPERATING_MODE"
          color="primary"
          aria-labelledby="pv-inverter-operating-mode-radio-group-label"
        >
          @for (option of operatorModeOptions; track option) {
            <mat-radio-button [value]="option" [attr.data-testid]="option">
              {{
                "PV_INVERTER_INFORMATION.OPERATING_MODE." + option | translate
              }}
            </mat-radio-button>
          }
        </mat-radio-group>
        <mat-error
          [appErrorMessages]="INVERTER_FORM.OPERATING_MODE"
          [hideError]="!allTouched"
        />
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>
            {{ "PV_INVERTER_INFORMATION.ISOLATED_OPERATIONAL" | translate }}
          </label>
          <app-info-tooltip
            tooltipText="PV_INVERTER_INFORMATION.ISOLATED_OPERATIONAL_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="INVERTER_FORM.ISOLATED_OPERATIONAL"
          class="input-container"
        >
          <mat-radio-button
            [value]="true"
            [attr.data-testid]="INVERTER_FORM.ISOLATED_OPERATIONAL + '-true'"
            color="primary"
            >{{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button
            [value]="false"
            [attr.data-testid]="INVERTER_FORM.ISOLATED_OPERATIONAL + '-false'"
            color="primary"
          >
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [appErrorMessages]="INVERTER_FORM.ISOLATED_OPERATIONAL"
            [hideError]="!allTouched"
          />
        </mat-radio-group>
      </section>
    </div>

    <div class="single-column-layout-container">
      <div class="single-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>
            {{
              "PV_INVERTER_INFORMATION.MANUFACTURER_AND_PERFORMANCE_DATA"
                | translate
            }}
          </label>
        </h2>
      </div>
    </div>

    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "PV_INVERTER_INFORMATION.SYSTEM_MANUFACTURER" | translate }}
            </mat-label>
            <input
              [attr.data-testid]="INVERTER_FORM.SYSTEM_MANUFACTURER"
              [name]="INVERTER_FORM.SYSTEM_MANUFACTURER"
              [formControlName]="INVERTER_FORM.SYSTEM_MANUFACTURER"
              matInput
            />
            <mat-error [appErrorMessages]="INVERTER_FORM.SYSTEM_MANUFACTURER" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_INVERTER_INFORMATION.SYSTEM_MANUFACTURER_TOOLTIP"
          />
        </div>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "PV_INVERTER_INFORMATION.SYSTEM_COUNT" | translate }}
            </mat-label>
            <input
              [name]="INVERTER_FORM.SYSTEM_COUNT"
              [attr.data-testid]="INVERTER_FORM.SYSTEM_COUNT"
              [formControlName]="INVERTER_FORM.SYSTEM_COUNT"
              matInput
              type="number"
              min="0"
            />
            <mat-error [appErrorMessages]="INVERTER_FORM.SYSTEM_COUNT" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_INVERTER_INFORMATION.SYSTEM_COUNT_TOOLTIP"
          />
        </div>
      </section>

      <section class="two-column-layout-item">
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "PV_INVERTER_INFORMATION.SYSTEM_TYPE" | translate }}
            </mat-label>
            <input
              [attr.data-testid]="INVERTER_FORM.SYSTEM_TYPE"
              [name]="INVERTER_FORM.SYSTEM_TYPE"
              [formControlName]="INVERTER_FORM.SYSTEM_TYPE"
              matInput
            />
            <mat-error [appErrorMessages]="INVERTER_FORM.SYSTEM_TYPE" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_INVERTER_INFORMATION.SYSTEM_TYPE_TOOLTIP"
          />
        </div>
      </section>
    </div>

    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "PV_INVERTER_INFORMATION.MAX_APPARENT_POWER" | translate }}
            </mat-label>
            <input
              [attr.data-testid]="INVERTER_FORM.MAX_APPARENT_POWER"
              [name]="INVERTER_FORM.MAX_APPARENT_POWER"
              [formControlName]="INVERTER_FORM.MAX_APPARENT_POWER"
              matInput
              type="number"
              min="0"
            />
            <mat-error [appErrorMessages]="INVERTER_FORM.MAX_APPARENT_POWER" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_INVERTER_INFORMATION.MAX_APPARENT_POWER_TOOLTIP"
          />
        </div>
      </section>

      <section class="two-column-layout-item">
        <p class="layout-paragraph">
          {{ "PV_INVERTER_INFORMATION.MAX_APPARENT_POWER_SUM" | translate }}
          {{ maxApparentPowerSum() | number: "1.2-2" : local }}
        </p>
      </section>

      <section class="two-column-layout-item">
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{
                "PV_INVERTER_INFORMATION.MAX_CONTROLLABLE_POWER" | translate
              }}
            </mat-label>
            <input
              [attr.data-testid]="INVERTER_FORM.MAX_CONTROLLABLE_POWER"
              [name]="INVERTER_FORM.MAX_CONTROLLABLE_POWER"
              [formControlName]="INVERTER_FORM.MAX_CONTROLLABLE_POWER"
              matInput
              type="number"
              min="0"
            />
            <mat-error
              [appErrorMessages]="INVERTER_FORM.MAX_CONTROLLABLE_POWER"
            />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_INVERTER_INFORMATION.MAX_CONTROLLABLE_POWER_TOOLTIP"
          />
        </div>
      </section>

      <section class="two-column-layout-item">
        <p class="layout-paragraph">
          {{ "PV_INVERTER_INFORMATION.MAX_CONTROLLABLE_POWER_SUM" | translate }}
          {{ maxControllablePowerSum() | number: "1.2-2" : local }}
        </p>
      </section>
    </div>
    <div class="space"></div>

    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>{{
            "PV_INVERTER_INFORMATION.GRID_FEED_IN" | translate
          }}</label>
          <app-info-tooltip
            tooltipText="PV_INVERTER_INFORMATION.GRID_FEED_IN_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="INVERTER_FORM.GRID_FEED_IN"
          class="input-container"
        >
          @for (option of gridFeedInOptions; track option) {
            <mat-radio-button
              [value]="option"
              [attr.data-testid]="INVERTER_FORM.GRID_FEED_IN + '-' + option"
              color="primary"
            >
              {{ "PV_INVERTER_INFORMATION.GRID_FEED_IN_" + option | translate }}
            </mat-radio-button>
          }
          <mat-error
            [appErrorMessages]="INVERTER_FORM.GRID_FEED_IN"
            [hideError]="!allTouched"
          />
        </mat-radio-group>
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label class="label-required">{{
            "PV_INVERTER_INFORMATION.METER_AVAILABLE" | translate
          }}</label>
        </h2>
        <mat-radio-group
          [formControlName]="INVERTER_FORM.METER_AVAILABLE"
          class="input-container"
        >
          <mat-radio-button
            [value]="true"
            [attr.data-testid]="INVERTER_FORM.METER_AVAILABLE + '-true'"
            color="primary"
            >{{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button
            [value]="false"
            [attr.data-testid]="INVERTER_FORM.METER_AVAILABLE + '-false'"
            color="primary"
          >
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [appErrorMessages]="INVERTER_FORM.METER_AVAILABLE"
            [hideError]="!allTouched"
          />
        </mat-radio-group>

        @if (pvInverterForm.get(INVERTER_FORM.METER_AVAILABLE)?.value) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label
                >{{ "PV_INVERTER_INFORMATION.METER_NUMBER" | translate }}
              </mat-label>
              <input
                [name]="INVERTER_FORM.METER_NUMBER"
                [formControlName]="INVERTER_FORM.METER_NUMBER"
                matInput
              />
              <mat-error [appErrorMessages]="INVERTER_FORM.METER_NUMBER" />
            </mat-form-field>
          </div>
        }
      </section>
    </div>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: pvInverterForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
