import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { SharedModule } from "@app/modules/shared/shared.module";
import { RouteService } from "@app/services/route.service";

@Component({
  selector: "app-balcony-pv-exit",
  standalone: true,
  imports: [SharedModule, TranslateModule],
  templateUrl: "./balcony-pv-exit.component.html",
  styleUrl: "balcony-pv-exit.component.scss",
})
export class BalconyPvExitComponent {
  #routeService: RouteService = inject(RouteService);

  public previous(): void {
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    this.#routeService.navigateToNextStep();
  }
}
