@if (powerIncreaseDetails) {
  <app-summary-section
    [stepRoute]="powerIncreaseDetailsRoute"
    heading="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.POWER_INCREASE.HEADING"
  >
    @if (powerIncreaseDetails.currentPowerDemand) {
      <app-summary-section-field
        [value]="powerIncreaseDetails.currentPowerDemand"
        label="POWER_CHANGE_DETAILS.CURRENT_POWER_DEMAND"
      />
    }
    @if (powerIncreaseDetails.plannedPowerDemand) {
      <app-summary-section-field
        [value]="powerIncreaseDetails.plannedPowerDemand"
        label="POWER_CHANGE_DETAILS.PLANNED_POWER_DEMAND"
      />
    }
  </app-summary-section>
}
