<app-form-card
  titleIcon="electric_bolt"
  cardTitle="GRID_CONNECTION_CHANGE.HEADING"
  data-testid="grid-connection-change-heading"
>
  <h2 class="full-width">
    {{ "GRID_CONNECTION_CHANGE.DESCRIPTION" | translate }}
  </h2>
  <form [formGroup]="form">
    <app-selection-group>
      @for (connection of changeTypeSelection; track connection) {
        <app-selection-card
          (click)="selectType(connection.type)"
          [icon]="connection.icon"
          [label]="connection.label"
          [selected]="selectedType === connection.type"
          [attr.data-testid]="connection.type"
          class="selection-card"
        />
      }
      @if (allTouched && !selectedType) {
        <mat-error appErrorMessages="changeType" />
      }
    </app-selection-group>

    @if (selectedType) {
      <div class="space"></div>
      <app-hint>
        @switch (selectedType) {
          @default {
            <span>
              {{
                "GRID_CONNECTION_CHANGE.CHANGE_TYPE.HINT." + selectedType
                  | translate
              }}
            </span>
          }
        }
      </app-hint>
    }

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      {{ "GRID_CONNECTION_CHANGE.DITCH_LENGTH" | translate }}
      <app-info-tooltip
        tooltipText="GRID_CONNECTION_CHANGE.DITCH_LENGTH_TOOLTIP"
      />
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "GRID_CONNECTION_CHANGE.DITCH_LENGTH_HINT" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="ditchLength"
          formControlName="ditchLength"
          min="0"
          data-testid="ditch-length"
        />
        <mat-error appErrorMessages="ditchLength" />
      </mat-form-field>
    </div>
    <label id="radio-group-label">
      <h2>
        {{ ("GRID_CONNECTION_CHANGE.TOTAL_POWER_CHANGED" | translate) + " *" }}
      </h2>
    </label>
    <mat-radio-group
      color="primary"
      formControlName="totalPowerChanged"
      aria-labelledby="radio-group-label"
      class="input-container"
    >
      <div>
        <mat-radio-button [value]="true" data-testid="total-power-changed">
          {{ "COMMON.YES" | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="total-power-not-changed">
          {{ "COMMON.NO" | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        [hideError]="!allTouched"
        appErrorMessages="totalPowerChanged"
      />
    </mat-radio-group>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
