<!-- heat options -->
<app-summary-section
  [stepRoute]="routes.HEAT_OPTIONS"
  heading="HEAT.HEAT_OPTIONS.HEADING"
>
  <span class="chip">
    {{
      "HEAT.HEAT_OPTIONS." + formData.heatOptions?.heatOption + ".LABEL"
        | translate
    }}
  </span>
  @if (formData.heatOptions?.heatOption === heatOptions.CHANGE) {
    @if (formData.heatOptions?.changeHeatPower) {
      <p class="section-heading">
        {{
          "HEAT.HEAT_OPTIONS.HEAT_CHANGE.CHANGE_HEAT_POWER_BEFORE_AFTER_LABEL"
            | translate
        }}
      </p>
      <div class="section-fields-container">
        <app-summary-section-field
          [value]="formData.heatOptions?.currentHeatPower"
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.CURRENT_HEAT_POWER_PLACEHOLDER"
        />
        <app-summary-section-field
          [value]="formData.heatOptions?.plannedHeatPower"
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.PLANNED_HEAT_POWER_PLACEHOLDER"
        />
        <app-summary-section-field
          [value]="formData.heatOptions?.additionalHeatDemand"
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.ADDITIONAL_DEMAND_QUESTION"
        />
      </div>
    }
    @if (formData.heatOptions?.otherChanges) {
      <p class="section-heading">
        {{ "HEAT.HEAT_OPTIONS.HEAT_CHANGE.OTHER_CHANGES_LABEL" | translate }}
      </p>
      <div class="section-fields-container">
        <app-summary-section-field
          [value]="formData.heatOptions?.otherChanges"
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.OTHER_CHANGES_PLACEHOLDER"
        />
      </div>
    }
  }
  @if (formData.heatOptions?.heatOption === heatOptions.DETACH) {
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="
          (formData.heatOptions?.permanentDeconstruction
            ? 'HEAT.HEAT_OPTIONS.HEAT_DETACH.PERMANENT_DECONSTRUCTION_LABEL'
            : 'HEAT.HEAT_OPTIONS.HEAT_DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
          ) | translate
        "
        label="HEAT.HEAT_OPTIONS.HEAT_DETACH.DECONSTRUCTION_TYPE_QUESTION"
      />
      @if (!formData.heatOptions?.permanentDeconstruction) {
        <app-summary-section-field
          [value]="
            (formData.heatOptions?.deconstructionUntil
              | date: 'shortDate' : '' : currentLanguageCulture) || undefined
          "
          label="HEAT.HEAT_OPTIONS.HEAT_DETACH.DECONSTRUCTION_UNTIL"
        />
      }
      <app-summary-section-field
        [value]="formData.heatOptions?.meterNumber"
        label="HEAT.HEAT_OPTIONS.HEAT_DETACH.METER_NUMBER"
      />
    </div>
  }
</app-summary-section>

<!-- heat property details -->
@if (
  formData.heatOptions?.heatOption === heatOptions.NEW ||
  formData.heatOptions?.heatOption === heatOptions.CHANGE
) {
  <app-summary-section
    [stepRoute]="routes.HEAT_PROPERTY_TYPE"
    heading="HEAT.HEAT_PROPERTY_TYPE.HEADING"
  >
    <app-summary-building-type
      [buildingType]="formData.heatBuildingDetails?.buildingType"
    />
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="
          'SHARED.BUILDING_STRUCTURE.' +
            formData.heatBuildingDetails?.buildingStructure | translate
        "
        label="SHARED.BUILDING_STRUCTURE.LABEL"
      />
    </div>
  </app-summary-section>
}

<!-- heat requirements -->
@if (formData.heatOptions?.heatOption === heatOptions.NEW) {
  <app-summary-section
    [stepRoute]="routes.HEAT_REQUIREMENTS"
    heading="HEAT.HEAT_REQUIREMENTS.HEADING"
  >
    <p class="section-heading">
      {{ "HEAT.HEAT_REQUIREMENTS.SUBHEADING_CONSUMPTION" | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="formData.heatRequirementDetails?.heatOutputDemand"
        label="HEAT.HEAT_REQUIREMENTS.OUTPUT_DEMAND"
      />
    </div>
    <p class="section-heading">
      {{ "HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS" | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="formData.heatRequirementDetails?.otherConsumers"
        label="HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS"
      />
      <app-summary-section-field
        [value]="formData.heatRequirementDetails?.otherConsumersPower"
        label="HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS_POWER"
      />
    </div>
    <p class="section-heading">
      {{ "HEAT.HEAT_REQUIREMENTS.SUBHEADING_OTHER" | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="formData.heatRequirementDetails?.notes"
        label="HEAT.HEAT_REQUIREMENTS.NOTES"
      />
    </div>
  </app-summary-section>
}
