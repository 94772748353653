<app-summary-section
  [stepRoute]="route"
  heading="CHARGING_DEVICE_SYSTEM_INFORMATION.HEADING"
>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        (chargingDeviceSystemInformation.plannedCommissioningDate
          | date: 'shortDate' : '' : currentLanguageCulture) || undefined
      "
      label="CHARGING_DEVICE_SYSTEM_INFORMATION.PLANNED_COMMISSIONING_DATE.TITLE"
    />

    <app-summary-section-field
      [value]="
        (chargingDeviceSystemInformation.controllable
          ? 'COMMON.YES'
          : 'COMMON.NO'
        ) | translate
      "
      label="CHARGING_DEVICE_SYSTEM_INFORMATION.CONTROLLABLE_SYSTEM_COMPLIANT.TITLE"
    />
  </div>
</app-summary-section>
