import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { filter, map, Subject, take, takeUntil } from "rxjs";

import { ELECTRICITY_SYSTEM_TYPE } from "@app/models/commissioning-mapped-data.interface";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

interface SystemDetails {
  icon?: string;
  svgIcon?: string;
  label: string;
  type: ELECTRICITY_SYSTEM_TYPE;
}

@Component({
  selector: "app-commissioning-electricity-systems",
  templateUrl: "./commissioning-electricity-systems.component.html",
  styleUrls: ["./commissioning-electricity-systems.component.scss"],
})
export class CommissioningElectricitySystemsComponent
  implements OnInit, OnDestroy
{
  public readonly maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public readonly otherType = ELECTRICITY_SYSTEM_TYPE.OTHER;
  private readonly systemIcons: Map<ELECTRICITY_SYSTEM_TYPE, object> = new Map([
    [ELECTRICITY_SYSTEM_TYPE.PV, { icon: "solar_power" }],
    [ELECTRICITY_SYSTEM_TYPE.STORAGE, { svgIcon: "storage" }],
    [ELECTRICITY_SYSTEM_TYPE.CHARGING_DEVICE, { icon: "electric_car" }],
    [ELECTRICITY_SYSTEM_TYPE.HEAT_PUMP, { icon: "heat_pump" }],
    [ELECTRICITY_SYSTEM_TYPE.OTHER, { icon: "more_horiz" }],
  ]);

  public readonly systemList: SystemDetails[] = Object.values(
    ELECTRICITY_SYSTEM_TYPE,
  ).map((type) => {
    return {
      ...this.systemIcons.get(type),
      label: `COMMISSIONING_ELECTRICITY.SYSTEM.TYPES.${type}`,
      type: type,
    };
  });
  public systemForm: FormGroup = new FormGroup({});
  public allTouched = false;
  public electricitySystemsRequired = false;
  public oneOrMoreSystemSelected = false;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createSystemForm();
    this.updateForm();
    this.watchForm();
  }

  private createSystemForm(): void {
    this.systemForm = this.formBuilder.group({
      meterChanges: [null, Validators.required],
    });
    this.systemList.forEach((system) => {
      this.systemForm.addControl(
        system.type,
        this.formBuilder.group({
          selected: [null],
          systemPower: [null],
          systemCount: [null],
          systemType: [
            null,
            {
              updateOn: "blur",
            },
          ],
          notes: [
            null,
            {
              updateOn: "blur",
            },
          ],
        }),
      );
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.commissioningElectricitySystems),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((commissioningElectricitySystems) => {
        Object.keys(commissioningElectricitySystems)
          .filter((key) =>
            Object.values(ELECTRICITY_SYSTEM_TYPE).some(
              (systemType) => systemType === key,
            ),
          )
          .forEach((systemType) =>
            this.updateCardSelectionValidators(
              systemType,
              commissioningElectricitySystems[
                systemType as ELECTRICITY_SYSTEM_TYPE
              ].selected,
            ),
          );
        this.systemForm.patchValue(commissioningElectricitySystems);
        this.checkElectricitySystemsRequired();
        this.checkOneOrMoreSystemSelected();
      });
  }

  private watchForm(): void {
    this.systemForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((commissioningElectricitySystem) => {
        this.appStateService.updateFormData({
          commissioningElectricitySystems: commissioningElectricitySystem,
        });
        this.checkElectricitySystemsRequired();
        this.checkOneOrMoreSystemSelected();
      });
  }

  public toggleCardSelection(formGroupName: string, selected?: boolean): void {
    const cardFormGroup = this.systemForm.get(formGroupName) as FormGroup;
    const isCardSelected = selected ?? !cardFormGroup.get("selected")?.value;
    cardFormGroup.get("selected")?.setValue(isCardSelected);
    this.updateCardSelectionValidators(formGroupName, isCardSelected);
  }

  private updateCardSelectionValidators(
    formGroupName: string,
    selected: boolean,
  ): void {
    const cardFormGroup = this.systemForm.get(formGroupName) as FormGroup;
    if (!selected) {
      cardFormGroup.get("systemPower")?.clearValidators();
      cardFormGroup.get("systemCount")?.clearValidators();
      cardFormGroup.get("systemType")?.clearValidators();
      cardFormGroup.get("notes")?.clearValidators();
    } else {
      if (formGroupName !== this.otherType) {
        cardFormGroup
          .get("systemPower")
          ?.addValidators([Validators.min(0), Validators.required]);
      }
      cardFormGroup
        .get("systemCount")
        ?.addValidators([Validators.min(0), Validators.required]);
      if (formGroupName === this.otherType) {
        cardFormGroup
          .get("systemType")
          ?.addValidators([
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ]);
      }
      if (formGroupName === this.otherType) {
        cardFormGroup
          .get("notes")
          ?.addValidators([
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ]);
      }
    }

    cardFormGroup.get("systemPower")?.updateValueAndValidity();
    cardFormGroup.get("systemCount")?.updateValueAndValidity();
    cardFormGroup.get("systemType")?.updateValueAndValidity();
    cardFormGroup.get("notes")?.updateValueAndValidity();
  }

  private checkElectricitySystemsRequired(): void {
    this.electricitySystemsRequired =
      !this.systemForm.get("meterChanges")?.value;
  }

  private checkOneOrMoreSystemSelected(): void {
    this.oneOrMoreSystemSelected = this.systemList.some(
      (system) => this.systemForm.get(system.type)?.get("selected")?.value,
    );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    const meterChanges = this.systemForm.get("meterChanges")?.value;
    if (
      this.systemForm.valid &&
      (meterChanges || this.oneOrMoreSystemSelected)
    ) {
      this.routeService.navigateToNextStep();
    } else {
      this.systemForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
