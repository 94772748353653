import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { filter, map, take } from "rxjs";

import {
  LOAD_MANAGEMENT,
  STORAGE_OPERATING_MODE,
  StorageDetailsDto,
  UNIT_COUPLING,
} from "@app/models/registration-form";
import { SharedModule } from "@app/modules/shared/shared.module";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { RequiredSuffixDirective } from "@app/shared/directives/required-suffix.directive";
import { NextButtonDisabledPipe } from "@app/shared/pipes/next-button-disabled.pipe";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  selector: "app-storage-details",
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    RequiredSuffixDirective,
    SharedModule,
    TranslatePipe,
  ],
  templateUrl: "./storage-details.component.html",
})
export class StorageDetailsComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);

  public form!: FormGroup;
  public allTouched = false;
  public maxApparentPowerSum = 0.0;
  public locale!: string;
  public readonly STORAGE_OPERATING_MODE = Object.values(
    STORAGE_OPERATING_MODE,
  );
  public readonly LOAD_MANAGEMENT = Object.values(LOAD_MANAGEMENT);
  public readonly UNIT_COUPLING = Object.values(UNIT_COUPLING);
  public readonly UNIT_COUPLING_OWN_INVERTER = UNIT_COUPLING.OWN_INVERTER;

  public ngOnInit(): void {
    this.locale = this.#translateService.currentLang;
    this.createForm();
    this.updateForm();
    this.watchForm();
  }

  private createForm(): void {
    // ControllableDetailsDto
    this.form = this.#formBuilder.group({
      operatingMode: [null, Validators.required],
      maxControllablePowerSupply: [null, Validators.required],
      loadManagement: [null, Validators.required],
      capacity: [null, Validators.required],
      unitCoupling: [null, Validators.required],
      systemManufacturer: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      systemType: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      systemCount: [null, Validators.required],
      maxApparentPower: [null, Validators.required],
      separateMeter: [null, Validators.required],
      meterAvailable: [null, Validators.required],
      meterNumber: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
    });
  }

  private updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map((state) => state.formData.storageDetailsDto),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((storageDetails) => {
        this.form.patchValue(storageDetails);
        this.configureFormInputs();
        this.calculateMaxApparentPowerSum();
      });
  }

  private watchForm(): void {
    this.form
      .get("unitCoupling")
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.configureFormInputs());

    this.form
      .get("meterAvailable")
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.configureMeterNumberInput());

    this.form
      .get("systemCount")
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.calculateMaxApparentPowerSum());

    this.form
      .get("maxApparentPower")
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.calculateMaxApparentPowerSum());
  }

  private configureFormInputs(): void {
    if (this.form.get("unitCoupling")?.value === UNIT_COUPLING.OWN_INVERTER) {
      this.form.get("systemManufacturer")?.enable();
      this.form.get("systemType")?.enable();
      this.form.get("systemCount")?.enable();
      this.form.get("maxApparentPower")?.enable();
      this.form.get("separateMeter")?.enable();
      this.form.get("meterAvailable")?.enable();
    } else {
      this.form.get("systemManufacturer")?.disable();
      this.form.get("systemType")?.disable();
      this.form.get("systemCount")?.disable();
      this.form.get("maxApparentPower")?.disable();
      this.form.get("separateMeter")?.disable();
      this.form.get("meterAvailable")?.disable();
    }
    this.configureMeterNumberInput();
  }

  private configureMeterNumberInput(): void {
    if (
      this.form.get("meterAvailable")?.enabled &&
      this.form.get("meterAvailable")?.value
    ) {
      this.form.get("meterNumber")?.enable();
    } else {
      this.form.get("meterNumber")?.disable();
    }
  }

  private calculateMaxApparentPowerSum(): void {
    this.maxApparentPowerSum =
      (this.form.get("systemCount")?.value || 0.0) *
      (this.form.get("maxApparentPower")?.value || 0.0);
  }

  public previous(): void {
    this.updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  private updateState(): void {
    const storageDetails: StorageDetailsDto = this.form.value;
    this.#appStateService.updateFormData({
      storageDetailsDto: storageDetails,
    });
  }
}
