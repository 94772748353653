import { Component, Input } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";

import {
  CHANGE_SYSTEM_TYPE,
  ChangeOtherDetailsDto,
  OTHER_CHANGE_TYPE,
  OTHER_TYPE,
} from "@app/models/registration-form";
import { ROUTES } from "@app/models/routes.enum";
import { SharedModule } from "@app/modules/shared/shared.module";

@Component({
  selector: "app-other-details-summary",
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: "./other-details-summary.component.html",
})
export class OtherDetailsSummaryComponent {
  @Input({ required: true })
  public otherDetails!: Omit<ChangeOtherDetailsDto, "type">;
  public readonly route = ROUTES.OTHER_2_DETAILS;
  public readonly CHANGE_SYSTEM_TYPE = CHANGE_SYSTEM_TYPE;
  public readonly OTHER_CHANGE_TYPE = OTHER_CHANGE_TYPE;
  public readonly OTHER_TYPE = OTHER_TYPE;
}
