import { inject, Injectable, NgZone } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ResizeObserverService {
  readonly #ngZone = inject(NgZone);
  readonly #resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(() => {
      const height = Math.max(document.body.scrollHeight);

      this.#ngZone.runOutsideAngular(() => {
        /**
         * disabled sonar because sending a message to parent window is not a security issue because we do not send
         * any sensitive data or allow the parent window to execute any code
         */
        window.parent.postMessage({ type: "body-scrollHeight", height }, "*"); // NOSONAR
      });
    });
  });

  public initObservation(): void {
    this.#resizeObserver.observe(document.documentElement);
  }
}
