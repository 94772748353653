import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SnackBarService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  public openSnackBar(
    messageKey: string,
    success: boolean,
    interpolateParams?: object | undefined,
    duration: number = 10000,
    action: string | undefined = undefined,
  ): void {
    this.translateService
      .get(messageKey, interpolateParams)
      .pipe(take(1))
      .subscribe((message) => {
        this.snackBar.open(message, action, {
          duration,
          panelClass: ["snackbar", `snackbar-${success ? "success" : "error"}`],
        });
      });
  }
}
