import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { filter, map, Subject, take, takeUntil } from "rxjs";

import { BUILDING_TYPE } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { PropertyDetails, propertyDetailsList } from "@app/shared/shared-data";

@Component({
  selector: "app-grid-connection-property-type",
  templateUrl: "./grid-connection-property-type.component.html",
  styleUrls: ["./grid-connection-property-type.component.scss"],
})
export class GridConnectionPropertyTypeComponent implements OnInit, OnDestroy {
  public readonly propertyDetailsList: PropertyDetails[] = propertyDetailsList;
  public gridConnectionPropertyType = BUILDING_TYPE;
  public selectedPropertyType: BUILDING_TYPE | undefined;
  public allTouched = false;
  public buildingTypeFormControl!: FormControl;
  public propertyTypeForm!: FormGroup;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createPropertyTypeForm();
    this.watchBuildingType();
    this.updateForm();
    this.watchForm();
  }

  private createPropertyTypeForm(): void {
    this.propertyTypeForm = this.formBuilder.group({
      buildingType: [null, Validators.required],
      basementAvailable: [false],
      externalConnection: [false],
    });
    this.buildingTypeFormControl = this.propertyTypeForm.get(
      "buildingType",
    ) as FormControl;
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.newGridConnectionDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((newGridConnectionDetails) => {
        this.selectedPropertyType = newGridConnectionDetails.buildingType;
        this.buildingTypeFormControl.setValue(this.selectedPropertyType);
        this.propertyTypeForm.patchValue(newGridConnectionDetails);
      });
  }

  private watchForm(): void {
    this.propertyTypeForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((newGridConnectionDetails) =>
        this.appStateService.updateFormData({
          newGridConnectionDetails,
        }),
      );
  }

  private watchBuildingType(): void {
    this.buildingTypeFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((buildingType) => {
        if (buildingType === BUILDING_TYPE.MULTI_FAMILY_HOUSE) {
          this.propertyTypeForm.addControl(
            "flatCount",
            this.formBuilder.control(null, [
              Validators.required,
              Validators.min(0),
            ]),
          );
          this.propertyTypeForm.removeControl("area");
        } else if (
          buildingType === BUILDING_TYPE.INDUSTRY ||
          buildingType === BUILDING_TYPE.MIXED
        ) {
          this.propertyTypeForm.addControl(
            "area",
            this.formBuilder.control(null, [
              Validators.required,
              Validators.min(0),
            ]),
          );
          this.propertyTypeForm.removeControl("flatCount");
        } else {
          this.propertyTypeForm.removeControl("flatCount");
          this.propertyTypeForm.removeControl("area");
        }
      });
  }

  public selectPropertyType(selectedOption: BUILDING_TYPE): void {
    this.buildingTypeFormControl.setValue(selectedOption);
    this.selectedPropertyType = selectedOption;
    this.allTouched = false;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.propertyTypeForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.propertyTypeForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
