import { Injectable } from "@angular/core";

import { environment } from "@environments/environment";
import { IEnvironment } from "@environments/ienvironment";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService implements IEnvironment {
  public get production(): boolean {
    return environment.production;
  }

  public get environment(): string {
    return environment.environment;
  }

  public get apiUrl(): string {
    return environment.apiUrl;
  }

  public get hostOverride(): string | undefined {
    return environment.hostOverride;
  }

  public set hostOverride(hostOverride: string | undefined) {
    environment.hostOverride = hostOverride;
  }

  public get consentBannerEnabled(): string | boolean {
    return environment.consentBannerEnabled;
  }

  public get isConsentBannerEnabled(): boolean {
    return (
      this.consentBannerEnabled === "true" || this.consentBannerEnabled === true
    );
  }

  public get dynamicHostOverrideEnabled(): string | boolean {
    return environment.dynamicHostOverrideEnabled;
  }

  public get isDynamicHostOverrideEnabled(): boolean {
    return (
      this.dynamicHostOverrideEnabled === "true" ||
      this.dynamicHostOverrideEnabled === true
    );
  }

  public get isOnMainDomain(): boolean {
    return window.location.host === environment.mainDomain;
  }

  public get analyticsTagId(): string {
    return environment.analyticsTagId;
  }

  public get turnstileSiteKey(): string {
    return environment.turnstileSiteKey;
  }

  public get gtmId(): string {
    return environment.gtmId;
  }

  public get gtmAuth(): string {
    return environment.gtmAuth;
  }

  public get gtmPreview(): string {
    return environment.gtmPreview;
  }

  public get maxTotalFileSizeMB(): number {
    return +environment.maxTotalFileSizeMB;
  }
}
