<app-form-card
  titleIcon="electric_bolt"
  cardTitle="PV_FACILITY_INFORMATION.HEADING"
  description="PV_FACILITY_INFORMATION.DESCRIPTION"
  dataTestId="pv-facility-information-heading"
>
  <div class="space"></div>

  <form [formGroup]="customerFacilityForm">
    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label class="label-required">{{
            "PV_FACILITY_INFORMATION.CONTROLLABLE_CONSUMPTION_DEVICE_LABEL"
              | translate
          }}</label>
          <app-info-tooltip
            tooltipText="PV_FACILITY_INFORMATION.CONTROLLABLE_CONSUMPTION_DEVICE_TOOLTIP"
          />
        </h2>
        <div
          [formArrayName]="PV_FACILITY_FORM.CONTROLLABLE_CONSUMPTION_SYSTEMS"
        >
          @for (
            option of readControllableConsumptionSystemsControls()?.controls;
            track option
          ) {
            <p>
              <mat-checkbox
                [formControlName]="$index"
                [attr.data-testid]="
                  controllableConsumptionSystemsOptions[$index]
                "
                color="primary"
              >
                {{
                  "CONTROLLABLE_CONSUMPTION_SYSTEM." +
                    controllableConsumptionSystemsOptions[$index] | translate
                }}
              </mat-checkbox>
            </p>
          }
          @if (allTouched) {
            <mat-error
              [appErrorMessages]="
                PV_FACILITY_FORM.CONTROLLABLE_CONSUMPTION_SYSTEMS
              "
            />
          }
        </div>
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label class="label-required">{{
            "PV_FACILITY_INFORMATION.CONTROL_ACCORDING_TO_§_9_EEG" | translate
          }}</label>
          <app-info-tooltip
            tooltipText="PV_FACILITY_INFORMATION.CONTROL_ACCORDING_TO_§_9_EEG_TOOLTIP"
          />
        </h2>

        <mat-radio-group
          [formControlName]="PV_FACILITY_FORM.CONTROL"
          color="primary"
          aria-labelledby="pv-control-radio-group-label"
        >
          @for (option of pvControlOptions; track option) {
            <mat-radio-button [value]="option" [attr.data-testid]="option">
              {{ "PV_CONTROL." + option | translate }}
            </mat-radio-button>
          }
        </mat-radio-group>
        <mat-error
          [appErrorMessages]="PV_FACILITY_FORM.CONTROL"
          [hideError]="!allTouched"
        />
      </section>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label id="planned-commissionioning-date-label">
            {{
              "PV_FACILITY_INFORMATION.PLANNED_COMMISSIONING_DATE" | translate
            }}
          </label>
          <app-info-tooltip
            tooltipText="PV_FACILITY_INFORMATION.PLANNED_COMMISSIONING_DATE_TOOLTIP"
          />
        </h2>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>
            {{ "PV_FACILITY_INFORMATION.DATE_LABEL" | translate }}
          </mat-label>
          <input
            [matDatepicker]="picker"
            [min]="minDate"
            [formControlName]="PV_FACILITY_FORM.PLANNED_COMMISSIONING_DATE"
            matInput
          />
          <mat-datepicker-toggle [for]="picker" matIconSuffix />
          <mat-datepicker #picker />
        </mat-form-field>
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label id="operater-radio-group-label" class="label-required">
            {{
              "PV_FACILITY_INFORMATION.SELECTION_OF_METERING_POINT_OPERATOR"
                | translate
            }}
          </label>
          <app-info-tooltip
            tooltipText="PV_FACILITY_INFORMATION.SELECTION_OF_METERING_POINT_OPERATOR_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="PV_FACILITY_FORM.OPERATOR"
          color="primary"
          aria-labelledby="pv-operator-control-radio-group-label"
        >
          @for (option of pvOperatorOptions; track option) {
            <mat-radio-button [value]="option" [attr.data-testid]="option">
              {{ "PV_OPERATOR." + option | translate }}
            </mat-radio-button>
          }
          <mat-error
            [appErrorMessages]="PV_FACILITY_FORM.OPERATOR"
            [hideError]="!allTouched"
          />
        </mat-radio-group>
      </section>
    </div>

    <div class="single-column-layout-container">
      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label id="formOfFeedInSale-radio-group-label" class="label-required">
            {{
              "PV_FACILITY_INFORMATION.DESIRED_FORM_OF_SALE_OF_THE_FEED_IN"
                | translate
            }}
          </label>
          <app-info-tooltip
            tooltipText="PV_FACILITY_INFORMATION.DESIRED_FORM_OF_SALE_OF_THE_FEED_IN_TOOLTIP"
          />
        </h2>
        <mat-radio-group
          [formControlName]="PV_FACILITY_FORM.FORM_OF_FEED_IN_SALE"
          color="primary"
          aria-labelledby="formOfFeedInSale-control-radio-group-label"
        >
          @for (option of formOfFeedInSaleOptions; track option) {
            <mat-radio-button [value]="option" [attr.data-testid]="option">
              {{ "FORM_OF_FEED_IN_SALE." + option | translate }}
            </mat-radio-button>
          }
          <mat-error
            [appErrorMessages]="PV_FACILITY_FORM.FORM_OF_FEED_IN_SALE"
            [hideError]="!allTouched"
          />
        </mat-radio-group>
      </section>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: customerFacilityForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
