import { HttpInterceptorFn } from "@angular/common/http";
import * as Sentry from "@sentry/angular";
import { catchError, throwError } from "rxjs";

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchError((err: any) => {
      const logObject = {
        // note: request body of multipart/form-data requests is not logged
        request: req,
        response: err,
      };
      Sentry.getCurrentScope().addAttachment({
        data: JSON.stringify(logObject),
        filename: "http-error-interceptor.json",
        contentType: "application/json",
      });
      Sentry.captureMessage(
        `A http request failed: ${req.method} ${req.url}`,
        "warning",
      );
      // Re-throw the error to propagate it further
      return throwError(() => err);
    }),
  );
};
