import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { distinctUntilChanged, filter, map, take } from "rxjs";

import { FORM_TYPE } from "@app/models/registration-form";
import { AppStateService } from "@app/services/app-state.service";
import { DateHelperService } from "@app/services/date-helper.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  templateUrl: "./construction-electricity.component.html",
  styleUrls: ["./construction-electricity.component.scss"],
})
export class ConstructionElectricityComponent implements OnInit {
  readonly #routeService = inject(RouteService);
  readonly #formBuilder = inject(FormBuilder);
  readonly #appStateService = inject(AppStateService);
  readonly #dateHelperService = inject(DateHelperService);
  readonly #destroyRef = inject(DestroyRef);

  public constructionElectricityForm!: FormGroup;
  public allTouched = false;
  public errorMessageForInvalidDateFormat =
    this.#dateHelperService.getErrorMessageForInvalidDateFormat();
  public dateForTomorrow = this.#dateHelperService.getFutureDate(1);

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
  }

  private createForm(): void {
    this.constructionElectricityForm = this.#formBuilder.group({
      systemPower: [null, [Validators.required, Validators.min(0)]],
      devicesRequireApproval: [null, Validators.required],
      desiredInstallationDate: [null, Validators.required],
      desiredDeconstructionDate: null,
    });
    this.watchDevicesRequireApproval();
  }

  private watchDevicesRequireApproval(): void {
    this.constructionElectricityForm
      .get("devicesRequireApproval")
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((devicesRequireApproval: boolean) =>
        this.handleDeviceApproval(devicesRequireApproval),
      );
  }

  private handleDeviceApproval(devicesRequireApproval: boolean): void {
    if (devicesRequireApproval) {
      this.addRequiredDevicesControl();
    } else {
      this.removeRequiredDevicesControl();
    }
  }

  private addRequiredDevicesControl(): void {
    this.constructionElectricityForm.addControl(
      "requiredDevices",
      new FormArray([this.buildRequiredDeviceFormControl()]),
    );
  }

  private removeRequiredDevicesControl(): void {
    this.constructionElectricityForm.removeControl("requiredDevices");
  }

  get requiredDevices(): FormArray {
    return this.constructionElectricityForm.get("requiredDevices") as FormArray;
  }

  private buildRequiredDeviceFormControl(): FormControl {
    return new FormControl(null, {
      updateOn: "blur",
      validators: [
        CustomValidators.trimValidator,
        CustomValidators.shortText,
        Validators.required,
      ],
    });
  }

  public addMoreDevice(): void {
    this.requiredDevices.push(this.buildRequiredDeviceFormControl());
  }

  public removeDevice(index: number): void {
    this.requiredDevices.removeAt(index);
  }

  private updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.constructionElectricity),
        filter(Boolean),
        take(1),
      )
      .subscribe((constructionElectricityDetails) => {
        this.constructionElectricityForm.patchValue(
          constructionElectricityDetails,
        );
        if (constructionElectricityDetails.requiredDevices?.length) {
          constructionElectricityDetails.requiredDevices.forEach(
            (requiredDevice) => {
              if (!this.requiredDevices.value?.includes(requiredDevice)) {
                this.requiredDevices.push(
                  new FormControl(requiredDevice, Validators.required),
                );
              }
            },
          );
        }
      });
  }

  private updateState(): void {
    this.#appStateService.updateFormData({
      constructionElectricity: {
        ...this.constructionElectricityForm.value,
        type: FORM_TYPE.CONSTRUCTION_ELECTRICITY,
      },
    });
  }

  public previous(): void {
    this.updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.constructionElectricityForm.valid) {
      this.updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.constructionElectricityForm.markAllAsTouched();
    }
  }
}
