import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";

@Component({
  selector: "app-commissioning-electricity-information",
  templateUrl: "./commissioning-electricity-information.component.html",
})
export class CommissioningElectricityInformationComponent
  implements OnInit, OnDestroy
{
  public allTouched = false;
  public informationForm!: FormGroup;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
  ) {}

  public ngOnInit(): void {
    this.createInformationForm();
    this.updateForm();
    this.watchForm();
  }

  private createInformationForm(): void {
    this.informationForm = this.formBuilder.group({
      systemPower: [null, [Validators.required, Validators.min(0)]],
      connectionFuse: [null, [Validators.required, Validators.min(0)]],
      constructionElectricity: [null, Validators.required],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.commissioningElectricityInformation),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((commissioningElectricityInformation) =>
        this.informationForm.patchValue(commissioningElectricityInformation),
      );
  }

  private watchForm(): void {
    this.informationForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((commissioningElectricityInformation) =>
        this.appStateService.updateFormData({
          commissioningElectricityInformation,
        }),
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.informationForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.informationForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
