import { Component, inject, Input } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

import { getLanguageLocaleCulture } from "@app/constants/language";
import { StorageCustomerFacility } from "@app/models/form-data.interface";
import { ROUTES } from "@app/models/routes.enum";
import { SharedModule } from "@app/modules/shared/shared.module";

@Component({
  selector: "app-storage-customer-facility-summary",
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: "./storage-customer-facility-summary.component.html",
})
export class StorageCustomerFacilitySummaryComponent {
  readonly #translateService: TranslateService = inject(TranslateService);
  @Input({ required: true })
  public storageCustomerFacility!: StorageCustomerFacility;
  public route = ROUTES.STORAGE_CUSTOMER_FACILITY;
  public currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang,
  );
}
