<app-summary-section
  [stepRoute]="contactRoute"
  heading="CONTACT.CUSTOMER_DETAILS"
>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="(contact.onBehalf ? 'COMMON.NO' : 'COMMON.YES') | translate"
      label="CONTACT.REQUESTER"
    />
  </div>

  <p class="section-heading">
    {{ "CONTACT.CONTACT_DETAILS" | translate }}
  </p>

  <div class="section-fields-container">
    @if (contact.onBehalf) {
      <app-summary-section-field
        [value]="'CONTACT.ON_BEHALF_TYPE.' + contact.onBehalfType | translate"
        label="CONTACT.YOUR_FUNCTION"
      />
      <app-summary-section-field
        [value]="
          (contact.powerOfAttorney ? 'COMMON.YES' : 'COMMON.NO') | translate
        "
        label="CONTACT.POWER_OF_ATTORNEY"
      />
      @if (contact.powerOfAttorney) {
        <app-summary-section-field
          [value]="contact.powerOfAttorneyDocument?.name"
          label="CONTACT.POWER_OF_ATTORNEY_DOCUMENT"
        />
      }
    }
    <app-contact-details [contactDetails]="contact.contactForm" />
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        (contact.differentAddress ? 'COMMON.NO' : 'COMMON.YES') | translate
      "
      label="CONTACT.CONNECTION_ADDRESS_DESCRIPTION"
    />
  </div>

  @if (contact.differentAddress && contact.differentAddressForm) {
    <div class="section-fields-container">
      <app-address [address]="contact.differentAddressForm" />
    </div>
  }

  @if (contact.onBehalf) {
    <p class="section-heading">
      {{ "CONTACT.REQUESTER_CONTACT" | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        [value]="contact.differentContactForm?.firstName"
        label="CONTACT.FIRST_NAME"
      />
      <app-summary-section-field
        [value]="contact.differentContactForm?.lastName"
        label="CONTACT.LAST_NAME"
      />
      <app-summary-section-field
        [value]="contact.differentContactForm?.email"
        label="CONTACT.EMAIL"
      />
      <app-summary-section-field
        [value]="contact.differentContactForm?.phone"
        label="CONTACT.PHONE"
      />
    </div>
  }
</app-summary-section>
