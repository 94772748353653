import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from "@angular/core";
import { Subject } from "rxjs";

import { TurnstileOptions } from "@app/modules/shared/ngx-turnstile/turnstiles-options.interface";
import { EnvironmentService } from "@app/services/environment.service";

declare global {
  interface Window {
    onloadTurnstileCallback: () => void;
    turnstile: {
      render: (
        idOrContainer: string | HTMLElement,
        options: TurnstileOptions,
      ) => string;
      reset: (widgetIdOrContainer: string | HTMLElement) => void;
      getResponse: (
        widgetIdOrContainer: string | HTMLElement,
      ) => string | undefined;
      remove: (widgetIdOrContainer: string | HTMLElement) => void;
    };
  }
}

@Component({
  selector: "app-ngx-turnstile",
  standalone: true,
  template: "",
  styles: [],
})
/**
 * Regarding the 401 for challenges.cloudflare
 * https://developers.cloudflare.com/turnstile/frequently-asked-questions/#i-am-seeing-a-401-error-in-your-console-during-a-turnstile-security-check-is-this-a-problem
 */
export class NgxTurnstileComponent implements AfterViewInit, OnDestroy {
  @Input() action?: string;
  @Input() theme?: "light" | "dark" | "auto" = "light";
  @Input({ required: false }) resetWidget$: Subject<void> = new Subject<void>();

  @Output() resolved = new EventEmitter<string | null>();

  #widgetId!: string;

  readonly #elementRef = inject(ElementRef);
  readonly #zone = inject(NgZone);
  readonly #environmentService: EnvironmentService = inject(EnvironmentService);
  readonly #siteKey = this.#environmentService.turnstileSiteKey;

  public ngAfterViewInit(): void {
    if (!this.#elementRef?.nativeElement || !this.#siteKey) {
      return;
    }

    const turnstileOptions: TurnstileOptions = {
      sitekey: this.#siteKey,
      action: this.action,
      theme: this.theme,
      callback: (token: string) => {
        this.#zone.run(() => this.resolved.emit(token));
      },
      "expired-callback": () => {
        this.#zone.run(() => this.reset());
      },
    };

    this.#widgetId = window.turnstile.render(
      this.#elementRef.nativeElement,
      turnstileOptions,
    );

    this.resetWidget$.subscribe(() => window.turnstile.reset(this.#widgetId));
  }

  public reset(): void {
    if (this.#widgetId) {
      this.resolved.emit(null);
      window.turnstile.reset(this.#widgetId);
    }
  }

  public ngOnDestroy(): void {
    if (this.#widgetId) {
      window.turnstile.remove(this.#widgetId);
    }
    this.resetWidget$.unsubscribe();
  }
}
