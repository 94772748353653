<app-summary-section
  [stepRoute]="ROUTES.PV_FACILITY_INFORMATION"
  heading="PV_FACILITY_INFORMATION.HEADING"
>
  <p class="section-heading"></p>
  <div class="section-fields-container">
    @if (pvCustomerFacility(); as pvCustomerFacility) {
      <app-summary-section-field
        [value]="controllableConsumptionSystemsTranslated()"
        label="PV_FACILITY_INFORMATION.CONTROLLABLE_CONSUMPTION_DEVICE_LABEL"
      />
      <app-summary-section-field
        [value]="'PV_CONTROL.' + pvCustomerFacility.control | translate"
        label="PV_FACILITY_INFORMATION.CONTROL_ACCORDING_TO_§_9_EEG"
      />
      <app-summary-section-field
        [value]="
          (pvCustomerFacility.plannedCommissioningDate
            | date: 'shortDate' : '' : currentLanguageCulture) || '-'
        "
        label="PV_FACILITY_INFORMATION.PLANNED_COMMISSIONING_DATE"
      />
      <app-summary-section-field
        [value]="'PV_OPERATOR.' + pvCustomerFacility.operator | translate"
        label="PV_FACILITY_INFORMATION.SELECTION_OF_METERING_POINT_OPERATOR"
      />
      <app-summary-section-field
        [value]="
          'FORM_OF_FEED_IN_SALE.' + pvCustomerFacility.formOfFeedInSale
            | translate
        "
        label="PV_FACILITY_INFORMATION.DESIRED_FORM_OF_SALE_OF_THE_FEED_IN"
      />
    }
  </div>
</app-summary-section>

<app-summary-section
  [stepRoute]="ROUTES.PV_INVERTER_INFORMATION"
  heading="PV_INVERTER_INFORMATION.HEADING"
>
  <div class="section-fields-container">
    @if (pvInverter(); as pvInverter) {
      <app-summary-section-field
        [value]="
          'PV_INVERTER_INFORMATION.OPERATING_MODE.' + pvInverter.operatingMode
            | translate
        "
        label="PV_INVERTER_INFORMATION.OPERATING_MODE"
      />
      <app-summary-section-field
        [value]="
          pvInverter.isolatedOperational
            ? ('COMMON.YES' | translate)
            : ('COMMON.NO' | translate)
        "
        label="PV_INVERTER_INFORMATION.ISOLATED_OPERATIONAL"
      />
      <app-summary-section-field
        [value]="pvInverter.systemManufacturer"
        label="PV_INVERTER_INFORMATION.SYSTEM_MANUFACTURER"
      />
      <app-summary-section-field
        [value]="pvInverter.systemType"
        label="PV_INVERTER_INFORMATION.SYSTEM_TYPE"
      />
      <app-summary-section-field
        [value]="pvInverter.systemCount"
        label="PV_INVERTER_INFORMATION.SYSTEM_COUNT"
      />
      <app-summary-section-field
        [value]="pvInverter.maxApparentPower"
        label="PV_INVERTER_INFORMATION.MAX_APPARENT_POWER"
      />
      <app-summary-section-field
        [value]="pvInverter.maxApparentPowerSum"
        label="PV_INVERTER_INFORMATION.MAX_APPARENT_POWER_SUM"
      />
      <app-summary-section-field
        [value]="pvInverter.maxControllablePower"
        label="PV_INVERTER_INFORMATION.MAX_CONTROLLABLE_POWER"
      />
      <app-summary-section-field
        [value]="pvInverter.maxControllablePowerSum"
        label="PV_INVERTER_INFORMATION.MAX_CONTROLLABLE_POWER_SUM"
      />
      <app-summary-section-field
        [value]="
          pvInverter.gridFeedIn
            ? ('PV_INVERTER_INFORMATION.GRID_FEED_IN_' + pvInverter!.gridFeedIn
              | translate)
            : null
        "
        label="PV_INVERTER_INFORMATION.GRID_FEED_IN"
      />

      <app-summary-section-field
        [value]="
          pvInverter.meterAvailable
            ? ('COMMON.YES' | translate)
            : ('COMMON.NO' | translate)
        "
        label="PV_INVERTER_INFORMATION.METER_AVAILABLE"
      />

      <app-summary-section-field
        [value]="pvInverter.meterNumber"
        label="PV_INVERTER_INFORMATION.METER_NUMBER"
      />
    }
  </div>
</app-summary-section>

<app-summary-section
  [stepRoute]="ROUTES.PV_GENERATION_UNIT_INFORMATION"
  heading="PV_GENERATION_UNIT_INFORMATION.HEADING"
>
  <div class="section-fields-container">
    @if (pvGenerationUnit(); as pvGenerationUnit) {
      <app-summary-section-field
        [value]="pvGenerationUnit.powerPerModule"
        label="PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_PV_MODULE_IN_KWP"
      />
      <app-summary-section-field
        [value]="pvGenerationUnit.moduleCount"
        label="PV_GENERATION_UNIT_INFORMATION.MODULE_COUNT"
      />
      <app-summary-section-field
        [value]="
          'PV_GENERATION_UNIT_INFORMATION.' + pvGenerationUnit.installationPlace
            | translate
        "
        label="PV_GENERATION_UNIT_INFORMATION.INSTALLATION_PLACE"
      />
      <app-summary-section-field
        [value]="
          pvGenerationUnit.powerFlowMonitoring
            ? ('COMMON.YES' | translate)
            : ('COMMON.NO' | translate)
        "
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING"
      />
      <app-summary-section-field
        [value]="pvGenerationUnit.powerFlowMonitoringManufacturer"
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER"
      />
      <app-summary-section-field
        [value]="pvGenerationUnit.powerFlowMonitoringType"
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE"
      />
      <app-summary-section-field
        [value]="pvGenerationUnit.powerFlowMonitoringPower"
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_POWER"
      />
      <app-summary-section-field
        [value]="
          pvGenerationUnit.networkAndSystemProtectionDetailsLocation
            ? ('PV_GENERATION_UNIT_INFORMATION.' +
                pvGenerationUnit.networkAndSystemProtectionDetailsLocation
              | translate)
            : null
        "
        label="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS"
      />
      <app-summary-section-field
        [value]="pvGenerationUnit.networkAndSystemProtectionDetailsManufacturer"
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER"
      />
      <app-summary-section-field
        [value]="pvGenerationUnit.networkAndSystemProtectionDetailsType"
        label="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE"
      />
    }
  </div>
</app-summary-section>
