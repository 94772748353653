import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import { AppStateService } from "@app/services/app-state.service";
import { DateHelperService } from "@app/services/date-helper.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  templateUrl: "./construction-water-requirements.component.html",
  styleUrls: ["./construction-water-requirements.component.scss"],
})
export class ConstructionWaterRequirementsComponent
  implements OnInit, OnDestroy
{
  public requirementsForm!: FormGroup;
  public allTouched = false;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public dateAfterTwoWeeks = this.dateHelperService.getFutureDate(14);
  public errorMessageForInvalidDateFormat =
    this.dateHelperService.getErrorMessageForInvalidDateFormat();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private routeService: RouteService,
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private dateHelperService: DateHelperService,
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
  }

  private createForm(): void {
    this.requirementsForm = this.formBuilder.group({
      flowRate: [null, Validators.min(0)],
      convertedSpace: [null, [Validators.required, Validators.min(0)]],
      desiredInstallationDate: [null, Validators.required],
      desiredDeconstructionDate: null,
      notes: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ],
        },
      ],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.constructionWaterRequirements),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((constructionWaterRequirements) =>
        this.requirementsForm.patchValue(constructionWaterRequirements),
      );
  }

  private updateState(): void {
    this.appStateService.updateFormData({
      constructionWaterRequirements: this.requirementsForm.value,
    });
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.requirementsForm.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.requirementsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
