<div [ngClass]="showOutline ? 'outline' : ''" class="hint-wrapper">
  @if (showIcon()) {
    <mat-icon
      [fontIcon]="warning ? 'warning_amber' : 'sms_fail'"
      aria-hidden="false"
      aria-label="hint information icon"
      class="info-icon material-icons-outlined"
    />
  }
  <div>
    <ng-content />
  </div>
</div>
