<app-form-card
  titleIcon="fact_check"
  cardTitle="COMMISSIONING_ELECTRICITY.INFORMATION.HEADING"
  data-testid="commissioning-electricity-information-heading"
>
  <form [formGroup]="informationForm">
    <h2 class="full-width">
      {{ "COMMISSIONING_ELECTRICITY.INFORMATION.SUBHEADING" | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.INFORMATION.SYSTEM_POWER" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="systemPower"
          formControlName="systemPower"
          min="0"
          data-testid="system-power"
        />
        <mat-error appErrorMessages="systemPower" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "COMMISSIONING_ELECTRICITY.INFORMATION.CONNECTION_FUSE" | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="connectionFuse"
          formControlName="connectionFuse"
          min="0"
          data-testid="connection-fuse"
        />
        <mat-error appErrorMessages="connectionFuse" />
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2>
      <label id="construction-electricity-radio-group-label" appRequiredSuffix>
        {{
          "COMMISSIONING_ELECTRICITY.INFORMATION.CONSTRUCTION_ELECTRICITY"
            | translate
        }}</label
      >
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="constructionElectricity"
      aria-labelledby="construction-electricity-radio-group-label"
      class="input-container"
    >
      <div>
        <mat-radio-button [value]="true" data-testid="construction-electricity">
          {{ "COMMON.YES" | translate }}
        </mat-radio-button>
        <mat-radio-button
          [value]="false"
          data-testid="no-construction-electricity"
        >
          {{ "COMMON.NO" | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        [hideError]="!allTouched"
        appErrorMessages="constructionElectricity"
      />
    </mat-radio-group>
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: informationForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
