import { Pipe, PipeTransform, inject } from "@angular/core";
import { Observable, map } from "rxjs";

import { FLOW_MODULE } from "@app/models/form-data.interface";
import { FORM_TYPE } from "@app/models/registration-form";
import { WhiteLabelService } from "@app/services/white-label.service";

@Pipe({
  name: "isModuleOptionEnabled",
  standalone: true,
})
export class IsModuleOptionEnabledPipe implements PipeTransform {
  private readonly whiteLabelService = inject(WhiteLabelService);

  transform(option: FORM_TYPE, module: FLOW_MODULE): Observable<boolean> {
    return this.whiteLabelService.whiteLabelConfig$.pipe(
      map((whiteLabelConfig) => {
        if (!whiteLabelConfig) {
          return false;
        }
        const moduleSettings = whiteLabelConfig.moduleSettings;
        if (!moduleSettings) {
          return false;
        }
        const moduleSetting = moduleSettings.find(
          (setting) => setting.module === module,
        );
        if (!moduleSetting) {
          return false;
        }
        return !moduleSetting.disabledFlows.includes(option);
      }),
    );
  }
}
