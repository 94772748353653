<app-summary-section [stepRoute]="route" heading="STORAGE_DETAILS.HEADING">
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        'STORAGE_DETAILS.OPERATING_MODE.' + storageDetails.operatingMode
          | translate
      "
      label="STORAGE_DETAILS.OPERATING_MODE.TITLE"
    />
    <app-summary-section-field
      [value]="storageDetails.maxControllablePowerSupply"
      label="STORAGE_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY"
    />
    <app-summary-section-field
      [value]="
        'STORAGE_DETAILS.LOAD_MANAGEMENT.' + storageDetails.loadManagement
          | translate
      "
      label="STORAGE_DETAILS.LOAD_MANAGEMENT.TITLE"
    />
    <app-summary-section-field
      [value]="storageDetails.capacity"
      label="STORAGE_DETAILS.CAPACITY"
    />
    <app-summary-section-field
      [value]="
        'STORAGE_DETAILS.UNIT_COUPLING.' + storageDetails.unitCoupling
          | translate
      "
      label="STORAGE_DETAILS.UNIT_COUPLING.TITLE"
    />
    @if (storageDetails.unitCoupling === UNIT_COUPLING.OWN_INVERTER) {
      <app-summary-section-field
        [value]="storageDetails.systemManufacturer"
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_MANUFACTURER"
      /><app-summary-section-field
        [value]="storageDetails.systemType"
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_TYPE"
      /><app-summary-section-field
        [value]="storageDetails.systemCount"
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_COUNT"
      /><app-summary-section-field
        [value]="storageDetails.maxApparentPower"
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.MAX_APPARENT_POWER"
      /><app-summary-section-field
        [value]="
          (storageDetails.systemCount || 0) *
          (storageDetails.maxApparentPower || 0)
        "
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.MAX_APPARENT_POWER_SUM"
      />
      <app-summary-section-field
        [value]="
          (storageDetails.separateMeter ? 'COMMON.YES' : 'COMMON.NO')
            | translate
        "
        label="STORAGE_DETAILS.SEPARATE_METER.TITLE"
      />
      <app-summary-section-field
        [value]="
          (storageDetails.meterAvailable ? 'COMMON.YES' : 'COMMON.NO')
            | translate
        "
        label="STORAGE_DETAILS.METER_AVAILABLE.TITLE"
      />
      @if (storageDetails.meterAvailable) {
        <app-summary-section-field
          [value]="storageDetails.meterNumber"
          label="STORAGE_DETAILS.METER_NUMBER"
        />
      }
    }
  </div>
</app-summary-section>
