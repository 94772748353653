<app-form-card
  titleIcon="electric_bolt"
  cardTitle="HEAT_PUMP_INFORMATION.HEADING"
  dataTestId="heat-pump-information-heading"
>
  <p>
    {{ "HEAT_PUMP_INFORMATION.DESCRIPTION" | translate }}
  </p>

  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 appRequiredSuffix>
          {{ "HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.TITLE" | translate }}
        </h2>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              "HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.MANUFACTURER" | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              name="systemManufacturer"
              formControlName="systemManufacturer"
              data-testid="system-manufacturer"
            />
            <mat-error appErrorMessages="systemManufacturer" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.MANUFACTURER_TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              "HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.TYPE" | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              name="systemModel"
              formControlName="systemModel"
              data-testid="system-model"
            />
            <mat-error appErrorMessages="systemModel" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.TYPE_TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              "HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.NUMBER_OF_IDENTICAL_HEAT_PUMPS"
                | translate
            }}</mat-label>

            <input
              matInput
              type="number"
              name="systemCount"
              formControlName="systemCount"
              min="0"
              data-testid="system-count"
            />
            <mat-error appErrorMessages="systemCount" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="HEAT_PUMP_INFORMATION.HEAT_PUMP_DATA.NUMBER_OF_IDENTICAL_HEAT_PUMPS_TOOLTIP"
          />
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              "HEAT_PUMP_INFORMATION.TYPE_OF_HEAT_PUMP.TITLE" | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="HEAT_PUMP_INFORMATION.TYPE_OF_HEAT_PUMP.TOOLTIP"
          />
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="systemType"
          class="input-container"
        >
          @for (
            heatPumpSystemType of heatPumpSystemTypes;
            track heatPumpSystemType
          ) {
            <mat-radio-button
              [value]="heatPumpSystemType"
              [attr.data-testid]="heatPumpSystemType"
              >{{
                "HEAT_PUMP_INFORMATION.TYPE_OF_HEAT_PUMP." + heatPumpSystemType
                  | translate
              }}</mat-radio-button
            >
          }
          <mat-error [hideError]="!allTouched" appErrorMessages="systemType" />
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 appRequiredSuffix>
          {{ "HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.TITLE" | translate }}
        </h2>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              "HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.POWER" | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="power"
              formControlName="power"
              min="0"
              data-testid="power"
            />
            <mat-error appErrorMessages="power" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.POWER_TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              "HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.ADDITIONAL_HEATING_POWER"
                | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="additionalHeatingPower"
              formControlName="additionalHeatingPower"
              min="0"
              data-testid="additional-heating-power"
            />
            <mat-error appErrorMessages="additionalHeatingPower" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.ADDITIONAL_HEATING_POWER_TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              "HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.MAX_STARTING_CURRENT"
                | translate
            }}</mat-label>

            <input
              matInput
              type="number"
              name="maxStartingCurrent"
              formControlName="maxStartingCurrent"
              min="0"
              data-testid="max-starting-current"
            />
            <mat-error appErrorMessages="maxStartingCurrent" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.MAX_STARTING_CURRENT_TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              "HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.MAX_GRID_POWER"
                | translate
            }}</mat-label>

            <input
              matInput
              type="number"
              name="maxGridPower"
              formControlName="maxGridPower"
              min="0"
              data-testid="max-grid-power"
            />
            <mat-error appErrorMessages="maxGridPower" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="HEAT_PUMP_INFORMATION.PERFORMANCE_DATA.MAX_GRID_POWER_TOOLTIP"
          />
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              "HEAT_PUMP_INFORMATION.MEASUREMENT_VIA_SEPARATE_METER.TITLE"
                | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="HEAT_PUMP_INFORMATION.MEASUREMENT_VIA_SEPARATE_METER.TOOLTIP"
          />
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="separateMeter"
          class="input-container"
        >
          <mat-radio-button [value]="true" data-testid="separate-meter">{{
            "COMMON.YES" | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="false" data-testid="no-separate-meter">
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="separateMeter"
          />
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              "HEAT_PUMP_INFORMATION.METER_ALREADY_EXISTS.TITLE" | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="HEAT_PUMP_INFORMATION.METER_ALREADY_EXISTS.TOOLTIP"
          />
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="meterAvailable"
          class="input-container"
        >
          <mat-radio-button [value]="true" data-testid="meter-available">{{
            "COMMON.YES" | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="false" data-testid="meter-not-available">
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="meterAvailable"
          />
        </mat-radio-group>

        @if (form.get("meterAvailable")?.value) {
          <div class="input-container">
            <mat-form-field
              appearance="outline"
              class="full-width-flex-element"
            >
              <mat-label
                >{{
                  "HEAT_PUMP_INFORMATION.METER_ALREADY_EXISTS.METER_NUMBER"
                    | translate
                }}
              </mat-label>
              <input
                matInput
                name="meterNumber"
                formControlName="meterNumber"
                data-testid="meter-number"
              />
              <mat-error appErrorMessages="meterNumber" />
            </mat-form-field>
          </div>
        }
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              "HEAT_PUMP_INFORMATION.OPERATIONAL_PURPOSE_OR_CRITICAL_INFRASTRUCTURE.TITLE"
                | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="HEAT_PUMP_INFORMATION.OPERATIONAL_PURPOSE_OR_CRITICAL_INFRASTRUCTURE.TOOLTIP"
          />
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="operationalPurposeOrCriticalInfrastructure"
          class="input-container"
        >
          <mat-radio-button
            [value]="true"
            data-testid="used-for-operational-purpose-or-critical-infrastructure"
            >{{ "COMMON.YES" | translate }}</mat-radio-button
          >
          <mat-radio-button
            [value]="false"
            data-testid="not-used-for-operational-purpose-or-critical-infrastructure"
          >
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="operationalPurposeOrCriticalInfrastructure"
          />
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "HEAT_PUMP_INFORMATION.LOAD_MANAGEMENT.TITLE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="HEAT_PUMP_INFORMATION.LOAD_MANAGEMENT.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="loadManagement"
          aria-labelledby="loadManagement-radio-group-label"
          class="input-container"
        >
          @for (loadManagement of loadManagementOptions; track loadManagement) {
            <mat-radio-button
              [value]="loadManagement"
              [attr.data-testid]="loadManagement"
            >
              {{
                "HEAT_PUMP_INFORMATION.LOAD_MANAGEMENT." + loadManagement
                  | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="loadManagement"
          />
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
