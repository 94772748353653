<app-form-card
  titleIcon="electric_bolt"
  cardTitle="OTHER_2_SYSTEM_DETAILS.HEADING"
  dataTestId="other-2-system-details-heading"
>
  <p>
    {{ "OTHER_2_SYSTEM_DETAILS.DESCRIPTION" | translate }}
  </p>
  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2>
          {{ "OTHER_2_SYSTEM_DETAILS.SYSTEM.TITLE" | translate }}
        </h2>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "OTHER_2_SYSTEM_DETAILS.SYSTEM_MANUFACTURER" | translate }}
            </mat-label>
            <input
              matInput
              name="systemManufacturer"
              formControlName="systemManufacturer"
              data-testId="system-manufacturer"
            />
            <mat-error appErrorMessages="systemManufacturer" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="OTHER_2_SYSTEM_DETAILS.SYSTEM_MANUFACTURER.TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "OTHER_2_SYSTEM_DETAILS.SYSTEM_MODEL" | translate }}
            </mat-label>
            <input
              matInput
              name="systemModel"
              formControlName="systemModel"
              data-testId="system-model"
            />
            <mat-error appErrorMessages="systemModel" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="OTHER_2_SYSTEM_DETAILS.SYSTEM_MODEL.TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "OTHER_2_SYSTEM_DETAILS.SYSTEM_COUNT" | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              name="systemCount"
              formControlName="systemCount"
              min="0"
              data-testId="system-count"
            />
            <mat-error appErrorMessages="systemCount" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="OTHER_2_SYSTEM_DETAILS.SYSTEM_COUNT.TOOLTIP"
          />
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2>
          <span appRequiredSuffix>{{
            "OTHER_2_SYSTEM_DETAILS.SYSTEM_TYPE" | translate
          }}</span>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="systemType"
          aria-labelledby="system-type-radio-group-label"
          class="input-container"
        >
          @for (systemType of OTHER_SYSTEM_TYPE; track systemType) {
            <mat-radio-button
              [value]="systemType"
              [attr.data-testId]="systemType"
            >
              {{
                "OTHER_2_SYSTEM_DETAILS.SYSTEM_TYPE." + systemType | translate
              }}
            </mat-radio-button>
          }
          <mat-error [hideError]="!allTouched" appErrorMessages="systemType" />
        </mat-radio-group>
        @if (form.get("systemType")?.value === OTHER_SYSTEM_TYPE_OTHER) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix"
            >
              <mat-label
                >{{ "OTHER_2_SYSTEM_DETAILS.OTHER_SYSTEM_TYPE" | translate }}
              </mat-label>
              <input
                matInput
                name="otherSystemType"
                formControlName="otherSystemType"
                data-testId="other-system-type"
              />
              <mat-error appErrorMessages="otherSystemType" />
            </mat-form-field>
          </div>
        }
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2>
          {{ "OTHER_2_SYSTEM_DETAILS.POWER.TITLE" | translate }}
        </h2>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "OTHER_2_SYSTEM_DETAILS.POWER" | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              name="power"
              formControlName="power"
              min="0"
              data-testId="power"
            />
            <mat-error appErrorMessages="power" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="OTHER_2_SYSTEM_DETAILS.POWER.TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{ "OTHER_2_SYSTEM_DETAILS.MAX_STARTING_CURRENT" | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              name="maxStartingCurrent"
              formControlName="maxStartingCurrent"
              min="0"
              data-testId="max-starting-current"
            />
            <mat-error appErrorMessages="maxStartingCurrent" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="OTHER_2_SYSTEM_DETAILS.MAX_STARTING_CURRENT.TOOLTIP"
          />
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label
              >{{
                "OTHER_2_SYSTEM_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY"
                  | translate
              }}
            </mat-label>
            <input
              matInput
              type="number"
              name="maxControllablePowerSupply"
              formControlName="maxControllablePowerSupply"
              min="0"
              data-testId="max-controllable-power-supply"
            />
            <mat-error appErrorMessages="maxControllablePowerSupply" />
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="OTHER_2_SYSTEM_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY.TOOLTIP"
          />
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "OTHER_2_SYSTEM_DETAILS.SEPARATE_METER.TITLE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_SYSTEM_DETAILS.SEPARATE_METER.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="separateMeter"
          aria-labelledby="separateMeter-radio-group-label"
          class="input-container"
        >
          <mat-radio-button [value]="true" data-testId="separate-meter-yes">
            {{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" data-testId="separate-meter-no">
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="separateMeter"
          />
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "OTHER_2_SYSTEM_DETAILS.METER_AVAILABLE.TITLE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_SYSTEM_DETAILS.METER_AVAILABLE.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="meterAvailable"
          aria-labelledby="meterAvailable-radio-group-label"
          class="input-container"
        >
          <mat-radio-button [value]="true" data-testId="meter-available-yes">
            {{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" data-testId="meter-available-no">
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="meterAvailable"
          />
        </mat-radio-group>
        @if (form.get("meterAvailable")?.value === true) {
          <div class="input-container">
            <mat-form-field
              appearance="outline"
              class="full-width-flex-element"
            >
              <mat-label
                >{{ "OTHER_2_SYSTEM_DETAILS.METER_NUMBER" | translate }}
              </mat-label>
              <input
                matInput
                name="meterNumber"
                formControlName="meterNumber"
                data-testId="meter-number"
              />
              <mat-error appErrorMessages="meterNumber" />
            </mat-form-field>
          </div>
        }
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "OTHER_2_SYSTEM_DETAILS.CRITICAL_INFRASTRUCTURE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_SYSTEM_DETAILS.CRITICAL_INFRASTRUCTURE.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="operationalPurposeOrCriticalInfrastructure"
          aria-labelledby="critical-infrastructure-radio-group-label"
          class="input-container"
        >
          <mat-radio-button
            [value]="true"
            data-testId="critical-infrastructure-yes"
          >
            {{ "COMMON.YES" | translate }}
          </mat-radio-button>
          <mat-radio-button
            [value]="false"
            data-testId="critical-infrastructure-no"
          >
            {{ "COMMON.NO" | translate }}
          </mat-radio-button>
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="operationalPurposeOrCriticalInfrastructure"
          />
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            "OTHER_2_SYSTEM_DETAILS.LOAD_MANAGEMENT.TITLE" | translate
          }}</span>
          <app-info-tooltip
            tooltipText="OTHER_2_SYSTEM_DETAILS.LOAD_MANAGEMENT.TOOLTIP"
          />
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="loadManagement"
          aria-labelledby="load-management-radio-group-label"
          class="input-container"
        >
          @for (loadManagement of LOAD_MANAGEMENT; track loadManagement) {
            <mat-radio-button
              [value]="loadManagement"
              [attr.data-testId]="loadManagement"
            >
              {{
                "OTHER_2_SYSTEM_DETAILS.LOAD_MANAGEMENT." + loadManagement
                  | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            [hideError]="!allTouched"
            appErrorMessages="loadManagement"
          />
        </mat-radio-group>
      </div>
    </div>
  </form>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
