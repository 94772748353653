import { Component, Input } from "@angular/core";

import {
  CommissioningElectricityBuildingDetails,
  CommissioningElectricityInformation,
} from "@app/models/form-data.interface";
import { ROUTES } from "@app/models/routes.enum";

@Component({
  selector: "app-new-grid-connection-summary",
  templateUrl: "./new-grid-connection-summary.component.html",
})
export class NewGridConnectionSummaryComponent {
  @Input({ required: true })
  public buildingDetails!: CommissioningElectricityBuildingDetails;
  @Input({ required: true })
  public electricityInformation!: CommissioningElectricityInformation;
  public readonly buildingDetailsRoute: ROUTES =
    ROUTES.COMMISSIONING_ELECTRICITY_PROPERTY_TYPE;
  public readonly electricityInformationRoute: ROUTES =
    ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION;
}
