import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, filter, map, take, takeUntil } from "rxjs";

import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

@Component({
  selector: "app-water-requirements",
  templateUrl: "./water-requirements.component.html",
  styleUrls: ["./water-requirements.component.scss"],
})
export class WaterRequirementsComponent implements OnInit, OnDestroy {
  public waterRequirementsForm!: FormGroup;
  public allTouched = false;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private routeService: RouteService,
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.watchForm();
  }

  private createForm(): void {
    this.waterRequirementsForm = this.formBuilder.group({
      numberOfHousingUnits: [null, [Validators.required, Validators.min(0)]],
      numberOfResidents: [null, [Validators.min(0)]],
      numberOfFloors: [null, [Validators.required, Validators.min(0)]],
      extractionPointHeight: [null, [Validators.required, Validators.min(0)]],
      flowRate: [null, [Validators.min(0)]],
      additionalConsumers: [
        null,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.waterRequirementDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((waterRequirementDetails) =>
        this.waterRequirementsForm.patchValue(waterRequirementDetails),
      );
  }

  private watchForm(): void {
    this.waterRequirementsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((waterRequirementDetails) =>
        this.appStateService.updateFormData({
          waterRequirementDetails,
        }),
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.waterRequirementsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.waterRequirementsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
