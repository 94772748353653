<app-form-card titleIcon="home" cardTitle="GAS.GAS_PROPERTY_TYPE.HEADING">
  <h2 class="full-width">
    {{ "GAS.GAS_PROPERTY_TYPE.DESCRIPTION" | translate }}
  </h2>
  <app-selection-group>
    @for (property of propertyDetailsList; track property) {
      <app-selection-card
        (click)="selectPropertyType(property.type)"
        [icon]="property.icon"
        [label]="property.label"
        [selected]="selectedPropertyType === property.type"
      />
    }

    @if (allTouched && !selectedPropertyType) {
      <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
    }
  </app-selection-group>

  <div class="space"></div>

  <form [formGroup]="propertyDetailsForm">
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "SHARED.BUILDING_STRUCTURE.LABEL" | translate
        }}</mat-label>
        <mat-select formControlName="buildingStructure">
          @for (structureType of buildingStructures; track structureType) {
            <mat-option [value]="structureType">
              {{ "SHARED.BUILDING_STRUCTURE." + structureType | translate }}
            </mat-option>
          }
        </mat-select>
        <mat-error appErrorMessages="buildingStructure" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          "GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE.LABEL" | translate
        }}</mat-label>
        <mat-select formControlName="currentEnergySource">
          @for (energySource of currentEnergySources; track energySource) {
            <mat-option [value]="energySource">
              {{
                "GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE." +
                  energySource | translate
              }}
            </mat-option>
          }
        </mat-select>
        <mat-error appErrorMessages="currentEnergySource" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ "SHARED.HOUSE_INLET.LABEL" | translate }}</mat-label>
        <mat-select formControlName="houseInlet">
          @for (inlet of houseInlets; track inlet) {
            <mat-option [value]="inlet">
              {{ "SHARED.HOUSE_INLET." + inlet | translate }}
            </mat-option>
          }
        </mat-select>
        <mat-error appErrorMessages="houseInlet" />
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: propertyDetailsForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
