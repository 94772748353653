<app-form-card
  titleIcon="category"
  cardTitle="PRODUCTS.HEADING"
  dataTestId="product-selection-heading"
>
  <form>
    <h2 class="full-width">{{ "PRODUCTS.DESCRIPTION" | translate }}</h2>
    <app-selection-group>
      @for (product of productDetailsList; track product) {
        @if (product.type | isModuleOptionEnabled: electricityModule | async) {
          <app-selection-card
            (click)="selectProduct(product.type)"
            [icon]="product.icon"
            [svgIcon]="product.svgIcon"
            [label]="'PRODUCTS.' + product.type"
            [selected]="selectedProduct === product.type"
            [attr.data-testid]="product.type"
          />
        }
      }

      @if (allTouched && productTypeControl.invalid) {
        <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
      }
    </app-selection-group>

    @if (selectedProduct) {
      <!-- eslint-disable-next-line @angular-eslint/template/prefer-control-flow -->
      <ng-container [ngSwitch]="selectedProduct">
        <div class="space"></div>
        <app-hint *ngSwitchCase="formType.PV_2" [showIcon]="false">
          <app-selected-pv
            [showHints]="isBalconyPVForm.get('isBalconyPV')?.value === false"
          >
            <form [formGroup]="isBalconyPVForm">
              <mat-radio-group
                name="isBalconyPV"
                color="primary"
                formControlName="isBalconyPV"
              >
                <mat-radio-button
                  [value]="true"
                  data-testid="isBalconyPVControl-true"
                  >{{ "COMMON.YES" | translate }}</mat-radio-button
                >
                <mat-radio-button
                  [value]="false"
                  data-testid="isBalconyPVControl-false"
                  >{{ "COMMON.NO" | translate }}</mat-radio-button
                >
                <mat-error
                  [hideError]="!allTouched"
                  appErrorMessages="isBalconyPV"
                />
              </mat-radio-group>
            </form>
          </app-selected-pv>
        </app-hint>

        <ng-container *ngSwitchCase="formType.STORAGE_2">
          <app-hint [warning]="true" [showOutline]="true">
            {{ "PRODUCTS.STORAGE_2_SELECTED.INSTALLER_HINT" | translate }}
          </app-hint>
          <div class="space"></div>
          <app-hint>
            <div>
              {{ "PRODUCTS.STORAGE_2_SELECTED.INFO" | translate }}
              <ul class="list-container">
                <li>
                  {{ "PRODUCTS.STORAGE_2_SELECTED.DOCUMENT_1" | translate }}
                </li>
                <li>
                  {{ "PRODUCTS.STORAGE_2_SELECTED.DOCUMENT_2" | translate }}
                </li>
                <li>
                  {{ "PRODUCTS.STORAGE_2_SELECTED.DOCUMENT_3" | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
        </ng-container>

        <ng-container *ngSwitchCase="formType.CHARGING_DEVICE_2">
          <app-hint [warning]="true">
            {{
              "PRODUCTS.CHARGING_DEVICE_SELECTED.WARNING" | translate
            }}</app-hint
          >
          <div class="space"></div>
          <app-hint>
            <div>
              {{ "PRODUCTS.CHARGING_DEVICE_SELECTED.INFO" | translate }}
              <ul class="list-container">
                <li>
                  {{ "PRODUCTS.CHARGING_DEVICE_SELECTED.DOCUMENT" | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
        </ng-container>

        <app-hint *ngSwitchCase="formType.GRID_CONNECTION">
          <div>
            {{ "PRODUCTS.GRID_CONNECTION_SELECTED.INFO_1" | translate }}
            <ul class="list-container">
              <li>
                {{ "PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_1" | translate }}
              </li>
              <li>
                {{ "PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_2" | translate }}
              </li>
              <li>
                {{ "PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_3" | translate }}
              </li>
              <li>
                {{ "PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_4" | translate }}
              </li>
            </ul>
          </div>

          <div class="space"></div>

          <div>
            {{ "PRODUCTS.GRID_CONNECTION_SELECTED.INFO_2" | translate }}
            <ul class="list-container">
              <li>
                {{ "PRODUCTS.GRID_CONNECTION_SELECTED.DOCUMENT_1" | translate }}
              </li>
              <li>
                {{ "PRODUCTS.GRID_CONNECTION_SELECTED.DOCUMENT_2" | translate }}
              </li>
              <li>
                {{ "PRODUCTS.GRID_CONNECTION_SELECTED.DOCUMENT_3" | translate }}
              </li>
            </ul>
            <p>
              {{ "PRODUCTS.GRID_CONNECTION_SELECTED.OTHER_INFO" | translate }}
            </p>
          </div>
        </app-hint>

        <ng-container *ngSwitchCase="formType.HEAT_PUMP_2">
          <app-hint [warning]="true">
            {{ "PRODUCTS.HEAT_PUMP_SELECTED.WARNING" | translate }}</app-hint
          >
          <div class="space"></div>
          <app-hint>
            <div>
              {{ "PRODUCTS.HEAT_PUMP_SELECTED.INFO" | translate }}
              <ul class="list-container">
                <li>
                  {{ "PRODUCTS.HEAT_PUMP_SELECTED.DOCUMENT" | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
        </ng-container>

        <ng-container *ngSwitchCase="formType.CONSTRUCTION_ELECTRICITY">
          <app-hint [warning]="true">
            {{
              "PRODUCTS.CONSTRUCTION_ELECTRICITY_SELECTED.WARNING" | translate
            }}</app-hint
          >
          <div class="space"></div>
          <app-hint>
            <div>
              {{
                "PRODUCTS.CONSTRUCTION_ELECTRICITY_SELECTED.INFO" | translate
              }}
              <ul class="list-container">
                <li>
                  {{
                    "PRODUCTS.CONSTRUCTION_ELECTRICITY_SELECTED.DOCUMENT_1"
                      | translate
                  }}
                </li>
                <li>
                  {{
                    "PRODUCTS.CONSTRUCTION_ELECTRICITY_SELECTED.DOCUMENT_2"
                      | translate
                  }}
                </li>
              </ul>
            </div>
          </app-hint>
        </ng-container>

        <ng-container *ngSwitchCase="formType.OTHER_2">
          <app-hint [warning]="true" [showOutline]="true">
            {{ "PRODUCTS.OTHER_2_SELECTED.INSTALLER_HINT" | translate }}
          </app-hint>
          <div class="space"></div>
          <app-hint>
            {{ "PRODUCTS.OTHER_2_SELECTED.INFO_NEW" | translate }}
            {{ "PRODUCTS.OTHER_2_SELECTED.INFO_CHANGE" | translate }}</app-hint
          >
        </ng-container>
      </ng-container>
    }
  </form>

  <div class="space"></div>

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: productTypeControl.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
