<div
  [class.selected-card]="selected"
  [class.vertical]="vertical"
  [class.horizontal]="!vertical"
  class="selection-card"
  data-testid="selection-card"
>
  <div
    [class.selected-card]="selected"
    [class.vertical]="vertical"
    [class.horizontal]="!vertical"
    class="selection-card-icon-container"
  >
    <mat-icon
      [fontIcon]="icon || ''"
      [svgIcon]="svgIcon || ''"
      [class.material-symbols-outlined]="symbolOutlinedIcon"
      [class.vertical]="vertical"
      [class.horizontal]="!vertical"
      aria-hidden="false"
      aria-label="selection-card icon"
      class="selection-card-icon material-icons-outlined"
    />
  </div>
  <div
    [lang]="currentLang"
    [class.vertical]="vertical"
    [class.horizontal]="!vertical"
    class="selection-card-name-container"
  >
    <span>
      {{ label | translate }}
    </span>
    @if (showCheckbox) {
      <mat-checkbox
        [checked]="selected"
        class="fake-checkbox"
        color="primary"
      />
    }
  </div>
</div>
