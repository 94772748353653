<app-form-card titleIcon="category" cardTitle="WATER.SEWAGE_OPTIONS.HEADING">
  <h2 class="full-width">
    {{ "WATER.SEWAGE_OPTIONS.DESCRIPTION" | translate }}
  </h2>
  <app-selection-group>
    @for (sewageOption of sewageOptionsList; track sewageOption) {
      <app-selection-card
        (click)="selectType(sewageOption.type)"
        [icon]="sewageOption.icon"
        [label]="sewageOption.label"
        [selected]="selectedOption === sewageOption.type"
        class="selection-card"
      />
    }
    @if (!selectedOption && allTouched) {
      <mat-error>{{ "VALIDATION.REQUIRED_SELECTION" | translate }}</mat-error>
    }
  </app-selection-group>

  @if (selectedOption) {
    <div class="space"></div>
    <form [formGroup]="sewageOptionsForm">
      @switch (selectedOption) {
        @case (sewageOptions.NEW) {
          <app-hint>
            <div>
              {{ "WATER.SEWAGE_OPTIONS.NEW.INFO" | translate }}
              <ul class="list-container">
                <li>
                  {{ "SHARED.DOCUMENT_TYPE.SITE_PLAN" | translate }}
                </li>
                <li>
                  {{ "SHARED.DOCUMENT_TYPE.FLOOR_PLAN" | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
        }
        @case (sewageOptions.DETACH) {
          <div>
            <app-hint>
              <div>
                {{ "WATER.SEWAGE_OPTIONS.DETACH.INFO" | translate }}
                <ul class="list-container">
                  <li>
                    {{ "SHARED.DOCUMENT_TYPE.PROOF_OF_OWNERSHIP" | translate }}
                  </li>
                  <li>
                    {{ "SHARED.DOCUMENT_TYPE.SITE_PLAN" | translate }}
                  </li>
                </ul>
              </div>
            </app-hint>
            <div class="space"></div>
            <h2>
              {{
                ("WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_TYPE_QUESTION"
                  | translate) + " *"
              }}
            </h2>
            <mat-radio-group
              formControlName="permanentDeconstruction"
              class="input-container"
            >
              <div class="deconstruction-type-container">
                <mat-radio-button [value]="false" color="primary"
                  >{{
                    "WATER.SEWAGE_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_LABEL"
                      | translate
                  }}
                </mat-radio-button>
                <app-info-tooltip
                  tooltipText="WATER.SEWAGE_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_TOOLTIP"
                />
              </div>
              <div class="deconstruction-type-container">
                <mat-radio-button [value]="true" color="primary">
                  {{
                    "WATER.SEWAGE_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_LABEL"
                      | translate
                  }}
                </mat-radio-button>
                <app-info-tooltip
                  tooltipText="WATER.SEWAGE_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_TOOLTIP"
                />
              </div>
              <mat-error
                [hideError]="!allTouched"
                appErrorMessages="permanentDeconstruction"
              />
            </mat-radio-group>
            @if (
              (sewageOptionsForm | formLookup: "permanentDeconstruction") ===
              false
            ) {
              <div class="input-container">
                <mat-form-field appearance="outline" class="input-field">
                  <mat-label>{{
                    "WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_UNTIL"
                      | translate
                  }}</mat-label>
                  <input
                    [min]="dateForTomorrow"
                    [matDatepicker]="picker"
                    matInput
                    formControlName="deconstructionUntil"
                  />
                  <mat-datepicker-toggle [for]="picker" matIconSuffix />
                  <mat-datepicker #picker />
                  @if (
                    sewageOptionsForm
                      | hasError: "deconstructionUntil" : "required"
                  ) {
                    <mat-error
                      >{{ errorMessageForInvalidDateFormat }}
                    </mat-error>
                  }
                  @if (
                    sewageOptionsForm
                      | hasError: "deconstructionUntil" : "matDatepickerMin"
                  ) {
                    <mat-error
                      >{{ "VALIDATION.DATE_PICKER_MIN" | translate }}
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            }
            <h2 class="tooltip-heading-label">
              {{ "WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER" | translate }}
              <app-info-tooltip
                tooltipText="WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER_TOOLTIP"
              />
            </h2>
            <div class="input-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>
                  {{ "WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER" | translate }}
                </mat-label>
                <input
                  matInput
                  type="text"
                  name="meterNumber"
                  formControlName="meterNumber"
                />
                <mat-error appErrorMessages="meterNumber" />
              </mat-form-field>
            </div>
          </div>
        }
      }
    </form>
  }

  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: sewageOptionsForm.invalid,
    }"
    slot="navigation"
  />
</app-form-card>
