import { Component, Input } from "@angular/core";

import {
  MEASUREMENT_DEVICE_CHANGE_TYPE,
  MeasurementDetails,
  METER_LOCATION,
} from "@app/models/commissioning-mapped-data.interface";

@Component({
  selector: "app-complex-measurement-detail-summary",
  templateUrl: "./complex-measurement-detail-summary.component.html",
})
export class ComplexMeasurementDetailSummaryComponent {
  @Input({ required: true })
  public measurementDetail!: MeasurementDetails;
  public MEASUREMENT_DEVICE_CHANGE_TYPE = MEASUREMENT_DEVICE_CHANGE_TYPE;
  public METER_LOCATION = METER_LOCATION;
}
