import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { getLanguageLocaleCulture } from "@app/constants/language";
import { PvGenerationUnit, PvInverter } from "@app/models/form-data.interface";
import { ROUTES } from "@app/models/routes.enum";

import { PVCustomerFacilityDto } from "./../../../../../models/registration-form";
import { SharedModule } from "../../../../shared/shared.module";

@Component({
  selector: "app-pv-summary",
  standalone: true,
  imports: [SharedModule, TranslateModule],
  templateUrl: "./pv-summary.component.html",
})
export class PvSummaryComponent {
  public readonly stepRoute = input.required<ROUTES>();
  public readonly pvCustomerFacility = input.required<PVCustomerFacilityDto>();
  public readonly pvInverter = input.required<PvInverter>();
  public readonly pvGenerationUnit = input.required<PvGenerationUnit>();

  readonly #translateService = inject(TranslateService);

  public readonly ROUTES = ROUTES;

  public currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang,
  );

  public controllableConsumptionSystemsTranslated = computed(() =>
    this.pvCustomerFacility()
      .controllableConsumptionSystems.map((system) =>
        this.#translateService.instant(
          `CONTROLLABLE_CONSUMPTION_SYSTEM.${system}`,
        ),
      )
      .join(", "),
  );
}
