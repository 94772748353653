<app-summary-section
  [stepRoute]="route"
  heading="HEAT_PUMP_SYSTEM_INFORMATION.HEADING"
>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="
        (heatPumpSystemInformation.plannedCommissioningDate
          | date: 'shortDate' : '' : currentLanguageCulture) || undefined
      "
      label="HEAT_PUMP_SYSTEM_INFORMATION.PLANNED_COMMISSIONING_DATE.TITLE"
    />

    <app-summary-section-field
      [value]="
        (heatPumpSystemInformation.controllable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      "
      label="HEAT_PUMP_SYSTEM_INFORMATION.CONTROLLABLE_SYSTEM_COMPLIANT.TITLE"
    />
  </div>
</app-summary-section>
