import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatError } from "@angular/material/form-field";
import { TranslatePipe } from "@ngx-translate/core";
import { filter, map, take } from "rxjs";

import { OTHER_TYPE } from "@app/models/registration-form";
import { SharedModule } from "@app/modules/shared/shared.module";
import { AppStateService } from "@app/services/app-state.service";
import { RouteService } from "@app/services/route.service";
import { NextButtonDisabledPipe } from "@app/shared/pipes/next-button-disabled.pipe";

interface OtherDetails {
  icon?: string;
  svgIcon?: string;
  label: string;
  type: OTHER_TYPE;
}

@Component({
  selector: "app-other-type",
  standalone: true,
  imports: [MatError, NextButtonDisabledPipe, SharedModule, TranslatePipe],
  templateUrl: "./other-type.component.html",
})
export class OtherTypeComponent implements OnInit {
  public readonly typeSelectionList: OtherDetails[] = [
    {
      icon: "add_circle",
      label: "OTHER_2.TYPE.NEW",
      type: OTHER_TYPE.NEW,
    },
    {
      icon: "settings",
      label: "OTHER_2.TYPE.CHANGE",
      type: OTHER_TYPE.CHANGE,
    },
  ];

  public readonly OTHER_TYPE = OTHER_TYPE;
  public selectedType?: OTHER_TYPE;
  public form!: FormGroup;
  public allTouched = false;

  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.#createForm();
    this.#updateForm();
  }

  #createForm(): void {
    this.form = this.#formBuilder.group({
      type: [null, Validators.required],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.otherType),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((otherType) => this.selectType(otherType));
  }

  public selectType(selectedType: OTHER_TYPE): void {
    this.form.get("type")?.setValue(selectedType);
    this.selectedType = selectedType;
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  #updateState(): void {
    this.#appStateService.updateFormData({
      otherType: this.form.get("type")?.value,
    });
  }
}
