@if (_previousConfig.visible) {
  <button
    (click)="previous.emit()"
    [disabled]="_previousConfig.disabled"
    type="button"
    color="primary"
    mat-raised-button
    data-testid="previous-button"
  >
    @if (_previousConfig.arrow) {
      <mat-icon fontIcon="arrow_back" />
    }
    @if (_previousConfig.label) {
      <span> {{ _previousConfig.label | translate }}</span>
    }
  </button>
}
<div class="space"></div>
@if (_nextConfig.visible) {
  <button
    (click)="next.emit()"
    [disabled]="_nextConfig.disabled"
    type="button"
    color="primary"
    mat-raised-button
    data-testid="next-button"
  >
    @if (_nextConfig.arrow) {
      <mat-icon fontIcon="arrow_forward" />
    }
    @if (_nextConfig.label) {
      <span> {{ _nextConfig.label | translate }}</span>
    }
  </button>
}
