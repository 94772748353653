import { Component, inject, Input } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

import { getLanguageLocaleCulture } from "@app/constants/language";
import {
  ControllableDetailsDto,
  CONTROLLER,
} from "@app/models/registration-form";
import { ROUTES } from "@app/models/routes.enum";
import { SharedModule } from "@app/modules/shared/shared.module";

@Component({
  selector: "app-other-controllable-details-summary",
  standalone: true,
  imports: [TranslatePipe, SharedModule],
  templateUrl: "./other-controllable-details-summary.component.html",
})
export class OtherControllableDetailsSummaryComponent {
  @Input({ required: true })
  public controllableDetails!: ControllableDetailsDto;
  readonly #translateService = inject(TranslateService);

  public readonly route = ROUTES.OTHER_2_CONTROLLABLE_SYSTEM;
  public readonly CONTROLLER = CONTROLLER;

  public currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang,
  );
}
