import {
  Component,
  computed,
  inject,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { filter, map, Subject, take, takeUntil } from "rxjs";

import { DocumentDetails } from "@app/constants/document-mappings";
import {
  DocumentDetailsWithFile,
  DocumentWithFile,
  FLOW,
} from "@app/models/form-data.interface";
import { DOCUMENT_TYPE } from "@app/models/registration-form";
import { FileSizePipe } from "@app/modules/common-steps/pipes/filesize.pipe";
import {
  AppStateService,
  PartialFormData,
} from "@app/services/app-state.service";
import { DocumentMapperService } from "@app/services/document-mapper.service";
import { EnvironmentService } from "@app/services/environment.service";
import { RouteService } from "@app/services/route.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";

interface FileType {
  type: string;
  label: string;
}

export const acceptedFileTypes: FileType[] = [
  { type: "application/pdf", label: ".pdf" },
  { type: "image/jpg", label: ".jpg" },
  { type: "image/jpeg", label: ".jpeg" },
  { type: "image/png", label: ".png" },
];

@Component({
  selector: "app-documents-upload",
  templateUrl: "./documents-upload.component.html",
  styleUrls: ["./documents-upload.component.scss"],
  providers: [FileSizePipe],
})
export class DocumentsUploadComponent implements OnInit, OnDestroy {
  readonly #appStateService = inject(AppStateService);
  readonly #formBuilder = inject(FormBuilder);
  readonly #routeService = inject(RouteService);
  readonly #documentMapperService = inject(DocumentMapperService);
  readonly #environmentService = inject(EnvironmentService);
  readonly #translateService = inject(TranslateService);
  readonly #fileSizePipe = inject(FileSizePipe);

  private readonly storageDocLink =
    "https://oneportalpublic.blob.core.windows.net/public/E.3%20Datenblatt%20fuer%20Speicher.pdf";
  private readonly dataSheetDocLink =
    "https://oneportalpublic.blob.core.windows.net/public/E.2%20Datenblatt%20fu%CC%88r%20Erzeugungsanlagen.pdf";
  private readonly unitCertificateDocLink =
    "https://oneportalpublic.blob.core.windows.net/public/E.4%20Einheitenzertifikat.pdf";
  private readonly protectionCertificateDocLink =
    "https://oneportalpublic.blob.core.windows.net/public/E.6%20Zertifikat%20NA%20Schutz.pdf";
  private readonly commissioningProtocolLink =
    "https://oneportalpublic.blob.core.windows.net/public/E.8%20Inbetriebsetzungsprotokoll.pdf";

  private readonly downloadLinksByType = new Map<string, string>([
    [DOCUMENT_TYPE.DATA_SHEET_STORAGE, this.storageDocLink],
    [DOCUMENT_TYPE.DATA_SHEET_GENERATION_PLANTS, this.dataSheetDocLink],
    [
      DOCUMENT_TYPE.UNIT_CERTIFICATE_GENERATION_PLANT,
      this.unitCertificateDocLink,
    ],
    [DOCUMENT_TYPE.UNIT_CERTIFICATE, this.unitCertificateDocLink],
    [DOCUMENT_TYPE.UNIT_CERTIFICATE_STORAGE, this.unitCertificateDocLink],
    [
      DOCUMENT_TYPE.GRID_SYSTEM_PROTECTION_CERTIFICATE,
      this.protectionCertificateDocLink,
    ],
    [DOCUMENT_TYPE.COMMISSIONING_PROTOCOL, this.commissioningProtocolLink],
    [DOCUMENT_TYPE.UNIT_CERTIFICATE_INVERTER, this.unitCertificateDocLink],
    [
      DOCUMENT_TYPE.NA_PROTECTION_CERTIFICATE,
      this.protectionCertificateDocLink,
    ],
  ]);

  public allTouched = false;
  public documentForm!: FormGroup;
  public availableDocumentTypes: DocumentDetails[] = [];
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public showCommentSection = false;
  public allRequiredFormsProvided: WritableSignal<boolean> = signal(false);
  public readonly documentFormValid: WritableSignal<boolean> = signal(false);
  public readonly formValid: Signal<boolean> = computed(
    () => this.documentFormValid() && this.allRequiredFormsProvided(),
  );
  public readonly maxTotalFileSize =
    this.#environmentService.maxTotalFileSizeMB;
  public readonly locale = this.#translateService.currentLang;
  private readonly onDestroy$ = new Subject<void>();
  private documents?: DocumentWithFile[];
  public ngOnInit(): void {
    this.initFormData();
  }

  private initFormData(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map((state) => state.formData),
        filter(Boolean),
        take(1),
      )
      .subscribe((formData) => {
        this.documents = formData.documentDetails?.documents;
        this.availableDocumentTypes =
          this.#documentMapperService.getAvailableDocumentTypes(formData);
        if (formData.selectedFlow === FLOW.COMMISSIONING) {
          this.showCommentSection = true;
        }
        // if a document type is cached in the state which is not available here => remove from state first before creating the form
        this.cleanupDocumentsState(formData);
        this.createForm(formData.documentDetails);
        this.watchForm();
        this.evaluateRequiredDocuments();
        this.applyDownloadLinks();
        this.#updateCumulatedFileSize();
      });
  }

  private cleanupDocumentsState(formData: PartialFormData): void {
    const cleanedDocuments =
      formData.documentDetails?.documents.filter((d) =>
        this.availableDocumentTypes.some(
          (availableDoc) => availableDoc.typeName === d.type,
        ),
      ) ?? [];
    this.#appStateService.updateFormData({
      documentDetails: { documents: cleanedDocuments },
    });
  }

  private applyDownloadLinks(): void {
    this.availableDocumentTypes.forEach((availableType) => {
      availableType.downloadLink = this.downloadLinksByType.get(
        availableType.typeName,
      );
    });
  }

  private createForm(documentDetails?: DocumentDetailsWithFile): void {
    this.documentForm = this.#formBuilder.group({
      notes: [
        documentDetails?.notes,
        {
          updateOn: "blur",
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ],
        },
      ],
    });
    this.availableDocumentTypes.forEach((documentType) => {
      this.documentForm.addControl(
        documentType.typeName,
        this.#formBuilder.control([]),
      );
      if (documentType.required) {
        this.documentForm
          .get(documentType.typeName)
          ?.setValidators([Validators.required]);
      }
      documentDetails?.documents.forEach((savedDocument) => {
        if (savedDocument.type === documentType.typeName) {
          this.documentForm
            .get(documentType.typeName)
            ?.patchValue([
              ...(this.documentForm.get(documentType.typeName)?.value ?? []),
              savedDocument,
            ]);
        }
      });
      this.documentForm.get(documentType.typeName)?.updateValueAndValidity();
    });
  }

  private watchForm(): void {
    this.documentForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((formGroup) => {
        const notes = formGroup.notes;
        const selectedFiles = Object.values(formGroup)
          .filter((elem) => elem?.constructor.name === "Array")
          .flat() as DocumentWithFile[];
        this.documents = selectedFiles;
        this.#appStateService.updateFormData({
          documentDetails: { documents: selectedFiles, notes: notes },
        });
        this.#updateCumulatedFileSize();
        this.evaluateRequiredDocuments();
      });
  }

  #updateCumulatedFileSize(): void {
    const documents: DocumentWithFile[] = this.documents ?? [];
    const totalFileSize = documents.reduce(
      (acc, doc) => acc + doc.file.size,
      0,
    );
    const maxAllowedSize = 1000 * 1000 * this.maxTotalFileSize;
    if (totalFileSize > maxAllowedSize) {
      this.documentForm.setErrors({
        max_total_size: {
          size: this.#fileSizePipe.transform(
            maxAllowedSize,
            this.#translateService.currentLang,
          ),
        },
      });
      this.documentFormValid.set(this.documentForm.valid);
    }
  }

  public getControl(documentName: string): FormControl {
    return this.documentForm.get(documentName) as FormControl;
  }

  public previous(): void {
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.formValid()) {
      this.#routeService.navigateToNextStep();
    } else {
      this.#updateCumulatedFileSize();
      this.allTouched = true;
      this.documentForm.markAllAsTouched();
    }
  }

  private evaluateRequiredDocuments(): void {
    this.allRequiredFormsProvided.set(
      Object.keys(this.documentForm.controls).every((key) => {
        if (this.documentForm.get(key)?.hasValidator(Validators.required)) {
          return this.documentForm.get(key)?.value?.length;
        }
        return true;
      }),
    );
    this.documentFormValid.set(this.documentForm.valid);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
