<app-form-card
  titleIcon="electric_bolt"
  cardTitle="BALCONY_PV_EXIT.HEADING"
  data-testid="balcony-pv-exit-heading"
>
  <p class="bold">{{ "BALCONY_PV_EXIT.DESCRIPTION" | translate }}</p>
  <div class="space"></div>
  <p>{{ "BALCONY_PV_EXIT.DESCRIPTION_2" | translate }}</p>
  <div class="space"></div>
  <div class="link-container">
    <a
      href="https://www.marktstammdatenregister.de/MaStR"
      target="_blank"
      class="bold"
    >
      <mat-icon>arrow_forward</mat-icon>
      {{ "BALCONY_PV_EXIT.GO_TO_MASTR" | translate }}</a
    >
  </div>
  <app-form-navigation
    (previous)="previous()"
    (next)="next()"
    [nextConfig]="{
      label: 'BALCONY_PV_EXIT.NEXT_BUTTON',
    }"
    slot="navigation"
  />
</app-form-card>
