<app-summary-section
  [stepRoute]="route"
  heading="OTHER_2_CONTROLLABLE_DETAILS.HEADING"
>
  <div class="section-fields-container">
    <app-summary-section-field
      [value]="controllableDetails.totalMinimumPower"
      label="OTHER_2_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER"
    />
    <app-summary-section-field
      [value]="
        'OTHER_2_CONTROLLABLE_DETAILS.COMPLIANT.' +
          (controllableDetails.compliant ? 'YES' : 'NO') | translate
      "
      label="OTHER_2_CONTROLLABLE_DETAILS.COMPLIANT.TITLE"
    />
    <app-summary-section-field
      [value]="
        'OTHER_2_CONTROLLABLE_DETAILS.CONTROLLER.' +
          controllableDetails.controller | translate
      "
      label="OTHER_2_CONTROLLABLE_DETAILS.CONTROLLER.TITLE"
    />
    @if (
      controllableDetails.controller ===
      CONTROLLER.COMPETITIVE_METERING_POINT_OPERATOR
    ) {
      <app-summary-section-field
        [value]="controllableDetails.controllerName"
        label="OTHER_2_CONTROLLABLE_DETAILS.CONTROLLER_NAME"
      />
    }
    <app-summary-section-field
      [label]="
        'OTHER_2_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.TITLE'
          | translate
            : {
                date:
                  '01.01.2024'
                  | date: 'shortDate' : '' : currentLanguageCulture,
              }
      "
      [value]="
        'OTHER_2_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.' +
          controllableDetails.existingSystem | translate
      "
    />
    <app-summary-section-field
      [value]="
        'OTHER_2_CONTROLLABLE_DETAILS.CONTROL_TYPE.' +
          controllableDetails.controlType | translate
      "
      label="OTHER_2_CONTROLLABLE_DETAILS.CONTROL_TYPE.TITLE"
    />
    <app-summary-section-field
      [value]="
        'OTHER_2_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.' +
          controllableDetails.networkFeeModule | translate
      "
      label="OTHER_2_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.TITLE"
    />
  </div>
</app-summary-section>
