<div class="section-fields-container">
  <app-summary-section-field
    [value]="constructionElectricityDetails.systemPower"
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.SYSTEM_POWER"
  />

  <app-summary-section-field
    [value]="
      (constructionElectricityDetails.devicesRequireApproval
        ? 'COMMON.YES'
        : 'COMMON.NO'
      ) | translate
    "
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.DEVICES_REQUIRE_APPROVAL_QUESTION"
  />

  @if (constructionElectricityDetails.devicesRequireApproval) {
    <app-summary-section-field
      [value]="constructionElectricityDetails.requiredDevices?.join(', ')"
      label="CONSTRUCTION_ELECTRICITY_INFORMATION.REQUIRED_DEVICES"
    />
  }

  <app-summary-section-field
    [value]="
      (constructionElectricityDetails.desiredInstallationDate
        | date: 'shortDate' : '' : currentLanguageCulture) || undefined
    "
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_INSTALLATION_DATE"
  />

  <app-summary-section-field
    [value]="
      (constructionElectricityDetails.desiredDeconstructionDate
        | date: 'shortDate' : '' : currentLanguageCulture) || undefined
    "
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_DECONSTRUCTION_DATE"
  />
</div>
